import Moment from 'moment-timezone';
import { get } from 'lodash';

// const { dateFormats } = Config;

const dateFormats = {
  // defaults
  WEEKDAY_DATE: 'ddd, MMM Do',
  DEFAULT: 'DD/MM/YYYY',
  LONG: 'ddd Do MMM YYYY',
  SHORT: 'DD MMM',
  SHORT_ORDINAL: 'Do MMM',
  MEDIUM: 'DD MMM YYYY',
  MEDIUM_WITH_TIME: 'DD MMM YYYY HH:mm A',
  DATE_STRING: 'L',
  DATE_STRING_LONG: 'll', // shortened month name
  LONG_MONTH: 'MMMM YYYY',
  DISCUSSIONS_DATE: 'ddd Do MMM',
  DISCUSSIONS_TIME: 'HH:mm',

  // language specific
  en: {},
  de: {},
  ja: {
    WEEKDAY_DATE: 'YYYY年M月D日 (dd)',
    DEFAULT: 'LLL',
    LONG: 'YYYY年M月D日 (dd)',
    SHORT: 'M月D日',
    SHORT_ORDINAL: 'MMMDo',
    MEDIUM: 'YYYY年M月D日',
    MEDIUM_WITH_TIME: 'YYYY年M月D日 HH:mm A',
    DATE_STRING: 'L',
    DATE_STRING_LONG: 'll', // shortened month name
    LONG_MONTH: 'YYYY年MMM',
    DISCUSSIONS_DATE: 'M月D日 (dd)'
  }
};

/**
 * Get current time as Moment object for the specified timezone.
 *
 * @param {string} timezone Defaults to guessed timezone
 * @returns {object} Moment object
 */
export const getNowMomentInTimezone = (timezone) => {
  const _tz = timezone || Moment.tz.guess();
  return Moment().tz(_tz);
};

/**
 * Get a time as Moment object for the specified timezone.
 *
 * @param {string} timezone Defaults to guessed timezone
 * @returns {object} Moment object
 */
export const getTimestampInTimezone = (timestamp, format = '', timezone = Moment.tz.guess()) => {
  if (format) {
    return Moment(timestamp, format).tz(timezone);
  }
  return Moment(timestamp).tz(timezone);
};

/**
 * Get a today's date as a string of the specified timezone.
 *
 * @param {string} timezone Defaults to guessed timezone
 * @returns {string} Date string
 */
export const getTodaysDate = (timezone = Moment.tz.guess()) => getNowMomentInTimezone(timezone).format('YYYY-MM-DD');

/**
 * Get the current time in RFC 3339 format
 *
 * @param {string} timezone
 * @returns {string} Formatted time as string YYYY-MM-DDTHH:mm:ssZ
 */
export function getCurrentTime(timezone = Moment.tz.guess()) {
  return getNowMomentInTimezone(timezone).format('YYYY-MM-DDTHH:mm:ssZ');
}

/**
 *
 * @param {string} time - Time string for moment to convert
 * @param timezone - IANA timezone string (default is to guess users timezone)
 * @returns {Object} moment
 */
export function getTimeInTimezone(time, timezone = Moment.tz.guess()) {
  Moment.tz.setDefault(timezone);
  return Moment(time);
}

//
// TIME DIFFERENCE
//
export function timeUnits(units) {
  switch (units) {
    case 'days':
      return 86400 * 1000;
    case 'hours':
      return 3600 * 1000;
    case 'minutes':
      return 60 * 1000;
    case 'seconds':
    default:
      return 1000;
  }
}

// TIME CHECK IS SKIPPED
function isTimeDifferenceCheckSkipped(countryCode) {
  const SKIP_CHECK_COUNTRIES = ['US', 'CA'];
  return SKIP_CHECK_COUNTRIES.includes(countryCode);
}

export function timeDifference({ time, comparator, round, units }) {
  const comparisonScale = timeUnits(units);

  switch (round) {
    case 'up':
      return Math.ceil(Moment(comparator).diff(Moment(time)) / comparisonScale);
    case 'down':
    default:
      return Math.floor(Moment(comparator).diff(Moment(time)) / comparisonScale);
  }
}

//
// FORMAT TIME WITH TIME ZONES
//
export function formatTimeWithTimeZone(time, timezone) {
  return Moment.tz(`${Moment().format('YYYY-MM-DD')}T${time}:00`, timezone).format('YYYY-MM-DDTHH:mm:ssZ');
}

//
// EVALUATE STORE TIMES
//
export function formatOpeningTimes({ timezone, start, end }) {
  // Evaluate what time EOD can start being declared from
  if (start && end) {
    const startHour = parseInt(end.split(':')[0], 10);
    const eodStartHour = startHour - 4;
    const eod = `${eodStartHour <= 9 ? 0 : ''}${eodStartHour}:${end.split(':')[1]}`;
    return {
      openingTime: formatTimeWithTimeZone(start, timezone),
      closingTime: formatTimeWithTimeZone(end, timezone),
      eodStartTime: formatTimeWithTimeZone(eod, timezone),
      timezone
    };
  }
  return {
    openingTime: '',
    closingTime: '',
    eodStartTime: '',
    timezone
  };
}

//
// EVALUATE WITHIN 4 HOURS OF OPEN
//
export function within4HoursOfOpen({ shop, shopInfo }) {
  if (!shop || shop.state === 'open') {
    if (shopInfo.openingTime === '') return false;

    const difference = timeDifference({
      time: shopInfo.openingTime,
      comparator: getCurrentTime(shopInfo.timezone),
      round: 'up',
      units: 'hours'
    });

    return difference <= 4;
  }
  return false;
}

//
// EVALUATE WITHIN 4 HOURS OF CLOSE
//
export function within4HoursOfClose({ shop = null, shopInfo }) {
  if (!shop || shop?.state === 'closed') {
    if (shopInfo?.closingTime === '') return false;

    if (isTimeDifferenceCheckSkipped(shopInfo.countryCode)) {
      return true;
    }

    const difference = timeDifference({
      time: shopInfo.closingTime,
      comparator: getCurrentTime(shopInfo.timezone),
      round: 'down',
      units: 'hours'
    });
    return difference >= -4;
  }
  return false;
}

/**
 * FORMAT TIME RANGE
 * @param {string} time
 * @returns string
 */
export function timeRange(time) {
  const hour = time.format('HH');
  const subsequentHour = Number(hour) + 1;
  const displayedSubsequentHour = subsequentHour <= 9 ? `0${subsequentHour.toString()}` : subsequentHour.toString();
  return `${hour}:00 - ${displayedSubsequentHour}:00`;
}

/**
 * Get the current language from the first part of the URL's pathname. Defaults to 'en' if language is not found
 *
 * Updated to enable passing in a lang value from useTranslation because that's safer.
 *
 * @returns {string} Language identifier
 */
export function getLocaleFromURL(lang) {
  const languages = ['en', 'de', 'ja'];

  let _lang = lang;

  if (typeof window !== 'undefined') {
    if (!_lang) {
      const { pathname } = window.location;
      [, _lang] = pathname.split('/'); // take element one
    }

    if (_lang === 'de') {
      Moment.updateLocale('de', {
        months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
        monthsShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
        weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
        weekdaysShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
        weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_')
      });
    } else if (_lang === 'ja') {
      Moment.updateLocale('ja', { weekdaysMin: '日_月_火_水_木_金_土'.split('_') });
    }

    return languages.indexOf(_lang) ? _lang : 'en-gb'; // NOTE: If _lang is 'en', then indexOf is 0, which is false.
  }

  return 'en-gb';
}

/**
 * Format date in local format based on language
 *
 * NOTE: Calling this function has a sideeffect of updating Moment to change the day
 * month and weekday names. That's pretty horrible.
 *
 * @param {Object} param.timezone - string
 * @param {Object} param.date - Moment date object
 * @param {string} param.locale - Language identifier
 * @param {string} param.format - Moment format string
 * @returns {string} Formatted date string for display
 */
export function formatWithLocale({
  timezone = Moment.tz.guess(),
  date = getNowMomentInTimezone(timezone),
  locale = getLocaleFromURL(),
  format = dateFormats.DEFAULT
}) {
  return date.locale(locale).format(format);
}

export function getDateString({ language = getLocaleFromURL(), stringName }) {
  const date = get(dateFormats, `${language}.${stringName}`, get(dateFormats, stringName, ''));
  if (date === '') {
    throw new Error(`Invalid date format config name: ${stringName}`);
  }
  return date;
}

/**
 * Check if date is yesterday's date
 *
 * @param {String} param.date - Date as string
 * @param {String} param.timezone - timezone as string e.g. London/Europe
 * @returns {Boolean}
 */
export const isYesterday = (date, timezone = Moment.tz.guess()) => {
  Moment.tz.setDefault(timezone);
  const yesterday = Moment().subtract(1, 'days');
  return Moment(date).isSame(yesterday, 'day');
};

/**
 * Check if date is today's date
 *
 * @param {String} param.date - Date as string
 * @param {String} param.timezone - timezone as string e.g. London/Europe
 * @returns {Boolean}
 */
export const isToday = (date, timezone = Moment.tz.guess()) => {
  Moment.tz.setDefault(timezone);
  return Moment(date).isSame(Moment(), 'day');
};

/**
 * Check if date is in the future
 *
 * @param {String} param.date - Date as string
 * @param {String} param.timezone - timezone as string e.g. London/Europe
 * @returns {Boolean}
 */
export const isFutureDay = (date, timezone = Moment.tz.guess()) => {
  Moment.tz.setDefault(timezone);
  return Moment(date).isAfter(Moment());
};

/**
 * Check if date is past yesterday's date
 *
 * @param {String} param.date - Date as string
 * @param {String} param.timezone - timezone as string e.g. London/Europe
 * @returns {Boolean}
 */
export const isPastDate = (date, timezone = Moment.tz.guess()) => {
  Moment.tz.setDefault(timezone);
  const yesterday = Moment().subtract(1, 'days');
  return Moment(date).isBefore(yesterday, 'day');
};

/**
 * Gets a Moment object of a date in a timezone
 *
 * @param {String} param.date - Date as string
 * @param {String} param.timezone - timezone as string e.g. London/Europe
 * @returns {Object} - Moment Object
 */
export const getMomentDate = (date, timezone = Moment.tz.guess()) => {
  Moment.tz.setDefault(timezone);
  return Moment(date);
};

export const getTodaysDay = (timezone = Moment.tz.guess()) => {
  Moment.tz.setDefault(timezone);
  const date = getNowMomentInTimezone(timezone).format('YYYY-MM-DD');

  return Moment(date).day();
};

export const formatDateTimeForEod = (time, timezone) => {
  let utcFormattedTime;

  // Handle a Moment.js bug where UK dates are converted into UTC incorrectly during leap years
  if (timezone === 'Europe/London') {
    utcFormattedTime = Moment(time, 'YYYY-MM-DD HH:mm', timezone)?.utcOffset(0, true).format();
  } else {
    utcFormattedTime = Moment(time, 'YYYY-MM-DD HH:mm', timezone)?.utc().format();
  }

  return utcFormattedTime;
};
