import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-left: 12px;
`;

export const Label = styled.button`
  background: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
`;

export const List = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};

  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  background: #fff;
  z-index: 5;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);

  &:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -10px;
    right: 10px;
    content: '';
  }
`;
