function decideFont(font) {
  switch (font) {
    case 'LushHandwritten':
      return "'LushHandwritten', 'inter', 'sans-serif'";
    case 'inter':
      return "'inter', 'sans-serif'";
    default:
      return font;
  }
}

export default function FontFamily(font) {
  if (font) {
    return `
    ${font === 'LushHandwritten' ? 'line-height: 1 !important;' : ''}
    font-family: ${decideFont(font)}
    `;
  }
  return null;
}
