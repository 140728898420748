import { isEmpty } from 'lodash';
import Moment from 'moment-timezone';
import { within4HoursOfOpen, within4HoursOfClose } from './time';
import AppConfig from '../config';

//
// VALIDATE TILL STATE
//
export function allowedTillStates(state) {
  const allowedStates = Object.keys(AppConfig.tillStates).map((item) => AppConfig.tillStates[item]);
  return allowedStates.includes(state);
}

//
// EVALUATE TILL STATE

// export function evaluateTillState({ till, eodReport, tillReport, shopInfo, shop, nextEod }) {
//   // Set error state straight away if needed
//   if (till.error || tillReport.error) return AppConfig.tillStates.ERROR;

//   // If we are fetching
//   if (till.fetching || tillReport.fetching) return AppConfig.tillStates.LOADING;

//   // If report processing
//   if (tillReport.status === 202) return AppConfig.tillStates.WAITING;

//   const newDayOpen = within4HoursOfOpen({ shop, shopInfo });

//   const trSubmitted = nextEod.data.next.TillDates[till.till_id];

//   console.log('eval', trSubmitted);

//   // ========================== //
//   // EVALUATE REPORT GENERATION //
//   // ========================== //
//   if (eodReport && eodReport.report_generated) {
//     // If the report is generated and till is closed
//     return AppConfig.tillStates.CLOSED_REPORT_GENERATED;
//   }

//   // if the till is closed_cashed  but no report generated
//   if (till.state === 'closed_cashed' && !eodReport?.report_generated) {
//     if (shop.state === 'closed') {
//       // If we are in a new day and the store is closed, display as day ended
//       return AppConfig.tillStates.CLOSED_DAY_ENDED;
//     }
//     if (newDayOpen && !within4HoursOfClose({ shopInfo })) {
//       // If we are in a new day and the store is open, display as closed
//       return AppConfig.tillStates.CLOSED;
//     }
//     // If not new day then show as report generating
//     return AppConfig.tillStates.CLOSED; //AppConfig.tillStates.CLOSED_REPORT_GENERATING;
//   }

//   // =================== //
//   // VALIDATE AND RETURN //
//   // =================== //
//   // Validate state is valid and return or return error
//   if (allowedTillStates(till.state)) return till.state;
//   return AppConfig.tillStates.ERROR;
// }

export function evaluateTillState({ till, eodReport, tillReport, shopInfo, shop, today, nextEod }) {
  // Set error state straight away if needed
  if (till.error || tillReport.error) return AppConfig.tillStates.ERROR;

  // If we are fetching
  if (till.fetching || tillReport.fetching) return AppConfig.tillStates.LOADING;

  // If report processing
  if (tillReport.status === 202) return AppConfig.tillStates.WAITING;

  const newDayOpen = within4HoursOfOpen({ shop, shopInfo });

  // ========================== //
  // EVALUATE REPORT GENERATION //
  // ========================== //

  // if the till is closed and has not traded
  if (till.state === 'closed') {
    const nextEodData = nextEod.next;
    if (
      nextEodData.ShopDate === null ||
      (nextEodData.ShopDate === today &&
        (nextEodData.TillDates[till.till_id] === null || nextEodData.TillDates === null))
    ) {
      return AppConfig.tillStates.CLOSED_NOT_TRADED;
    }
  }
  if (eodReport && eodReport?.report_generated) {
    // If the report is generated and till is closed
    return AppConfig.tillStates.CLOSED_REPORT_GENERATED;
  }
  // if the till is closed_cashed  but no report generated
  if (till.state === 'closed_cashed' && !eodReport?.report_generated) {
    if (shop.state === 'closed') {
      // If we are in a new day and the store is closed, display as day ended
      return AppConfig.tillStates.CLOSED_DAY_ENDED;
    }
    if (newDayOpen && !within4HoursOfClose({ shopInfo })) {
      // If we are in a new day and the store is open, display as closed
      return AppConfig.tillStates.CLOSED;
    }
    // If not new day then show as report generating
    return AppConfig.tillStates.CLOSED_REPORT_GENERATING;
  }

  // =================== //
  // VALIDATE AND RETURN //
  // =================== //
  // Validate state is valid and return or return error
  if (allowedTillStates(till.state)) return till.state;
  return AppConfig.tillStates.ERROR;
}

//
// GET TILL DATA
//
export function getTillData({ id, tills }) {
  // Get details about the till
  const till = tills.tills[Number(id)];
  if (!isEmpty(till)) {
    return till;
  }
  return null;
}

//
// TILL REPORT POLLING FUNCTION
//
export function startTillReportPoll({ till, getTillReport, reportPoll, date }) {
  const retries = 7;
  const attemptCount = reportPoll || 1;
  const modifier = attemptCount / 2;
  const timeout = 5000 * modifier;
  const context = date ? 'dated' : 'default';
  const dateInput = date || Moment().format('YYYY-MM-DD');
  if (attemptCount <= retries) {
    const poll = setTimeout(() => {
      getTillReport({
        date: dateInput,
        shop: till.location_id,
        till: till.till_id,
        context
      }).then((res) => {
        if (res.payload.status === 202) {
          clearTimeout(poll);
          startTillReportPoll({
            till,
            getTillReport,
            reportPoll: attemptCount + 1,
            date
          });
        }
      });
    }, timeout);
  } else {
    // TODO: ADD CALL TO INTRODUCE AN ERROR STATE
    console.error('TILL REPORT => Poll count exceeded');
  }
}
