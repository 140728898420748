import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// LUSH
import { removeElement } from '../../../helpers/dom';

export default class Dock extends React.Component {
  constructor(props) {
    super(props);
    // Create a div that we'll render the dock into. Because each
    // dock component has its own element, we can render multiple
    // dock components into the dock container.
    if (typeof document !== 'undefined') {
      this.dockRoot = document.createElement('div');
    }
  }

  UNSAFE_componentWillMount() {
    if (this.dockRoot && typeof document !== 'undefined') {
      const { style } = this.props;

      this.dockRoot.className = 'dock';
      this.dockRoot.style.backgroundColor = '#000';
      this.dockRoot.style.position = 'sticky';
      this.dockRoot.style.left = 0;
      this.dockRoot.style.bottom = 0;
      this.dockRoot.style.width = '100%';

      Object.keys(style).forEach((key) => {
        this.dockRoot.style[key] = style[key];
      });

      document.body.appendChild(this.dockRoot);
    }
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    removeElement(this.dockRoot);
  }

  constructDock() {
    const { children } = this.props;
    return children;
  }

  render() {
    // Use a portal to render the children into the element
    if (this.dockRoot) {
      return ReactDOM.createPortal(this.constructDock(), this.dockRoot);
    }
    return null;
  }
}

Dock.displayName = 'Dock';

Dock.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object
};

Dock.defaultProps = { style: {} };
