import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Icon from '@lushdigital/icons';
import { Row, Column } from '@lushdigital/ui-latest';
import useTranslation from 'next-translate/useTranslation';
import { isEmpty, cloneDeep } from 'lodash';
import { useRouter } from 'next/router';
import { useStore } from '@/manager-next/store';

// HELPERS
import { useSession } from '@/manager-next/useRqSession';
import {
  TILLS,
  REPORTS,
  PETTYCASH,
  TILL_READS,
  DEVICE_MANAGER,
  STORE_MANAGER,
  DIAGNOSTICS,
  TSS
} from '../../config/pages';
import { getTodaysDate, getNowMomentInTimezone } from '../../helpers';

export const dashboardTileLinks = [
  {
    route: TILLS,
    icon: 'smartphone-android',
    section: 'tills'
  },
  {
    route: REPORTS,
    icon: 'trending-up',
    section: 'reports',
    adminOnly: false
  },
  {
    route: PETTYCASH,
    icon: 'case',
    section: 'petty-cash',
    adminOnly: false
  },
  {
    route: TILL_READS,
    icon: 'recipt',
    section: 'till-reads',
    adminOnly: false
  },
  {
    route: DEVICE_MANAGER,
    icon: 'devices',
    section: 'device-manager',
    adminOnly: true
  },
  {
    route: STORE_MANAGER,
    icon: 'store',
    section: 'store-manager',
    adminOnly: true
  },
  {
    route: DIAGNOSTICS,
    icon: 'eye',
    section: 'diagnostics',
    adminOnly: true
  },
  { route: TSS, icon: 'swap', section: 'tss', adminOnly: true }
];

// Component
const DashboardTiles = () => {
  const { t, lang } = useTranslation('tills');
  const [dashboardLinks, setDashboardLinks] = useState([]);
  const router = useRouter();
  const [session] = useSession();
  const shopInfo = useStore((state) => state.shopInfo);

  useEffect(() => {
    const timezone = shopInfo?.timezone || null;
    const date = getTodaysDate(timezone === '' ? undefined : timezone);
    const moment = getNowMomentInTimezone(timezone);

    const currentMonth = moment.format('M');
    const currentYear = moment.format('YYYY');

    let links = cloneDeep(dashboardTileLinks);

    links.find((link) => link.route.title === 'PETTY_CASH').route.path = PETTYCASH.path
      .replace(':startYear', currentYear)
      .replace(':startMonth', currentMonth)
      .replace(':endYear', currentYear)
      .replace(':endMonth', currentMonth);

    links.find((link) => link.route.title === 'TILL_READS').route.path = TILL_READS.path.replace(':date', date);

    switch (shopInfo.countryCode) {
      case 'DE':
        break;
      case 'US':
      case 'JP':
      case 'UK':
      default:
        links = links.filter((l) => l.route.title !== 'TSS');
        break;
    }

    links = links.filter((link) =>
      link.route.permissions ? link.route.permissions.some((role) => session?.user?.roles.includes(role)) : true
    );

    setDashboardLinks(links);
  }, [shopInfo.countryCode, shopInfo.timezone]);

  return (
    <div className="container-tw flex pt-20">
      {!isEmpty(dashboardLinks) && (
        <Row>
          {dashboardLinks.map((item) => {
            const tile = (
              <a
                href={item.route.path.replace(':language', lang)}
                className="flex flex-col items-center no-underline transition-all duration-300	hover:opacity-40"
                data-testid={`navigation-${item.route.title.toLowerCase()}`}
              >
                <span className="cursor-pointer rounded-full bg-[#ffffff40]	p-6">
                  <Icon icon={item.icon} size="55px" fill="#fff" />
                </span>
                <span className="block pt-5 text-sm leading-6 text-white">{t(item.route.title)}</span>
              </a>
            );

            const linkedTile =
              router.asPath !== item.route.path.replace('/:language', '') ? (
                <Link href={item.route.path.replace(':language', lang)}>{tile}</Link>
              ) : (
                tile
              );

            return (
              <Column medium={3} default={6} padding="20px 20px 60px" alignItems="center" key={item.route.title}>
                {linkedTile}
              </Column>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default DashboardTiles;
