import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import Cookies from 'universal-cookie';

const ENV = process.env.NEXT_PUBLIC_BUILD_ENV || process.env.NODE_ENV;

const GRAPHQL_URI =
  ENV === 'production'
    ? 'https://apollo-graphql.frontendserviceaccount.com/graphql'
    : 'https://apollo-graphql-staging.frontendserviceaccount.com/graphql';

const getToken = () => {
  const cookies = new Cookies();
  const jwt = cookies.get('idToken');
  return jwt;
};

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.error(message));
});

const httpLink = createHttpLink({
  uri: GRAPHQL_URI
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Access-Token': '3DAEAE839A095B57E6C18A60DA7EA566E2B8B45F5C70BD7F735BFB9CE7BA8F26'
    }
  };
});

const client = new ApolloClient({
  connectToDevTools: true,
  ssrMode: true,
  name: 'manager',
  version: '2.0',
  uri: GRAPHQL_URI,
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authLink, httpLink])
});

export default client;
