import Proptypes from 'prop-types';
import styled from 'styled-components';

// base typography component
import base from '../base';

// element
const Caption = styled('caption')`
  ${base}
`;

// default props for element
Caption.defaultProps = {
  textSize: '11px',
  lineHeight: '18px'
};

Caption.propTypes = {
  /** define font size of the caption */
  textSize: Proptypes.string,
  /** define line line height of the caption */
  lineHeight: Proptypes.string
};

// DisplayName for new element
Caption.displayName = 'Caption';

// export element
export default Caption;

//
export const StoryCaption = props => <Caption { ...props } />; // eslint-disable-line
// default props for element
StoryCaption.defaultProps = {
  textSize: '11px',
  lineHeight: '18px'
};
StoryCaption.propTypes = {
  /** define font size of the caption */
  textSize: Proptypes.string,
  /** define line line height of the caption */
  lineHeight: Proptypes.string
};
StoryCaption.displayName = 'Caption';
