import * as Sentry from '@sentry/nextjs';

import { ENV } from '@lushdigital/helper-functions';

const isDevelopment = ENV === 'development';

export const logSentryApiException = ({ exception, config = {} }) => {
  if (!isDevelopment) {
    Sentry.captureException(exception, {
      extra: {
        url: config?.url,
        payload: config?.data
      }
    });
  }
};

export const logSentryClientException = ({ exception }) => {
  if (!isDevelopment) {
    Sentry.captureException(exception);
  } else {
    console.error(exception);
  }
};

export function logApolloException({ name, code, error, allow401, allow404, allow422 }) {
  // Check if the response code is 404, 422 or 401 and flag to ignore exception unless allowed
  let sendException = true;
  if (code && (code === 404 || code === 401 || code === 422)) {
    sendException = (code === 401 && allow401) || (code === 404 && allow404) || (code === 422 && allow422);
  }
  // Only send exception if allowed code
  if (sendException) {
    Sentry.captureException(name, {
      extra: {
        error
      }
    });
    // eslint-disable-next-line
    console && console.error && console.error(error);
  }
}

// export function __DEPRECATED__logSentryException(ex, res, post = null) {
//   // eslint-disable-line
//   logSentryException({
//     ex,
//     res,
//     body: post
//   });
// }
