import styled from 'styled-components';
import PropTypes from 'prop-types';

const Error = styled.span`
  display: block;
  width: 100%;
  color: #d0021b;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  text-align: ${({ align }) => align};
`;

Error.span = Error;
Error.h1 = Error.withComponent('h1');
Error.h2 = Error.withComponent('h2');
Error.h3 = Error.withComponent('h3');
Error.h4 = Error.withComponent('h4');
Error.h5 = Error.withComponent('h5');
Error.h6 = Error.withComponent('h6');
Error.p = Error.withComponent('p');
Error.label = Error.withComponent('label');

Error.propTypes = {
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  align: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string
};

Error.defaultProps = {
  fontSize: '12px',
  lineHeight: '18px',
  align: 'left',
  padding: '0',
  margin: '0'
};

Error.displayName = 'Error';

export default Error;
