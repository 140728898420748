import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';

import { InlineMenuWithStyles } from './inlineMenu.styles';

class InlineMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { inlineMenu: false };
    this.inlineMenuContainer = React.createRef();
    this.Wrapper = React.createRef();
  }

  componentDidMount() {
    const { onGetRef } = this.props;

    if (typeof onGetRef === 'function') {
      onGetRef(this.inlineMenuContainer.current);
    }
    this.Wrapper.current.focus();
  }

  handleClick = (e) => {
    const target = e.target.className;
    if (typeof target === 'string') {
      if (target.includes('ui-inline-menu__row')) {
        this.setState({ inlineMenu: false });
      }
    }
  };

  // change menu to be active or inactive
  toggleInlineMenu = () => {
    const { inlineMenu } = this.state;
    this.setState({ inlineMenu: !inlineMenu });
  };

  // render child buttons / components
  handleBlur = (e) => {
    const { currentTarget } = e;

    this.tick = setTimeout(() => {
      if (!currentTarget.contains(document.activeElement) && this.Wrapper.current !== document.activeElement) {
        this.setState({ inlineMenu: false });
      }
    }, 0);
  };

  renderChildren() {
    const { children, data } = this.props;

    const childrenWithProps = React.Children.map(children, (child) => {
      if (child !== null) {
        return React.cloneElement(child, { data, toggle: this.toggleInlineMenu });
      }
    });

    return childrenWithProps;
  }

  render() {
    const { mobile, context, className } = this.props;
    const { inlineMenu } = this.state;

    let setContext = context;
    if (context === 'table' && mobile) {
      setContext = 'table--card';
    }

    return (
      <InlineMenuWithStyles
        onClick={(e) => this.handleClick(e)}
        className={`
          ui-inline-menu ui-inline-menu--${setContext}
          ${className !== '' ? ` inline-menu-${className}` : ''}
          ${inlineMenu ? ' is-active' : ''}
        `}
        ref={this.Wrapper}
        onBlur={this.handleBlur}
      >
        <div
          className={`
            ui-inline-menu__wrapper
            ${className !== '' ? ` inline-menu-${className}__wrapper` : ''}
          `}
        >
          {/* Container of buttons */}
          <div
            ref={this.inlineMenuContainer}
            className={`
              ui-inline-menu__container
              ${className !== '' ? ` inline-menu-${className}__container` : ''}
            `}
          >
            {this.renderChildren()}
          </div>

          {/* Toggle button (Open/Close) */}
          <button
            type="button"
            onClick={() => {
              this.toggleInlineMenu();
            }}
            className={`
              ui-inline-menu__toggle
              ${className !== '' ? ` inline-menu-${className}__toggle` : ''}
            `}
          >
            <Icon fill="#fff" icon="more-vert" size="18px" />
          </button>
        </div>
      </InlineMenuWithStyles>
    );
  }
}

InlineMenu.defaultProps = {
  children: '',
  className: '',
  context: 'float',
  onGetRef: null,
  data: null,
  mobile: false
};

InlineMenu.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  context: PropTypes.string,
  onGetRef: PropTypes.func,
  data: PropTypes.object,
  mobile: PropTypes.bool
};

InlineMenu.displayName = 'InlineMenu';

export default InlineMenu;
