import styled from 'styled-components';
import PropTypes from 'prop-types';

// THEMES
import { themes } from './themes';

// Loop over themes and render theme based css
function renderTheme(prop) {
  const theme = themes[prop];

  if (!theme) return;

  if (prop && prop.indexOf && (prop.indexOf('floating') !== -1 || prop === 'inverse' || prop === 'default')) {
    return `
      transition: opacity 0.3s;
      color: ${theme.color};
      background: ${theme.background};
      border-color: ${theme.borderColor};
      opacity: 1;


      @media (hover: hover) {
        &:hover {
          transition: opacity 0.3s;
          opacity: 0.6;
        }
      }

      &.active,
      &:active {
        transition: opacity 0.3s;
        opacity: 0.4;
      }

      &.disabled,
      &:disabled {
        transition: opacity 0.3s;
        opacity: 0.25;
      }
    `;
  }

  return `
    transition: color 0.3s, background 0.3s, border-color 0.3s;
    color: ${theme.color};
    background: ${theme.background};
    border-color: ${theme.borderColor};

    @media (hover: hover) {
      &:hover {
        transition: color 0.3s, background 0.3s, border-color 0.3s;
        color: ${theme.hover.color};
        background: ${theme.hover.background};
        border-color: ${theme.hover.borderColor};
      }
    }

    &.active,
    &:active {
      transition: color 0.3s, background 0.3s, border-color 0.3s;
      color: ${theme.active.color};
      background: ${theme.active.background};
      border-color: ${theme.active.borderColor};
    }

    &.disabled,
    &:disabled {
      transition: color 0.3s, background 0.3s, border-color 0.3s;
      color: ${theme.disabled.color};
      background: ${theme.disabled.background};
      border-color: ${theme.disabled.borderColor};
    }
  `;
}

// Exported default element
const Button = styled.button`
  box-sizing: border-box;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  backface-visibility: hidden;

  font-family: 'inter', sans-serif;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
  line-height: 1.6em;
  letter-spacing: 2px;
  text-transform: uppercase;

  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  ${(props) => {
    switch (props.align) {
      case 'right': {
        return `
          text-align: right;
          justify-content: flex-end;
        `;
      }
      case 'left': {
        return `
          text-align: left;
          justify-content: flex-start;
        `;
      }
      default: {
        return `
          text-align: center;
          justify-content: center;
        `;
      }
    }
  }}

  align-items: center;
  text-decoration: none;
  vertical-align: middle;

  /* // render theme styling */
  ${({ theme }) => renderTheme(theme)};

  ${({ fullWidth }) =>
    fullWidth
      ? `
    display: flex;
    width: 100%;
  `
      : `
    display: inline-flex;
  `};

  border-radius: ${({ circle }) => (circle ? '50%' : 0)};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  &:focus,
  &:active {
    box-shadow: inset 0 0 0 0.4em rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }
`;

// Optional tag options
Button.button = Button;
Button.a = Button.withComponent('a');

// Default props
Button.defaultProps = {
  fontSize: '11px',
  align: 'center',
  padding: '1.41em 2.4em',
  margin: '0',
  theme: 'default',
  fullWidth: false,
  circle: false
};

// Prop type check
Button.propTypes = {
  fontSize: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  padding: PropTypes.string,
  margin: PropTypes.string,
  theme: PropTypes.oneOf([
    'default',
    'default-clear',
    'default-solid',
    'default-floating',
    'inverse',
    'inverse-clear',
    'inverse-floating',
    'inverse-solid',
    'error',
    'warning',
    'success'
  ]),
  fullWidth: PropTypes.bool,
  circle: PropTypes.bool
};

Button.displayName = 'Button';

// export
export default Button;
