import React from 'react';
import Link from 'next/link';
import Button from '../button';

// TODO Understand what withComponent is doing here

// Filter out none acceptable attributes for react router Link.
const LinkFilter = ({ textTransform, circle, fullWidth, ...props }) => (
  <Link href={props.href || props.to} passHref={false}>
    <Button {...props} />
  </Link>
);

const UILink = Button.withComponent(LinkFilter);

UILink.displayName = 'Link';

// export
export default UILink;
