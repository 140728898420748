import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

// LUSH COMPONENTS
import Icon from '@lushdigital/icons';

// STYLED COMPONENTS
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Element = styled.button`
  border: none;
  border-radius: 38px;
  height: 38px;
  width: 38px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  outline: none;

  &:focus,
  &:active {
    box-shadow: 0 0 0 0.4em rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }

  position: absolute;
  top: 0;
  ${(props) => (props.direction === 'left' ? 'left: 0;' : 'right: 0;')}

  ${(props) =>
    props.activate
      ? `
    background: #333;
  `
      : `
    background: ${props.theme === 'white' ? 'black' : 'transparent'};
  `}


  ${(props) =>
    props.loading
      ? `
    svg {
      animation: ${rotate360} 1s linear infinite;
    }
  `
      : ''}
`;

// COMPONENT
class Button extends Component {
  handleClick = (e) => {
    const { onActivate, onDeactivate, activate } = this.props;
    e.preventDefault();

    if (activate) {
      onDeactivate();
    } else {
      onActivate();
    }
  };

  render() {
    const { button, activate, disabled, loading, direction, theme, className } = this.props;

    return (
      <Element
        direction={direction}
        theme={theme}
        className={`ui-type-ahead__button ${className !== '' ? `${className}-type-ahead__button` : ''}`}
        activate={activate ? 1 : 0}
        disabled={disabled ? 1 : 0}
        loading={loading ? 1 : 0}
        onClick={(e) => {
          this.handleClick(e);
        }}
        innerRef={button}
      >
        {loading && <Icon icon="refresh-alt" fill="#fff" size="15px" />}
        {!disabled && !activate && !loading && <Icon icon="plus" fill="#fff" size="16px" />}
        {!disabled && activate && !loading && <Icon icon="close" fill="#fff" size="12px" />}
      </Element>
    );
  }
}

Button.propTypes = {
  button: PropTypes.object.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  activate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['white', 'black']).isRequired,
  direction: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default Button;
