import { clarity as reactMicrosoftClarity } from 'react-microsoft-clarity';

const ENV = process.env.NEXT_PUBLIC_BUILD_ENV || process.env.NODE_ENV;

const isProduction = ENV === 'production';

const claritySiteId = isProduction
  ? process.env.NEXT_PUBLIC_CLARITY_SITE_ID_PROD
  : process.env.NEXT_PUBLIC_CLARITY_SITE_ID_DEV;

type ClarityParams = Array<string>;

interface User {
  userId: number;
  firstName?: string;
  lastName?: string;
}

// Send command to Clarity
function applyClarityCommand(...params: ClarityParams) {
  if (!claritySiteId) {
    throw new Error('Clarity site ID is not defined');
  }

  // Check if Clarity is not initialised and initialise it
  if (!window.clarity) {
    reactMicrosoftClarity.init(claritySiteId);
    // If Clarity is still not initialised, throw an error
    if (!window.clarity) {
      throw new Error('Clarity is not initialised');
    }
  }

  window.clarity.apply(undefined, params);
}

// Check if Clarity has started
function hasStarted() {
  return typeof window.clarity === 'function';
}

// Upgrade prioritizes specific session types for recording beyond the default rules.
function upgrade(reason: string) {
  applyClarityCommand('upgrade', reason);
}

// Set a tag in Clarity
function setTag(key: string, value: string) {
  applyClarityCommand('set', key, value);
}

// Set multiple tags in Clarity
function setTags(tags: { [key: string]: string }) {
  Object.keys(tags).forEach((key) => {
    applyClarityCommand('set', key, tags[key]);
  });
}

// Identify a user in Clarity
function identify(user: User) {
  let clarityIdentify = 'user-unknown';

  if (user?.firstName && user?.lastName) {
    const firstName = user.firstName.replace(/\s/g, '-');
    const lastName = user.lastName.replace(/\s/g, '-');
    clarityIdentify = `${firstName}-${lastName}`;
  } else if (user?.userId) {
    clarityIdentify = `userID-${user.userId.toString()}`;
  }
  setTag('lushUserId', user.userId.toString());

  applyClarityCommand('identify', `${ENV}-${clarityIdentify}`);
}

// Initialise Clarity with the site ID for the current environment
function init() {
  if (!claritySiteId) {
    throw new Error('Clarity site ID is not defined');
  }
  reactMicrosoftClarity.init(claritySiteId);
}

// Export clarity functions
export const clarity = {
  init,
  hasStarted,
  identify,
  upgrade,
  setTag,
  setTags
};
