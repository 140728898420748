import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ActionRow extends Component {
  render() {
    const { actions, mobile } = this.props;

    if (actions === null) return null;
    return <div>{actions({ mobile })}</div>;
  }
}

ActionRow.defaultProps = { actions: null };

ActionRow.propTypes = {
  mobile: PropTypes.bool.isRequired,
  actions: PropTypes.any
};
