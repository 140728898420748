import React from 'react';
import { signOut } from 'next-auth/react';
import { useSession } from '@/manager-next/useRqSession';
import { Button } from '@lushdigital/ui';
import Avatar from '@/components/Avatar';
import { ENV, appEnvironmentRoutes } from '@lushdigital/helper-functions';

// components
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Dropdown from './dropdown';

const appRoutes = appEnvironmentRoutes(ENV);

export const AccountDropdown = (): JSX.Element => {
  const { t, lang } = useTranslation('tills');
  const router = useRouter();
  const [session] = useSession();

  const { locales, asPath } = router;

  const user = session
    ? {
      first_name: session.user?.firstName,
      last_name: session.user?.lastName,
      profile_image: session.user?.user?.profile_image
    }
    : {};

  const renderAvatar = (): JSX.Element => (
    <Avatar first_name={user?.first_name} last_name={user?.last_name} profile_image={user?.profile_image || undefined} />
  );

  const renderDropdownItems = (): JSX.Element[] | null => {
    const localeLinks = locales ? locales
      .filter((lo) => lo !== 'default')
      .map((lo) => (
        <a href={`/${lo}/tills`} key={lo}>
          <div style={{ padding: '10px', textTransform: 'uppercase', cursor: 'pointer', width: '100%' }}>{lo}</div>
        </a>
      )) : [];

    return [
      ...localeLinks,
      <Link href={appRoutes.ACCOUNT.replace(':language', lang)} key="my-account">
        <Button key="my-account" theme="inverse" fullWidth href={appRoutes.ACCOUNT.replace(':language', lang)}>
          {t('MY_ACCOUNT')}
        </Button>
      </Link>,
      <Button key="logout" fullWidth onClick={() => signOut()}>
        {t('LOGOUT')}
      </Button>
    ];
  };

  return <Dropdown label={renderAvatar()} items={renderDropdownItems()} />;
};
