export const breakpoints = {
  tiny: 360,
  phone: 480,
  small: 620,
  tablet: 767,
  medium: 860,
  large: 1000,
  desktop: 1400
};

export const matches = {
  tiny: false,
  phone: false,
  small: false,
  tablet: false,
  medium: false,
  large: false,
  desktop: false
};

export const sizes = [
  {
    key: 'tiny',
    size: 360,
    match: false
  },
  {
    key: 'phone',
    size: 480,
    match: false
  },
  {
    key: 'small',
    size: 620,
    match: false
  },
  {
    key: 'tablet',
    size: 767,
    match: false
  },
  {
    key: 'medium',
    size: 860,
    match: false
  },
  {
    key: 'large',
    size: 1000,
    match: false
  },
  {
    key: 'desktop',
    size: 1400,
    match: false
  }
];
