import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// styled
const Input = styled.input`
  position: absolute;
  left: -9999px;
`;

const sharedStyles = css`
  display: flex;
  flex: 0 0 auto;
  border: 1px solid ${({ background }) => background};
  border-radius: ${({ circle }) => (circle ? '100%' : '3px')};
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-size: 2em;
  background-position: 0.3em 0.5em;
  justify-content: center;
  margin: ${({ margin }) => margin};

  *:focus + &,
  *:active + & {
    box-shadow: inset 0 0 0 0.2em rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }

  width: 2em;
  height: 2em;
  background-size: 2em;
  background-position: 0.3em 0.5em;

  cursor: pointer;

  ${Input}:disabled + & {
    opacity: 0.25;
    cursor: auto;
  }
`;

const Tick = styled.div`
  ${sharedStyles}

  ${Input}:checked + & {
    background-color: ${({ background }) => background};

    ${({ theme }) =>
      theme === 'black'
        ? `
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTE5IDIyOEwzNDYgMWwyOSAzMC0yNTYgMjU2TDAgMTY4bDMwLTMwIDg5IDkweiIvPjwvc3ZnPg==');
    `
        : `
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjMDAwIiBkPSJNMTE5IDIyOEwzNDYgMWwyOSAzMC0yNTYgMjU2TDAgMTY4bDMwLTMwIDg5IDkweiIvPjwvc3ZnPg==');
    `}
  }
`;

const Fill = styled.div`
  ${sharedStyles}

  ${Input}:checked + &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border: 0.2em solid transparent;
    background-clip: padding-box;
    background-color: ${({ background }) => background};
    border-radius: ${({ circle }) => (circle ? '100%' : '3px')};
  }
`;

const Checkbox = ({ background, circle, theme, type, appearance, className, margin, ...rest }) => (
  <>
    <Input type={type} {...rest} />
    {appearance === 'tick' && (
      <Tick background={background || theme} className={className} margin={margin} theme={theme} circle={circle} />
    )}
    {appearance === 'fill' && (
      <Fill background={background || theme} className={className} margin={margin} theme={theme} circle={circle} />
    )}
  </>
);

Checkbox.defaultProps = {
  circle: false,
  theme: 'black',
  type: 'checkbox',
  appearance: 'fill',
  margin: '0 1.5em 0 0',
  className: '',
  background: null
};

Checkbox.propTypes = {
  /** Forces the checkbox to be circlular in shape */
  circle: PropTypes.bool,
  /** Forces the checkbox into a colour scheme */
  theme: PropTypes.oneOf(['black', 'white', '']),
  /** Forces the checkbox to be curtain type */
  type: PropTypes.oneOf(['radio', 'checkbox']),
  /** 'fill': smaller dot or cicle when marked as checked. 'Tick': a check will appear when checked */
  appearance: PropTypes.oneOf(['fill', 'tick']),
  /** manage the checkbox margin */
  margin: PropTypes.string,
  /** className prop mainly for style components */
  className: PropTypes.string,
  /** chooses background colour of box. If nothing is passed will be based off of theme */
  background: PropTypes.string
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
