import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const useLooker = (countryCode = 'unknown') => {
  const [hasLooker, setHasLooker] = useState(false);
  const lookerMarkets = ['GB', 'IE', 'DE'];

  useEffect(() => {
    setHasLooker(lookerMarkets.includes(countryCode));
  }, [countryCode, lookerMarkets]);

  return hasLooker;
};

useLooker.propTypes = { countryCode: PropTypes.string.isRequired };

export default useLooker;
