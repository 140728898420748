import styled from 'styled-components';
import PropTypes from 'prop-types';

const Row = styled.div`
  display: flex;
  width: 100%;

  flex: ${({ flex }) => flex};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;

Row.defaultProps = {
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  padding: '0',
  margin: '0',
  flex: '0 1 auto'
};

Row.propTypes = {
  flex: PropTypes.string,
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  flexWrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  alignItems: PropTypes.oneOf(['normal', 'stretch', 'flex-start', 'center', 'flex-end']),
  justifyContent: PropTypes.oneOf([
    'normal',
    'space-evenly',
    'stretch',
    'space-around',
    'space-between',
    'center',
    'flex-start',
    'flex-end'
  ]),

  padding: PropTypes.string,
  margin: PropTypes.string
};

Row.displayName = 'Row';

export default Row;
