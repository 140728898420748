import styled from 'styled-components';
import PropTypes from 'prop-types';

const SubText = styled.span`
  color: ${(props) => props.color};
  text-align: ${(props) => props.align};
  display: block;
  font-weight: 400;
  font-size: 11px;
  margin: 0 0 5px;

  &.sr-only {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

SubText.span = SubText.withComponent('span');
SubText.h1 = SubText.withComponent('h1');
SubText.h2 = SubText.withComponent('h2');
SubText.h3 = SubText.withComponent('h3');
SubText.h4 = SubText.withComponent('h4');
SubText.h5 = SubText.withComponent('h5');
SubText.h6 = SubText.withComponent('h6');
SubText.p = SubText.withComponent('p');
SubText.label = SubText.withComponent('label');

SubText.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string
};

SubText.defaultProps = {
  color: '#8F8F8F',
  align: 'left'
};

SubText.displayName = 'SubText';

export default SubText;
