import styled from 'styled-components';
import PropTypes from 'prop-types';
import FluidType from '../../../helpers/fluidType';
import FontFamily from '../../../helpers/fontFamily';

const H4 = styled.h4`
  display: block;
  width: 100%;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  ${({ fonts }) => (fonts === 'LushHandwritten' ? 'font-weight: 400;' : 'font-weight: 600;')};
  ${({ fonts }) => FontFamily(fonts)};

  ${({ fontSize, lineHeight, minFont, minLine, maxFont, maxLine }) => {
    if (fontSize === '' && lineHeight === '') {
      return `${FluidType({ fontSize: [minFont, maxFont], lineHeight: [minLine, maxLine] })}`;
    }
    return `
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `;
  }}
`;

H4.h1 = H4.withComponent('h1');
H4.h2 = H4.withComponent('h2');
H4.h3 = H4.withComponent('h3');
H4.h4 = H4.withComponent('h4');
H4.h5 = H4.withComponent('h5');
H4.h6 = H4.withComponent('h6');
H4.p = H4.withComponent('p');
H4.span = H4.withComponent('span');
H4.label = H4.withComponent('label');

H4.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  minFont: PropTypes.number,
  minLine: PropTypes.number,
  maxFont: PropTypes.number,
  maxLine: PropTypes.number,
  fonts: PropTypes.oneOfType([PropTypes.oneOf(['inter', 'LushHandwritten']), PropTypes.string])
};

H4.defaultProps = {
  color: '#333',
  align: 'left',
  fontSize: '',
  lineHeight: '',
  margin: '0',
  padding: '0',
  minFont: 14,
  maxFont: 23,
  minLine: 21,
  maxLine: 29,
  fonts: 'inter'
};

H4.displayName = 'H4';

export default H4;
