import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ==================== //
// TABLE HEAD COMPONENT //
// ==================== //
export default class TableHead extends Component {
  renderActions(row) {
    const { actions, data, columns, mobile } = this.props;

    const styles =
      mobile === false
        ? {
            flexBasis: actions.flexBasis ? actions.flexBasis : 0,
            flexGrow: actions.flexBasis ? actions.flexBasis : 0,
            minWidth: actions.minWidth
          }
        : {};

    let cell = '';

    if (typeof actions.head === 'string' || typeof actions.head === 'number') {
      cell = actions.head;
    } else if (typeof actions.head === 'object') {
      cell = React.cloneElement(actions.head, {
        key: i,
        index: i,
        mobile,
        actions
      });
    }

    return (
      <div style={styles} key="actions" className={`ui-table__th ${mobile ? 'ui-table__th--is-mobile' : ''} `}>
        {cell}
      </div>
    );
  }

  renderRow() {
    const { actions, columns, mobile } = this.props;

    const row = columns.map((column, i) => {
      const styles =
        mobile === false
          ? {
              flexBasis: column.flexBasis ? column.flexBasis : `${100 / columns.length}%`,
              flexGrow: column.flexBasis == 0 ? 0 : 1,
              minWidth: column.minWidth
            }
          : {};

      let cell = '';

      if (typeof column.head === 'string' || typeof column.head === 'number') {
        cell = column.head;
      } else if (typeof column.head === 'object') {
        cell = React.cloneElement(column.head, {
          key: i,
          index: i,
          mobile,
          column
        });
      }

      return (
        <div style={styles} key={i} className={`ui-table__th ${mobile ? 'ui-table__th--is-mobile' : ''} `}>
          {cell}
        </div>
      );
    });

    if (actions !== null) {
      row.push(this.renderActions(row));
    }

    return row;
  }

  render() {
    const { mobile } = this.props;

    return <div className={`ui-table__thead ${mobile ? 'ui-table__thead--is-mobile' : ''} `}>{this.renderRow()}</div>;
  }
}
TableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired,
  actions: PropTypes.object
};
