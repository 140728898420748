import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PaymentTypes } from '@/types/PaymentTypes';
import {
  japanPaymentTypes,
  germanyPaymentTypes,
  ukPaymentTypes,
  iePaymentTypes,
  usPaymentTypes,
  usWithMoheganPaymentTypes,
  usWithPuertoRicoCreditPaymentTypes
} from '../../../helpers/paymentTypes';

import { getEnvironment } from '@/components/@lushdigital/helper-functions';

function usePaymentTypes(countryCode, shopId) {
  const [types, setTypes] = useState({});

  useEffect(() => {
    switch (countryCode) {
      case 'JP':
        setTypes(japanPaymentTypes as PaymentTypes);
        break;
      case 'DE':
        setTypes(germanyPaymentTypes as PaymentTypes);
        break;
      case 'IE':
        setTypes(iePaymentTypes as PaymentTypes);
        break;
      case 'US':
        if (getEnvironment() === 'production') {
          if (shopId === 1387) {
            setTypes(usWithMoheganPaymentTypes as PaymentTypes);
          } else if (shopId === 1572) {
            setTypes(usWithPuertoRicoCreditPaymentTypes as PaymentTypes);
          } else {
            setTypes(usPaymentTypes as PaymentTypes);
          }
        } else {
          if (shopId === 3255) {
            setTypes(usWithMoheganPaymentTypes as PaymentTypes);
          } else if (shopId === 3297) {
            setTypes(usWithPuertoRicoCreditPaymentTypes as PaymentTypes);
          } else {
            setTypes(usPaymentTypes as PaymentTypes);
          }
        }
        break;
      case 'GB':
      default:
        setTypes(ukPaymentTypes as PaymentTypes);
        break;
    }
  }, [countryCode, shopId]);

  return types as PaymentTypes;
}

usePaymentTypes.propTypes = { countryCode: PropTypes.string };

export default usePaymentTypes;
