import styled from 'styled-components';
import PropTypes from 'prop-types';
import colour from '../../../helpers/colour';

const Optional = styled.span`
  color: ${colour.darkgray};
  font-style: italic;
  font-weight: 400;
`;

Optional.propTypes = { children: PropTypes.string.isRequired };

Optional.displayName = 'Optional';

export default Optional;
