import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'lodash';

// helpers
import { removeElement } from '../../helpers/dom';

// COMPONENTS
import Button from './components/button';
import Input from './components/input';
import Group from './components/group';
import ErrorGroup from './components/errorGroup';

// STYLED COMPONENTS
const Wrapper = styled.div`
  z-index: 301;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  min-height: 40px;
  border-radius: 20px;
  transition: all 0.3s;
  vertical-align: top;
  align-self: normal;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}
  ${({ activate }) => (activate ? 'width: 200px;' : 'width: 40px;')}

  ${({ theme }) =>
    theme === 'white'
      ? `
    border: 1px solid #F6F7FB;
  `
      : `
    border: 1px solid #fff;
  `}
`;

const Overlay = styled.div`
  position: fixed;
  top: -100vh;
  left: -100vw;
  height: 300vh;
  width: 300vw;
  z-index: 300;

  ${({ theme }) =>
    theme === 'white'
      ? `
    background: rgba(255, 255, 255, 0.97);
  `
      : `
    background: rgba(0, 0, 0, 0.85);
  `}
`;

// COMPONENT
class TypeAhead extends Component {
  static getDerivedStateFromProps(props, state) {
    const { activate, value, error } = props;

    const updates = {};

    // If an activate prop is passed, override `activate` state with it
    if (activate !== 'none' && activate !== state.activate) {
      updates.activate = activate;
    }

    // If a value prop is passed, override `search` state with it
    if (value !== 'none' && value !== state.search) {
      updates.search = value;
    }

    return Object.keys(updates).length ? updates : null;
  }

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      debounceComplete: true,
      search: '',
      activate: false
    };

    this.search = debounce(this.search, Number(props.debounceTime));
    this.overlayRoot = null;
    this.wrapper = null;
    this.button = React.createRef();
  }

  componentDidMount() {
    this.overlayRoot = document.createElement('div');
    document.body.appendChild(this.overlayRoot);
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    removeElement(this.overlayRoot);
  }

  handleActivate = () => {
    const { customActivate } = this.props;

    if (customActivate !== null) {
      customActivate();
    }

    this.setState(() => ({ activate: true }));
  };

  handleDeactivate = () => {
    const { customActivate } = this.props;

    if (customActivate !== null) {
      customActivate();
    }

    this.setState(() => ({
      activate: false,
      search: ''
    }));

    if (this.button) {
      this.button.current?.focus();
    }
  };

  handleSelect = (item) => {
    const { onSelect, customActivate } = this.props;

    if (customActivate !== null) {
      customActivate();
    }

    onSelect(item);

    this.setState(() => ({
      activate: false,
      error: false,
      search: ''
    }));

    if (this.button) {
      this.button.current?.focus();
    }
  };

  handleChange(e) {
    const { value } = e.target;

    this.setState(
      () => ({ search: value, error: false, debounceComplete: false }),
      () => this.search(value)
    );
  }

  search = (value) => {
    const { onChange } = this.props;

    this.setState({ debounceComplete: true });
    onChange(value);
  };

  handleEnter = () => {
    const { options } = this.props;

    if (options.length) {
      this.handleSelect(options[0]);
    }
  };

  handleBackwardTab(e, focusableEls) {
    const firstElement = focusableEls[0];
    const lastElement = focusableEls[focusableEls.length - 1];

    if (document.activeElement === firstElement) {
      e.preventDefault();
      lastElement.focus();
    }
  }

  handleForwardTab(e, focusableEls) {
    const firstElement = focusableEls[0];
    const lastElement = focusableEls[focusableEls.length - 1];

    if (document.activeElement === lastElement) {
      e.preventDefault();
      firstElement.focus();
    }
  }

  handleKeyDown = (e) => {
    const focusableEls = []; // Array.prototype.slice.call(this.wrapper.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'));
    const KEY_TAB = 9;
    const KEY_ESCAPE = 27;

    switch (e.keyCode) {
      case KEY_TAB:
        if (e.shiftKey) {
          this.handleBackwardTab(e, focusableEls);
        } else {
          this.handleForwardTab(e, focusableEls);
        }

        break;
      case KEY_ESCAPE:
        this.handleDeactivate(e);

        break;
      default:
        break;
    }
  };

  render() {
    const {
      error: forceError,
      errorMessage,
      staticHeight,
      options,
      disabled,
      loading,
      direction,
      theme,
      overlay,
      classes,
      className,
      renderOptions
    } = this.props;
    const { debounceComplete, activate, search, error: stateError } = this.state;

    const error = (debounceComplete && forceError) || stateError;

    return [
      activate && overlay && <Overlay key="overlay" theme={theme} />,
      <Wrapper
        staticHeight={staticHeight}
        key="wrapper"
        disabled={disabled}
        activate={activate}
        theme={theme}
        tabIndex={-1}
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
        innerRef={(e) => {
          this.wrapper = e;
        }}
        className={`ui-type-ahead ${classes} ${className !== '' ? `${className}-type-ahead` : ''}`}
      >
        {/* TEXT INPUT */}
        {activate && (
          <Input
            className={className}
            onEnter={this.handleEnter}
            disabled={disabled}
            onChange={(e) => this.handleChange(e)}
            value={search}
            direction={direction}
            theme={theme}
          />
        )}
        {/* TOGGLE BUTTON */}
        <Button
          button={this.button}
          className={className}
          disabled={disabled}
          loading={loading}
          onActivate={this.handleActivate}
          onDeactivate={this.handleDeactivate}
          activate={activate}
          direction={direction}
          theme={theme}
        />
        {/* DISPLAY FOR RESULTS */}
        {!error && activate && search.trim() !== '' && !disabled && options.length > 0 && (
          <Group
            staticHeight={staticHeight}
            className={className}
            options={options}
            onSelect={this.handleSelect}
            renderOptions={renderOptions}
            theme={theme}
            direction={direction}
          />
        )}
        {/* error */}
        {error && activate && search.trim() !== '' && (
          <ErrorGroup staticHeight={staticHeight} message={errorMessage} direction={direction} />
        )}
      </Wrapper>
    ];
  }
}

TypeAhead.defaultProps = {
  staticHeight: true,
  disabled: false,
  loading: false,
  direction: 'left',
  theme: 'black',
  overlay: true,
  classes: '',
  className: '',
  customActivate: null,
  renderOptions: null,
  activate: 'none',
  value: 'none',
  debounceTime: 300,
  error: false,
  errorMessage: ''
};

TypeAhead.propTypes = {
  /**
   * For the height of the inner options to affect height of container
   */
  staticHeight: PropTypes.bool,
  /**
   * stop users being able to do anything
   */
  disabled: PropTypes.bool,
  /**
   * swap plus / close icon with loading icon
   */
  loading: PropTypes.bool,
  /**
   * Show / hide the background 'wash'
   */
  overlay: PropTypes.bool,
  /**
   * List of options to display
   */
  options: PropTypes.array.isRequired,
  /**
   * Function ran when an option is clicked
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Function ran when user types something
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Custom function for rendering the typeahead options
   */
  renderOptions: PropTypes.func,
  /**
   * Force open or close
   */
  activate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  customActivate: PropTypes.func,
  classes: PropTypes.string,
  className: PropTypes.string,
  /**
   * Show input to the left or right of the toggle
   */
  direction: PropTypes.oneOf(['left', 'right']),
  /**
   *
   */
  theme: PropTypes.oneOf(['white', 'black']),
  /**
   * Change amount of time for the request to fire while user types
   */
  debounceTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * display error
   */
  error: PropTypes.bool,
  /**
   * message to show when error is true
   */
  errorMessage: PropTypes.string
};

TypeAhead.displayName = 'TypeAhead';

export default TypeAhead;
