import { getIndexByKey, getObjectByKey } from './functions/objects';

import {
  numberWithCommas,
  formatCurrency,
  formatNum // Deprecated
} from './functions/formatting';

import { debounce } from './functions/debounce';

import { shouldUpdate, checkForContent } from './functions/update';

import { isBrowser, removeElement } from './functions/dom';

import {
  getEnvironment,
  getLoginUrl,
  getEnvRoot,
  getGatewayUrl,
  getApps,
  appEnvironmentRoutes
} from './functions/environment';

import { isLoading, isError, getRequestStatus } from './functions/apollo';

const ENV = process.env.NODE_ENV;
const { NEXT_PUBLIC_BUILD_ENV } = process.env;
const { REACT_APP_BUILD_ENV } = process.env;

export {
  // objects
  getIndexByKey,
  getObjectByKey,

  // formatting
  numberWithCommas,
  formatCurrency,
  formatNum, // Deprecated

  // debounce
  debounce,

  // update
  shouldUpdate,
  checkForContent,

  // environment
  ENV,
  NEXT_PUBLIC_BUILD_ENV,
  REACT_APP_BUILD_ENV,
  getEnvironment,
  getLoginUrl,
  getEnvRoot,
  getGatewayUrl,
  getApps,
  appEnvironmentRoutes,

  // dom
  isBrowser,
  removeElement,

  // apollo
  isLoading,
  isError,
  getRequestStatus
};
