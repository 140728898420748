import styled from 'styled-components';
import Button from '../../atoms/button';

export const Container = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ outerPadding }) => (outerPadding ? '20px' : null)};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${({ transparent }) => transparent});
`;

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ bodyBackground }) => bodyBackground};
  z-index: 100;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  max-width: ${({ setWidth }) => setWidth};

  ${({ forceHeight, setHeight }) =>
    forceHeight
      ? `
    height: ${setHeight};

    @media (max-height: ${setHeight}) {
      max-height: 100%;
    }
  `
      : `
    max-height: ${setHeight};

    @media (max-height: ${setHeight}) {
      max-height: 100%;
    }
  `}

  @media (max-width: ${({ mobileFullscreen }) => mobileFullscreen}) {
    min-height: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  ${({ sticky }) =>
    sticky
      ? `
    position: relative;
    position: sticky;
    top: 0;
    left: 0;
  `
      : `
    position: relative;
  `}

  z-index: 10;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
  padding: 20px 24px 15px;
`;

export const Close = styled(Button)`
  padding: 10px;
  position: absolute;
  right: 10px;
  opacity: 0.7;
  line-height: 0;

  ${({ header }) =>
    !header
      ? `
    top: 10px;
  `
      : `
    top: 50%;
    transform: translateY(-50%);
  `}

  &:hover {
    opacity: 1;
  }
`;

export const Body = styled.div`
  flex: 1;
  ${({ padding }) => (padding ? 'padding: 20px;' : '')}
`;

export const Footer = styled.div`
  z-index: 10;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eaeaea;
  padding: 15px 24px 20px;

  ${({ sticky }) =>
    sticky
      ? `
    position: relative;
    position: sticky;
    bottom: 0;
    left: 0;
  `
      : `
    position: relative;
  `}
`;
