import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';

import Button from '../button';
import Loader from '../loader';
import SrOnly from '../srOnly';

// icon styling
const FancyIcon = styled.div`
  position: relative;
  ${({ position, iconOffset }) =>
    position === 'before'
      ? `
    margin-right: ${iconOffset};
  `
      : `
    margin-left: ${iconOffset};
  `}
  line-height: 0;

  ${({ circle, theme }) =>
    circle
      ? `
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.99);
      border: 1px solid ${theme};
      height: 3.6em;
      width: 3.6em;
      border-radius: 3.6em;
    }
  `
      : ''}
`;

// theme checker
const isWhiteTheme = (theme) =>
  ['default', 'default-solid', 'inverse-clear', 'inverse-floating', 'error', 'warning', 'success'].includes(theme);
// exported component
class CTA extends React.PureComponent {
  renderContent = () => {
    const { iconSize, iconOffset, iconCircle, children, iconBefore, iconAfter, showContent, theme } = this.props;

    return (
      <>
        {iconBefore !== '' && (
          <FancyIcon
            position="before"
            circle={iconCircle}
            iconOffset={iconOffset}
            theme={isWhiteTheme(theme) ? 'white' : 'black'}
          >
            <Icon icon={iconBefore} size={iconSize} styles={{ pointerEvents: 'none' }} />
          </FancyIcon>
        )}
        {/* For some reason passing children outside of another element crashes IE */}
        {showContent && <>{children}</>}
        {!showContent && <SrOnly>{children}</SrOnly>}
        {iconAfter !== '' && (
          <FancyIcon
            position="after"
            circle={iconCircle}
            iconOffset={iconOffset}
            theme={isWhiteTheme(theme) ? 'white' : 'black'}
          >
            <Icon icon={iconAfter} size="1.4em" styles={{ pointerEvents: 'none' }} />
          </FancyIcon>
        )}
      </>
    );
  };

  render() {
    const { style, loading, theme, className, ...props } = this.props;

    const styles = {
      ...(loading ? { color: 'transparent' } : {}),
      ...style
    };

    return (
      <Button {...props} className={className} style={styles} theme={theme}>
        {this.renderContent()}
        {loading && <Loader position="absolute" theme={isWhiteTheme(theme) ? 'white' : 'black'} />}
      </Button>
    );
  }
}

// Default props
CTA.defaultProps = {
  children: null,
  iconBefore: '',
  iconAfter: '',
  showContent: true,
  loading: false,
  iconCircle: false,
  iconSize: '1.4em',
  iconOffset: '1.8em',
  theme: 'default',
  style: null,
  className: null
};

// Prop type check
CTA.propTypes = {
  /** Multiple pre-defined themes exist. Current does not support styled components theme prop. 🙈 */
  theme: PropTypes.oneOf([
    'default',
    'default-clear',
    'default-floating',
    'inverse',
    'inverse-clear',
    'inverse-floating',
    'error',
    'warning',
    'success'
  ]),
  /** render what ever you want within a button */
  children: PropTypes.any,
  /** this prop will render any children within a <SrOnly /> component meaning only screen readers will be able to 'see' them */
  showContent: PropTypes.bool,
  /** select an icon to render before any children */
  iconBefore: PropTypes.string,
  /** select an icon to render after any children */
  iconAfter: PropTypes.string,
  /** add a cirular border around any icons you want to render */
  iconCircle: PropTypes.bool,
  /** when true the content within the button will be replaced with a <Loader /> */
  loading: PropTypes.bool,
  /** Define the size of the icon you want to render. Pass directly to an <Icon /> as it's size prop */
  iconSize: PropTypes.string,
  /** change the spacing between inner elements and any icons. Add right spacing for iconBefore and left spacing for iconAfter. */
  iconOffset: PropTypes.string,
  /** style object to style the <Button /> element */
  style: PropTypes.object,
  /** class to be added to the <Button /> element, useful if you plan to expend the component using styled components */
  className: PropTypes.string
};

CTA.displayName = 'CTA';

export default CTA;
