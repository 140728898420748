import React, { useMemo } from 'react';
import { useStore } from '@/manager-next/store';

/**
 * Actions
 */
// import { getLocation } from '../../actions/locations';

import { FeaturesContext } from './context';

/**
 * Hooks
 */
import usePaymentTypes from './hooks/paymentTypes';
import useEodCount from './hooks/eodCount';
import useLooker from './hooks/looker';
import useFeatureFlags from './hooks/featureFlags';

const FeatureProvider = ({ children }) => {
  const shopFromGlobalState = useStore((state) => state.shop);
  const countryCode = useStore((state) => state.shopInfo.countryCode);
  const timezone = useStore((state) => state.shopInfo.timezone);

  const eodCount = useEodCount(['JP', 'DE', 'US', 'CA'], countryCode);
  const paymentTypes = usePaymentTypes(countryCode, shopFromGlobalState);

  /* To find out if market that store is in has Looker data */
  const hasLooker = useLooker(countryCode);
  const featureFlags = useFeatureFlags(countryCode, shopFromGlobalState);

  const value = useMemo(
    () => ({
      features: {
        shopId: shopFromGlobalState,
        timezone,
        countryCode,
        eodCount,
        paymentTypes,
        hasLooker,
        featureFlags
      }
    }),
    [shopFromGlobalState, timezone, eodCount, countryCode, hasLooker, paymentTypes, featureFlags]
  );

  return <FeaturesContext.Provider value={value}>{children}</FeaturesContext.Provider>;
};

export default FeatureProvider;
