import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

// Exported default element
const scaleFadeWhite = keyframes`
  0% {
    background-color: rgba(255, 255, 255, 0);
    transform: scale(0);
  }
  5% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
    transform: scale(1);
  }
`;

const scaleFadeBlack = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(0);
  }
  5% {
    background-color: rgba(0, 0, 0, 1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(1);
  }
`;

const interpolatedThemeColor = css`
  animation: ${(props) => (props.theme === 'white' ? scaleFadeWhite : scaleFadeBlack)} 1.3s 0s linear infinite;
`;

// Styling
const StyledLoader = styled.div`
  box-sizing: border-box;
  position: relative;
  font-size: ${(props) => props.fontSize};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  will-change: transform, opacity;

  ${(props) =>
    props.position === 'absolute'
      ? `
    position: absolute;
  `
      : ''}

  ${(props) => {
    switch (props.align) {
      case 'top-left': {
        if (props.position === 'absolute') {
          return `
            top: 10px;
            left: 10px;
            transform: translate3d(0, 0, 0);
          `;
        }
        return 'margin: 0 auto 0 0;';
      }
      case 'top-center': {
        if (props.position === 'absolute') {
          return `
            top: 10px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
          `;
        }
        return 'margin: 0 auto;';
      }
      case 'top-right': {
        if (props.position === 'absolute') {
          return `
            top: 10px;
            right: 10px;
            transform: translate3d(0, 0, 0);
          `;
        }
        return 'margin: 0 0 0 auto;';
      }
      case 'left':
      case 'center-left': {
        if (props.position === 'absolute') {
          return `
            top: 50%;
            left: 10px;
            transform: translate3d(0, -50%, 0);
          `;
        }
        return 'margin: 0 auto 0 0;';
      }
      case 'right':
      case 'center-right': {
        if (props.position === 'absolute') {
          return `
            top: 50%;
            right: 10px;
            transform: translate3d(0, -50%, 0);
          `;
        }
        return 'margin: 0 0 0 auto;';
      }
      case 'bottom-left': {
        if (props.position === 'absolute') {
          return `
            bottom: 10px;
            left: 10px;
            transform: translate3d(0, 0, 0);
          `;
        }
        return 'margin: 0 auto 0 0;';
      }
      case 'bottom-center': {
        if (props.position === 'absolute') {
          return `
            bottom: 10px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
          `;
        }
        return 'margin: 0 auto;';
      }
      case 'bottom-right': {
        if (props.position === 'absolute') {
          return `
            bottom: 10px;
            right: 10px;
            transform: translate3d(0, 0, 0);
          `;
        }
        return 'margin: 0 0 0 auto;';
      }
      case 'center':
      case 'center-center':
      default: {
        if (props.position === 'absolute') {
          return `
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          `;
        }
        return 'margin: 0 auto;';
      }
    }
  }} }

  &:before,
  &:after,
  div {
    content: '';
    will-change: transform, opacity;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3em;
    width: 6em;
    height: 6em;

    ${interpolatedThemeColor}
  }

  &:before {
    animation-delay: -0.4s;
  }

  &:after {
    animation-delay: -0.2s;
  }
`;

const Loader = (props) => (
  <StyledLoader {...props}>
    <div />
  </StyledLoader>
);

// Default props
Loader.defaultProps = {
  fontSize: '10px',
  size: '6em',
  margin: '0',
  theme: 'black',
  position: 'static',
  align: 'center'
};

// Prop type check
Loader.propTypes = {
  fontSize: PropTypes.string,
  size: PropTypes.string,
  margin: PropTypes.string,
  theme: PropTypes.oneOf(['black', 'white']),
  position: PropTypes.oneOf(['static', 'absolute']),
  align: PropTypes.oneOf([
    'top-left',
    'top-center',
    'top-right',
    'left',
    'center',
    'right',
    'center-left',
    'center-center',
    'center-right',
    'bottom-left',
    'bottom-center',
    'bottom-right'
  ])
};

Loader.displayName = 'Loader';

// export
export default Loader;
