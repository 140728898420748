import styled from 'styled-components';
import PropTypes from 'prop-types';
import FontFamily from '../../../helpers/fontFamily';

const Title = styled.span`
  display: block;
  width: 100%;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  ${({ fonts }) => (fonts === 'LushHandwritten' ? 'font-weight: 400;' : 'font-weight: 600;')};
  ${({ fonts }) => FontFamily(fonts)};
`;

Title.h1 = Title.withComponent('h1');
Title.h2 = Title.withComponent('h2');
Title.h3 = Title.withComponent('h3');
Title.h4 = Title.withComponent('h4');
Title.h5 = Title.withComponent('h5');
Title.h6 = Title.withComponent('h6');
Title.p = Title.withComponent('p');
Title.span = Title.withComponent('span');
Title.label = Title.withComponent('label');

Title.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.string,
  align: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  fonts: PropTypes.oneOfType([PropTypes.oneOf(['inter', 'LushHandwritten']), PropTypes.string])
};

Title.defaultProps = {
  color: '#333',
  margin: '0',
  padding: '0',
  align: 'center',
  fontSize: '17px',
  lineHeight: '21px',
  fonts: 'inter'
};

Title.displayName = 'Title';

export default Title;
