import { find, get, hasIn, isEmpty, map, includes, every } from 'lodash';
import Moment from 'moment-timezone';
import { shouldUpdate } from '@lushdigital/helper-functions';

import { getCashTransactionsByCurrency, getSalesByCurrency, getSubTotalsByCurrency } from './currency';
import { within4HoursOfClose, isYesterday, isToday, isPastDate } from './time';

//
// CHECK SINGLE TILL EOD STATE
//
export function getTillEODReportState({ eod, date, till }) {
  // First off lets see if we have the eod summary data yet
  if (hasIn(eod, `${date}.data`) && !isEmpty(eod[date].data)) {
    // if we have the eod details lets check the till details.
    const tillReport = eod[date].data.states.reports.find((t) => t.till_id === Number(till));

    if (tillReport) {
      // If report is already generated redirect with an error message
      if (tillReport.report_generated) {
        return 'generated';
      }
      return 'fetched';
    }
  }
  return null;
}

//
// UPDATE TILL COUNT DISCREPANCY FOR TILL REPORT SUBMISSION
//
export function updateTillCountDiscrepancy({ currency, summary, currentCount = 0 }) {
  let tillCash = find(getSubTotalsByCurrency(summary, currency), {
    type: 'cash'
  });
  tillCash = !isEmpty(tillCash) ? tillCash : { amount: 0 };
  let cashOut = 0;
  let cashIn = 0;
  let openingFloat = 0;
  if (summary.cash_in) {
    cashIn = getCashTransactionsByCurrency(summary.cash_in, currency).reduce(
      (previous, payment) => previous + payment.amount,
      0
    );
  }
  if (summary.cash_out) {
    cashOut = getCashTransactionsByCurrency(summary.cash_out, currency).reduce(
      (previous, payment) => previous + payment.amount,
      0
    );
  }
  if (summary.opening_float) {
    openingFloat = summary.opening_float.amount;
  }
  const discrepancy = currentCount - (tillCash.amount + cashIn + openingFloat - cashOut);

  return {
    discrepancy,
    openingFloat
  };
}

//
// CAN EOD THE STORE FOR CURRENT DAY
//
export const canDeclareShopEOD = ({ date, nextEod, datedEod, shopInfo, isAdmin, tills }) => {
  const { timezone } = shopInfo;

  if (!nextEod || !datedEod) return false; // TODO check this works

  const { ShopDate: nextEodDate, tills: nextEodTills } = nextEod;

  const dateIsInPast = isYesterday(date, timezone) || isPastDate(date, timezone);

  const eodIsDeclared = datedEod?.data?.eod_signature !== null || false;
  const tillReads = datedEod?.data?.reports;

  // All tills in a shop
  const allAvailableTillsDeclared = every(
    tillReads,
    (till) => till.report_generated === true || tills[till.till_id].state === 'offline'
  );

  if (!allAvailableTillsDeclared) return false;

  // We want to allow submission of past eods for admins
  // Checks if eod has been declared to prevent from double submission
  // Checks if all tills have been declared
  if (isAdmin && dateIsInPast && !eodIsDeclared && allAvailableTillsDeclared) {
    return true;
  }

  // Check if the shop has traded
  if (nextEodDate !== null || nextEodTills !== null) {
    // Check if all tills that have traded are submitted
    const allNextEodTillsAreSubmitted = !includes(nextEodTills, false);

    // Check if all tills that have traded are submitted
    if (allNextEodTillsAreSubmitted && nextEodDate === date) {
      // For today, check if shop is allowed to eod today and is within 4 hours of closing time
      if (isToday(date, timezone) && within4HoursOfClose({ shopInfo })) {
        return true;
      }
      // For past dates only allow when next eod date is submitted date
      if (dateIsInPast) {
        return true;
      }
    }
  }
  return false;
};

//
// CHECK IF TILL IS SUCCESSFULLY DECLARED FOR A SPECIFIC EOD DATE
// datedEod is eod[date]
//
export const tillIsDeclared = ({ datedEod = {}, tillId }) => {
  if (!isEmpty(datedEod.data)) {
    const tillRead = datedEod.data.reports[tillId];
    if (tillRead?.report_generated) {
      return true;
    }
  }
  return false;
};

//
// CAN DECLARE TILL READ FOR CURRENT DAY
//
export const canDeclareTillRead = ({ date, datedEod, nextEod, till, shopInfo, isAdmin }) => {
  const timezone = shopInfo?.timezone;

  const state = till?.state;
  const tillId = till?.till_id;

  const nextEodDate = nextEod?.next?.ShopDate;
  const nextEodTills = nextEod?.next?.TillDates;

  if (!timezone || !state || !tillId) {
    return false;
  }

  const dateIsInPast = isYesterday(date, timezone) || isPastDate(date, timezone);

  // We want to allow submission of past eods for admins
  // Checks if till has been declared to prevent from double submission
  if (isAdmin && dateIsInPast && !tillIsDeclared({ datedEod, tillId })) {
    return true;
  }

  // Checks if there are tills that need to be declared
  if (nextEodDate !== null || nextEodTills !== null) {
    // For today, only allow submission if
    // - the shop is within 4 hours of closing
    // - the till is closed, offline or faulty but not open for trading or cashed up
    if (
      isToday(date, timezone) &&
      within4HoursOfClose({ shopInfo }) &&
      (state === 'closed' || state === 'offline' || state === 'faulty' || state === 'closed_cashed')
    ) {
      // Only allow submission if till has traded and has not already been declared
      if (!tillIsDeclared({ datedEod, tillId }) && date === nextEodDate) {
        return true;
      }
    }
    // For past dates, only allow submission, if
    // - till has not already been declared
    // - the till has traded on selected date
    // And the submission is only allowed for the next eod date (unless admin - see above)
    if (dateIsInPast) {
      if (!tillIsDeclared({ datedEod, tillId }) && date === nextEodDate) {
        return true;
      }
    }
  }
  return false;
};

// Check the EOD state to decide whether to fetch. Return true if EOD is not already fetching AND:
// 1. EOD data is not present OR
// 2. EOD data is old
//
export function shouldFetchEOD(eodData) {
  const { data, fetching, error, last_updated } = eodData || {};

  return (isEmpty(data) || shouldUpdate(last_updated, 5 * 60)) && !fetching && !error;
}

//
// GET SAFE BANKING DECLARATIONS FROM TILL READS
//
export function getSafeBankingReads({ tillReads }) {
  return map(tillReads, (till) => {
    if (hasIn(till, 'report.declarations')) {
      return find(till.report.declarations, {
        transaction_type: 'safe_banking'
      });
    }
    return null;
  }).filter((item) => item);
}

//
// SUM UP TILL READS WITH STARTER DENOMINATIONS
//
export function sumUpTillReads({ currentBanking, tillReads, date }) {
  // Clone so we can update without mutating state
  const expectedBank = {
    ...currentBanking
  };
  // If we have the reports lets loop over them and add to values to state
  const safeBankingReads = getSafeBankingReads({
    tillReads,
    date
  });
  safeBankingReads.forEach((read) => {
    // aka till
    // Map over individual current banking denominations and add to read values
    const newDenominationValues = expectedBank.denominations.map((denomination) => {
      // find value already in currentExpectedBank
      const readValue = find(read.denominations, {
        amount: denomination.amount
      });

      // If we have it as a valid currency adjust the totals
      if (!isEmpty(readValue)) {
        // Update total and qty fields
        const total = denomination.total + readValue.total;
        const qty = denomination.qty + readValue.qty;
        // Modify total for object as we go to save another pass
        expectedBank.total += readValue.total;

        return {
          ...denomination,
          qty,
          total
        };
      }
      return denomination;
    });
    // Assign new denominations object back so it is available for the next iteration
    expectedBank.denominations = newDenominationValues;
  });

  return expectedBank;
}

//
// CALCULATE THE VARIANCE ACROSS ALL COUNTS AND BANKING VALUES
//
export function calculateBankingVariance({ count, expectedBank }) {
  const variance = {
    denominations: [],
    total: 0
  };
  variance.denominations = expectedBank.denominations.map((denomination) => {
    const countValue = find(count.denominations, {
      amount: denomination.amount
    });
    const total = countValue.total - denomination.total;
    variance.total += total;
    return {
      ...denomination,
      total,
      qty: countValue.qty - denomination.qty
    };
  });
  return variance;
}

//
// HELPERS FOR CREATING A CASH TRANSACTION
//
export function numberPadded(n = 0, width = 5, z = '0') {
  // z = z || '0';
  // n += '';
  const nAsString = String(n);
  return nAsString.length >= width ? nAsString : new Array(width - nAsString.length + 1).join(z) + nAsString;
}

//
// GENERATE TRANSCTION ID
//
export function generateTransactionID(userId, tillId, shopId, time = Moment().format('YYYYMMDDHHmmss')) {
  if (userId && tillId && shopId) {
    return `${numberPadded(userId)}${numberPadded(tillId)}${numberPadded(shopId)}${time}`;
  }
  return false;
}

//
// PREPARE DENOMINATIONS FOR SUBMISSION
//
export function prepareDenominations({ denominations, date }) {
  let denominationsUpdated = [];
  if (denominations && denominations.length > 0) {
    denominationsUpdated = denominations.map((denomination, i) => ({
      ...denomination,
      qty: denomination.total / denomination.amount,
      timestamp: date,
      sequence: i
    }));
  }
  return denominationsUpdated;
}

//
// GENERATE EOD BANKING SUBMISSION TRANSACTION
//
export function generateTransactionObject({
  date,
  count,
  eod,
  shop,
  currency,
  type = 'safe_banking',
  generator_id = 99999
}) {
  const formattedDate = Moment(`${date}T12:00:00Z`).format('YYYY-MM-DDTHH:mm:ssZ'); // Lock to midday or the date breaks in when the timezone offset pushes the date into a different day
  // const realFormattedDate = Moment(date + Moment().format('THH:mm:ssZ')).format('YYYY-MM-DDTHH:mm:ssZ'); // Dynamic for submitting EOD in next day

  const transaction_number = generateTransactionID(
    eod.user_id,
    generator_id,
    shop,
    Moment(formattedDate).format('YYYYMMDDHHmmss')
  ); // TODO: Set back to 00000

  const denominations = prepareDenominations({
    denominations: count.denominations,
    date: formattedDate
  });
  return {
    transaction_number,
    transaction_type: type,
    transaction_status: 'completed',
    timestamp: formattedDate,
    producer_id: shop,
    generator_id, // TODO: Set back to 00000
    operator_id: eod.user_id,
    cash_drawer_id: 3, // Until we have a way to get tills cash draw
    amount: count.total,
    amount_type: 'cash',
    currency_code: currency,
    denominations
  };
}

//
// CALCULATE COUNT VARIANCE
//
export function calculateCountVariance({ read, count, currency }) {
  let result = {
    amount: 0,
    currency
  };

  if (read && !isEmpty(read)) {
    const pickups = getSalesByCurrency(read.pickups, currency);
    const floatIn = getSalesByCurrency(read.float_in, currency);
    const pettyCashIn = getSalesByCurrency(read.incomes, currency);
    const pettyCashOut = getSalesByCurrency(read.expenses, currency);
    const cashSales = getSalesByCurrency(read?.total_takings?.cash, currency);
    let openingFloat = find(read.declarations, {
      transaction_type: 'opening_float'
    });

    if (!openingFloat) {
      openingFloat = { amount: 0 };
    }

    const variance =
      (count?.amount || 0) -
      (cashSales.amount +
        floatIn.amount +
        openingFloat.amount +
        pettyCashIn.amount -
        (pickups.amount + pettyCashOut.amount));

    result = {
      amount: variance,
      currency
    };
  }
  return result;
}

//
// CALCULATE FOREIGN CASH
//
export const sumUpForeignCash = ({ tillReads }) => {
  const declarations = map(tillReads, (till) => {
    if (hasIn(till, 'report.declarations')) {
      return find(till.report.declarations, {
        transaction_type: 'declaration_counted_foreign_cash'
      });
    }
    return null;
  }).filter((item) => item);
  if (!isEmpty(declarations)) {
    const amount = declarations.reduce((acc, declaration) => acc + declaration.amount, 0);
    const currency = declarations[0].currency_code;
    return {
      amount,
      currency
    };
  }
  return null;
};

export const sumUpVouchers = ({ tillReads, currency, date }) =>
  map(get(tillReads, `[${date}]`, []), (till) =>
    get(till, `report.total_takings['jp_voucher'][${currency}].amount`, 0)
  ).filter((item) => item);

// CN A function that was removed in the past that _might_ be useful.
// //
// // CHECK TILLS ARE DECLARED FOR CURRENT DATE
// //
// export function checkTillsDeclared({ date, tills, tillReads, timezone }) {
//   // Get today's date in YYYY-MM-DD format
//   const today = getTodaysDate(timezone);
//   const eodExcludedState = [AppConfig.tillStates.OFFLINE, AppConfig.tillStates.FAULTY];
//   let eodPossible = true;
//   forEach(tills.tills, (till) => {
//     // If the till isn't one of the untraded states
//     // check if we have the EOD report generated
//     if (!eodExcludedState.includes(till.state)) {
//       // Now check we have an till read for this till
//       if (!hasIn(tillReads, `${date || today}.${till.till_id}`) || !tillReads[date || today][till.till_id].report) {
//         eodPossible = false;
//       }
//     }
//   });
//   return eodPossible;
// }
