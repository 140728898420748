import styled from 'styled-components';

// INLINE MENU //
export const InlineMenuWithStyles = styled('div')`
  z-index: 5;
  background: none;

  /* White backgound overlay */
  &:before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &.is-active:before {
    width: 100%;
    height: calc(100% + 3px);
    background: rgba(255, 255, 255, 0.98);
  }

  &:not(.ui-inline-menu--card) {
    width: 100%;
    height: 100%;
  }

  /* Contexts */
  &.ui-inline-menu--card {
    display: flex;
    justify-content: flex-end;
  }

  &.ui-inline-menu--table--card {
    display: flex;
    justify-content: flex-end;
  }

  &.ui-inline-menu--float {
    position: relative;
  }

  /* // Wrapper for actions and toggle */
  .ui-inline-menu__wrapper {
    display: inline-block;

    .ui-inline-menu--card & {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  /* // Container for actions */
  .ui-inline-menu__container {
    position: absolute;
    display: flex;
    width: 100%;
    visibility: hidden;
    justify-content: flex-end;
  }

  &.is-active .ui-inline-menu__container {
    visibility: visible;
  }

  /* Contexts */
  &.ui-inline-menu--card .ui-inline-menu__container {
    flex-direction: column;
    right: 0;
    bottom: 60px;
    left: 0;
    padding: 0 0 5px;
    width: calc(100% - 60px);
    text-align: right;
  }

  &.ui-inline-menu--table .ui-inline-menu__container {
    flex-direction: row;
    right: 60px;
    width: calc(100% - 60px);
    padding: 0 10px 0 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
  }

  &.ui-inline-menu--table--card .ui-inline-menu__container {
    flex-direction: column;
    right: 0;
    bottom: 40px;
    left: 0;
    padding: 0 0 10px;
    text-align: right;
  }

  &.ui-inline-menu--float .ui-inline-menu__container {
    flex-direction: row;
    justify-content: flex-start;
    left: 65px;
    width: calc(100% - 65px);
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
  }

  /* // Toggle button */
  &.is-active .ui-inline-menu__toggle {
    transition: transform 250ms;
    transform: rotate(0);
  }

  .ui-inline-menu__toggle {
    cursor: pointer;
    outline: none;
    padding: 10px;
    border-radius: 40px;
    background: #000;
    height: 40px;
    width: 40px;
    overflow: hidden;
    border: none;
    transform: rotate(90deg);
    transition: transform 0.1s;

    &:focus,
    &:active {
      box-shadow: inset 0 0 0 0.4em rgba(0, 123, 255, 0.75);
      border-color: #80bdff;
      outline: none;
    }

    svg {
      fill: #fff;
    }
  }

  /* // Action wrapper */
  &.is-active .ui-inline-menu__item {
    transform: scale(1);
    transition-duration: 0.3s;
  }

  .ui-inline-menu__item {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    transform: scale(0);
    transition-duration: 0.1s;
  }

  /* Contexts */
  &.ui-inline-menu--card .ui-inline-menu__item {
    padding-top: 5px;
    padding-left: 0;
    padding-bottom: 5px;
    padding-right: 0;
  }

  &.ui-inline-menu--table .ui-inline-menu__item {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.ui-inline-menu--table--card .ui-inline-menu__item {
    padding-left: 0;
    padding-right: 0;
  }

  &.ui-inline-menu--float .ui-inline-menu__item {
    padding-top: 0;
    padding-bottom: 0;
  }

  /* // Action link */
  .ui-inline-menu__button,
  .ui-inline-menu__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    -webkit-appearance: none;
    border: none;
    background: transparent;
    margin: 0;
    padding: 10px;
    color: #333;

    &:focus,
    &:active {
      box-shadow: inset 0 0 0 0.4em rgba(0, 123, 255, 0.75);
      border-color: #80bdff;
      outline: none;
    }
  }

  &.ui-inline-menu--table {
    .ui-inline-menu__button,
    .ui-inline-menu__link {
      flex-direction: row-reverse;
    }
  }

  /* // Action text */
  .ui-inline-menu__text {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2.5px;
    white-space: nowrap;
    color: #333;
  }

  /* // Action icon */
  .ui-inline-menu__icon {
    cursor: pointer;
    outline: none;
    padding: 10px;
    border-radius: 40px;
    background: #fff;
    height: 40px;
    width: 40px;
    border: 1px solid #000;
    display: flex;
    margin-left: 10px;
    box-sizing: border-box;
    svg-fill: #000;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0px;
      top: 0px;
      height: 40px;
      border-radius: 40px;
      width: 40px;
    }
  }

  &.ui-inline-menu--table .ui-inline-menu__icon {
    margin-left: 0;
    margin-right: 10px;
  }
`;
