import PropTypes from 'prop-types';
import styled from 'styled-components';

// base typography component
import base from '../base';

// element
const Label = styled('label')`
  ${base};
`;

// default props for element
Label.defaultProps = {
  margin: '0 0 3px',
  textSize: '12px',
  textWeight: '500',
  lineHeight: '1.91'
};

Label.propTypes = {
  margin: PropTypes.string,
  textSize: PropTypes.string,
  textWeight: PropTypes.string,
  lineHeight: PropTypes.string
};

// DisplayName for new element
Label.displayName = 'Label';

// export element
export default Label;
