import PropTypes from 'prop-types';
import styled from 'styled-components';

// base typography component
import base from '../base';

// helpers
import colour from '../colour';

// element
const Error = styled('span')`
  ${base}
`;

// default props for element
Error.defaultProps = {
  textSize: '12px',
  lineHeight: '18px',
  color: colour.red
};

Error.propTypes = {
  textSize: PropTypes.string,
  lineHeight: PropTypes.string,
  color: PropTypes.string
};

// DisplayName for new element
Error.displayName = 'Error';

// export element
export default Error;
