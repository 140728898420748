import { isEmpty } from 'lodash';
// import Moment from 'moment-timezone';
import { getCurrencyDetails } from './currency';

//
// GET SHOP DATA FROM STATE
//
export function getShopData({ shops, id }) {
  const shopID = Number(id);
  const shop = shops[shopID];
  if (!isEmpty(shop)) {
    return {
      shop,
      currency: getCurrencyDetails(shop.default_currency)
    };
  }
  return null;
}

export function getCurrencyData(currency) {
  return getCurrencyDetails(currency);
}
