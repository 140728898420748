import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Icon from '@lushdigital/icons';

import { Wrapper, SearchIcon, Input } from './_styles';

class Search extends Component {
  search = debounce(() => {
    const { onSearch } = this.props;
    const { searchTerm } = this.state;

    onSearch(searchTerm);
  }, 300);

  state = { searchTerm: '' };

  onChange({ target }) {
    const { value } = target;

    this.setState({ searchTerm: value }, this.search);
  }

  resetSearch() {
    this.setState({ searchTerm: '' }, this.search);
  }

  render() {
    const { placeholder, autoFocus } = this.props;
    const { searchTerm } = this.state;

    return (
      <Wrapper>
        <SearchIcon hasTerm={!!searchTerm} onClick={() => this.resetSearch()}>
          {searchTerm && <Icon icon="close" size="18px" />}
          {searchTerm === '' && <Icon icon="search" size="22px" />}
        </SearchIcon>
        <Input
          value={searchTerm}
          hasTerm={!!searchTerm}
          type="search"
          placeholder={placeholder}
          name="search"
          onChange={(e) => this.onChange(e)}
          autoFocus={autoFocus}
        />
      </Wrapper>
    );
  }
}

Search.displayName = 'Search';

Search.defaultProps = {
  placeholder: 'Enter search',
  autoFocus: false
};

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool
};

export default Search;
