import styled from 'styled-components';
import PropTypes from 'prop-types';

const Label = styled.label`
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-size: 12px;
  font-weight: 500;
  display: block;
  line-height: 1.91;
  margin: 0 0 3px;

  &.sr-only {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

Label.label = Label;
Label.h1 = Label.withComponent('h1');
Label.h2 = Label.withComponent('h2');
Label.h3 = Label.withComponent('h3');
Label.h4 = Label.withComponent('h4');
Label.h5 = Label.withComponent('h5');
Label.h6 = Label.withComponent('h6');
Label.p = Label.withComponent('p');
Label.span = Label.withComponent('span');

Label.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string
};

Label.defaultProps = {
  color: '#333',
  align: 'left'
};

Label.displayName = 'Label';

export default Label;
