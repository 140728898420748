import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// helpers
import { shouldDisplayError, shouldDisplayWarning } from '../../helpers';

// components
import Label from '../../atoms/form/label';
import SubText from '../../atoms/form/subText';
import Select from '../../atoms/form/select';
import Warning from '../../atoms/typography/warning';
import Error from '../../atoms/typography/error';
import SrOnly from '../../atoms/typography/srOnly';
import Optional from '../../atoms/typography/optional';

// styled
const Wrapper = styled.label`
  display: block;
  width: 100%;
`;

// COMPONENT
class SelectField extends Component {
  constructor(props) {
    super(props);
    this.state = { touched: false };
  }

  shouldDisplayError() {
    const { forceTouch, showError, error } = this.props;
    const { touched } = this.state;

    return shouldDisplayError({ forceTouch, showError, error, touched });
  }

  shouldDisplayWarning() {
    const { forceTouch, warning, showWarning } = this.props;
    const { touched } = this.state;

    return shouldDisplayWarning({ forceTouch, warning, showWarning, touched });
  }

  // functions
  handleChange = (e) => {
    const { onChange } = this.props;

    this.setState(() => ({ touched: true }));

    onChange(e);
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;

    this.setState(() => ({ touched: true }));

    onBlur(e);
  };

  // Render
  renderLabel() {
    const { labelProps, hideLabel, label, name } = this.props;

    if (!hideLabel && label !== '' && label !== undefined) {
      return (
        <Label.span {...labelProps}>
          {label}
          {this.renderOptionalText()}
        </Label.span>
      );
    }
    return (
      <SrOnly>
        {name}
        {this.renderOptionalText()}
      </SrOnly>
    );
  }

  renderOptionalText() {
    const { optional } = this.props;
    if (optional) {
      return (
        <span className="label--optional">
          {' '}
          — <Optional>optional</Optional>
        </span>
      );
    }
    return null;
  }

  renderSubText() {
    const { hideLabel, subtext } = this.props;

    if (!hideLabel && subtext !== null) {
      return <SubText>{subtext}</SubText>;
    }
    return <SrOnly>{subtext}</SrOnly>;
  }

  renderOptions() {
    const { valueKey, labelKey, placeholder } = this.props;
    const options = [
      <option disabled key="disabled" value="">
        {placeholder || 'Please select'}
      </option>
    ];

    if (this.props.options.length) {
      this.props.options.map((option) => {
        options.push(
          <option value={option[valueKey]} key={option[valueKey]}>
            {option[labelKey]}
          </option>
        );
      });
    }
    return options;
  }

  renderOptionGroups() {
    const { optionGroups, valueKey, labelKey, placeholder } = this.props;
    const list = [
      <option disabled value="" key="disabled">
        {placeholder || 'Please select'}
      </option>
    ];

    optionGroups.forEach((optionGroup) => {
      const opt = optionGroup.options.map((option) => (
        <option value={option[valueKey]} key={option[valueKey]}>
          {option[labelKey]}
        </option>
      ));
      list.push(
        <optgroup key={optionGroup.label} label={optionGroup.label}>
          {opt}
        </optgroup>
      );
    });

    return list;
  }

  render() {
    const {
      name,
      value,
      error,
      disabled,
      optional,
      options,
      optionGroups,
      warning,
      selectProps,
      errorProps,
      warningProps
    } = this.props;

    const showError = this.shouldDisplayError();
    const showWarning = this.shouldDisplayWarning();

    return (
      <Wrapper htmlFor={name} className={`${showError ? 'error' : ''} ${showWarning ? 'warning' : ''}`}>
        {this.renderLabel()}
        {this.renderSubText()}
        <Select
          fullWidth
          ref={name}
          id={name}
          name={name}
          value={value}
          onChange={(e) => {
            this.handleChange(e);
          }}
          onBlur={(e) => {
            this.handleBlur(e);
          }}
          required={!optional}
          disabled={disabled}
          error={showError}
          {...selectProps}
        >
          {options.length && this.renderOptions()}
          {optionGroups.length && this.renderOptionGroups()}
        </Select>
        {showError && error && (
          <Error styled={{ display: 'block' }} margin="10px 0 0" {...errorProps}>
            {error}
          </Error>
        )}
        {showWarning && warning && (
          <Warning styled={{ display: 'block' }} margin="10px 0 0" {...warningProps}>
            {warning}
          </Warning>
        )}
      </Wrapper>
    );
  }
}

SelectField.propTypes = {
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  optionGroups: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  subtext: PropTypes.string,
  optional: PropTypes.bool,
  showError: PropTypes.bool,
  hideLabel: PropTypes.bool,
  warning: PropTypes.string,
  forceTouch: PropTypes.bool,
  showWarning: PropTypes.bool,
  labelProps: PropTypes.object,
  selectProps: PropTypes.object,
  errorProps: PropTypes.object,
  warningProps: PropTypes.object
};

SelectField.defaultProps = {
  subtext: null,
  label: '',
  value: '',
  options: [],
  optionGroups: [],
  labelKey: 'label',
  valueKey: 'value',
  forceTouch: false,
  showError: true,
  error: null,
  disabled: false,
  optional: false,
  hideLabel: false,
  showWarning: true,
  warning: null,
  labelProps: {},
  selectProps: {},
  errorProps: {},
  warningProps: {}
};

SelectField.displayName = 'SelectField';

export default SelectField;
