import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// helper
import { sanitize, shouldDisplayError, shouldDisplayWarning } from '../helpers';

// local
import Row from '../row';
import Label from '../label';
import SubText from '../subText';
import Input from '../input';
import Warning from '../warning';
import Error from '../error';
import SrOnly from '../srOnly';
import Optional from '../optional';

// styled
const Wrapper = styled.label`
  display: block;
  width: 100%;
`;

class InputField extends Component {
  state = { touched: false };

  // functions
  handleChange = (e) => {
    const { onChange } = this.props;

    this.setState(() => ({ touched: true }));

    onChange(e, sanitize(e.target.value));
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;

    this.setState(() => ({ touched: true }));

    onBlur(e, sanitize(e.target.value));
  };

  shouldDisplayError() {
    const { forceTouch, showError, error } = this.props;
    const { touched } = this.state;

    return shouldDisplayError({ forceTouch, showError, error, touched });
  }

  shouldDisplayWarning() {
    const { forceTouch, warning, showWarning } = this.props;
    const { touched } = this.state;

    return shouldDisplayWarning({ forceTouch, warning, showWarning, touched });
  }

  // render
  renderOptionalText() {
    const { optionalLabel, optional } = this.props;
    if (optional) {
      return (
        <span>
          {' '}
          — <Optional>{optionalLabel}</Optional>
        </span>
      );
    }
    return null;
  }

  renderLabel() {
    const { labelProps, hideLabel, label, name } = this.props;

    if (!hideLabel && label !== '' && label !== undefined) {
      return (
        <Label as="span" {...labelProps}>
          {label}
          {this.renderOptionalText()}
        </Label>
      );
    }
    return (
      <SrOnly>
        {name}
        {this.renderOptionalText()}
      </SrOnly>
    );
  }

  renderSubText() {
    const { hideLabel, subtext } = this.props;

    if (subtext === '') return null;

    if (!hideLabel && subtext !== null) {
      return <SubText>{subtext}</SubText>;
    }
    return <SrOnly>{subtext}</SrOnly>;
  }

  renderPrefix(showError, showWarning) {
    const { prefix, error, warning } = this.props;
    const { touched } = this.state;

    if (typeof prefix === 'function') {
      return prefix({ showError, showWarning, error, warning, touched });
    }

    if (prefix !== null) {
      return prefix;
    }

    return null;
  }

  renderSuffix(showError, showWarning) {
    const { suffix, error, warning } = this.props;
    const { touched } = this.state;

    if (typeof suffix === 'function') {
      return suffix({ showError, showWarning, error, warning, touched });
    }

    if (suffix !== null) {
      return suffix;
    }

    return null;
  }

  renderInput() {
    const { name, value, type, placeholder, optional, disabled, min, max, inputProps } = this.props;

    const showError = this.shouldDisplayError();

    // Turn off autocomplete for password fields
    // (PCI compliance requirement)
    if (type === 'password') {
      inputProps.autoComplete = 'off';
    }

    return (
      <Input
        ref={name}
        id={name}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={(e) => {
          this.handleChange(e);
        }}
        onBlur={(e) => {
          this.handleBlur(e);
        }}
        required={!optional}
        disabled={disabled}
        min={min}
        max={max}
        maxLength={typeof max === 'string' ? Number(max) : max}
        error={showError}
        fullWidth
        {...inputProps}
      />
    );
  }

  render() {
    const { name, error, warning, errorProps, warningProps, rowProps } = this.props;

    const showError = this.shouldDisplayError();
    const showWarning = this.shouldDisplayWarning();

    const props = { flexWrap: 'nowrap', alignItems: 'stretch', ...rowProps };

    return (
      <Wrapper htmlFor={name} className={`${showError ? 'invalid' : ''} ${showWarning ? 'warning' : ''}`}>
        {this.renderLabel()}
        {this.renderSubText()}
        <Row {...props}>
          {this.renderPrefix(showError, showWarning)}
          {this.renderInput()}
          {this.renderSuffix(showError, showWarning)}
        </Row>
        {showError && error && (
          <Error margin="10px 0 0" {...errorProps}>
            {error}
          </Error>
        )}
        {showWarning && warning && (
          <Warning margin="10px 0 0" {...warningProps}>
            {warning}
          </Warning>
        )}
      </Wrapper>
    );
  }
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['date', 'email', 'number', 'text', 'tel', 'time', 'url', 'password', 'hidden']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.any,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  warning: PropTypes.string,
  showWarning: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  optional: PropTypes.bool,
  subtext: PropTypes.string,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  forceTouch: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  suffix: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  optionalLabel: PropTypes.string,
  hideLabel: PropTypes.bool,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  errorProps: PropTypes.object,
  rowProps: PropTypes.object,
  warningProps: PropTypes.object,
  iconBefore: PropTypes.func,
  iconAfter: PropTypes.func
};

InputField.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  warning: null,
  subtext: null,
  error: null,
  forceTouch: false,
  showWarning: true,
  onChange: () => null,
  onBlur: () => null,
  showError: true,
  optional: false,
  disabled: false,
  min: null,
  max: null,
  prefix: null,
  suffix: null,
  optionalLabel: 'Optional',
  hideLabel: false,
  labelProps: {},
  inputProps: {},
  errorProps: {},
  warningProps: {},
  rowProps: {},
  iconBefore: () => null,
  iconAfter: () => null
};

InputField.displayName = 'InputField';

export default InputField;
