export const themes = {
  default: {
    background: '#000',
    borderColor: '#000',
    color: '#fff'
  },
  'default-solid': {
    background: '#000',
    borderColor: '#000',
    color: '#fff',
    hover: {
      background: '#666',
      borderColor: '#666',
      color: '#fff'
    },
    active: {
      background: '#999',
      borderColor: '#999',
      color: '#fff'
    },
    disabled: {
      background: '#bfbfbf',
      borderColor: '#bfbfbf',
      color: '#fff'
    }
  },
  'default-clear': {
    background: 'transparent',
    borderColor: '#000',
    color: '#000',
    hover: {
      background: 'transparent',
      borderColor: '#666',
      color: '#666'
    },
    active: {
      background: 'transparent',
      borderColor: '#999',
      color: '#999'
    },
    disabled: {
      background: 'transparent',
      borderColor: '#bfbfbf',
      color: '#bfbfbf'
    }
  },
  'default-floating': {
    background: 'transparent',
    borderColor: 'transparent',
    color: '#000',
    hover: {
      background: 'transparent',
      borderColor: 'transparent',
      color: '#000'
    },
    active: {
      background: 'transparent',
      borderColor: 'transparent',
      color: '#000'
    },
    disabled: {
      background: 'transparent',
      borderColor: 'transparent',
      color: '#000'
    }
  },
  inverse: {
    background: '#fff',
    borderColor: '#fff',
    color: '#000'
  },
  'inverse-solid': {
    background: '#fff',
    borderColor: '#fff',
    color: '#000',
    hover: {
      background: '#bfbfbf',
      borderColor: '#bfbfbf',
      color: '#000'
    },
    active: {
      background: '#999',
      borderColor: '#999',
      color: '#000'
    },
    disabled: {
      background: '#666',
      borderColor: '#666',
      color: '#000'
    }
  },
  'inverse-clear': {
    background: 'transparent',
    borderColor: '#fff',
    color: '#fff',
    hover: {
      background: 'transparent',
      borderColor: '#999',
      color: '#999'
    },
    active: {
      background: 'transparent',
      borderColor: '#666',
      color: '#666'
    },
    disabled: {
      background: 'transparent',
      borderColor: '#404040',
      color: '#404040'
    }
  },
  'inverse-floating': {
    background: 'transparent',
    borderColor: 'transparent',
    color: '#fff',
    hover: {
      background: 'transparent',
      borderColor: 'transparent',
      color: '#fff'
    },
    active: {
      background: 'transparent',
      borderColor: 'transparent',
      color: '#fff'
    },
    disabled: {
      background: 'transparent',
      borderColor: 'transparent',
      color: '#fff'
    }
  },
  error: {
    background: '#d0021b',
    borderColor: '#d0021b',
    color: '#fff',
    hover: {
      background: '#E36877',
      borderColor: '#E36877',
      color: '#fff'
    },
    active: {
      background: '#ED9AA4',
      borderColor: '#ED9AA4',
      color: '#fff'
    },
    disabled: {
      background: '#F4C0C6',
      borderColor: '#F4C0C6',
      color: '#fff'
    }
  },
  warning: {
    background: '#ff9800',
    borderColor: '#ff9800',
    color: '#fff',
    hover: {
      background: '#FFC266',
      borderColor: '#FFC266',
      color: '#fff'
    },
    active: {
      background: '#FFD699',
      borderColor: '#FFD699',
      color: '#fff'
    },
    disabled: {
      background: '#FFE6BF',
      borderColor: '#FFE6BF',
      color: '#fff'
    }
  },
  success: {
    background: '#00a44c',
    borderColor: '#00a44c',
    color: '#fff',
    hover: {
      background: '#66C994',
      borderColor: '#66C994',
      color: '#fff'
    },
    active: {
      background: '#99DBB8',
      borderColor: '#99DBB8',
      color: '#fff'
    },
    disabled: {
      background: '#BFE9D3',
      borderColor: '#BFE9D3',
      color: '#fff'
    }
  }
};
