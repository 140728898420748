import PropTypes from 'prop-types';
import styled from 'styled-components';

const themes = {
  default: '#fff',
  error: '#d0021b',
  warning: '#ff9800',
  success: '#00a44c',
  inactive: '#8F8F8F'
};

const Card = styled.div`
  position: relative;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);

  /* prop styling */
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  /* status */
  ${(props) => {
    let borders = '';
    if (props.borderTop) borders += `border-top: 3px solid ${themes[props.theme]};`;
    if (props.borderRight) borders += `border-right: 3px solid ${themes[props.theme]};`;
    if (props.borderBottom) borders += `border-bottom: 3px solid ${themes[props.theme]};`;
    if (props.borderLeft) borders += `border-left: 3px solid ${themes[props.theme]};`;
    return borders;
  }}

  /* sizing */
  height: 100%;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight};

  /* layout */
  display: flex;
  flex-direction: column;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-basis: ${({ flexBasis }) => flexBasis};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

// Default props
Card.defaultProps = {
  background: '#fff',
  padding: '0',
  margin: '0',
  theme: 'default',
  borderTop: true,
  borderRight: false,
  borderBottom: false,
  borderLeft: false,
  minHeight: '50px',
  flexGrow: '1',
  flexShrink: '0',
  flexBasis: '0%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
};

// Prop type check
Card.propTypes = {
  /** background setter. Can be anything */
  background: PropTypes.string,
  /** single value padding. e.g "10px" or "10px 30px" */
  padding: PropTypes.string,
  /** single value margin. e.g "10px" or "10px 30px" */
  margin: PropTypes.string,
  /** defines the color of any borders */
  theme: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'inactive']),
  /** show or hide a top border */
  borderTop: PropTypes.bool,
  /** show or hide a right hand side border */
  borderRight: PropTypes.bool,
  /** show or hide a bottom border */
  borderBottom: PropTypes.bool,
  /** show or hide a left hand side border */
  borderLeft: PropTypes.bool,
  /** force a minimum height */
  minHeight: PropTypes.string,
  /** tweak flex-grou property. Renders as a stand-alone value. */
  flexGrow: PropTypes.string,
  /** tweak flex-shrink property. Renders as a stand-alone value. */
  flexShrink: PropTypes.string,
  /** tweak flex-basis property. Renders as a stand-alone value. */
  flexBasis: PropTypes.string,
  /** tweak flex-direction property. Renders as a stand-alone value. */
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  /** Vertically align inner items */
  alignItems: PropTypes.oneOf(['normal', 'stretch', 'flex-start', 'center', 'flex-end']),
  /** Horizontally align inner items. Options: 'space-between', 'center', 'flex-start', 'flex-end' */
  justifyContent: PropTypes.oneOf([
    'normal',
    'space-evenly',
    'stretch',
    'space-around',
    'space-between',
    'center',
    'flex-start',
    'flex-end'
  ])
};

Card.displayName = 'Card';

// export
export default Card;

// ========================== //
// CODE TO BE USED IN STORIES //
// ========================== //
export const StoryCard = props => <Card { ...props } />; // eslint-disable-line
// default props for element
StoryCard.defaultProps = {
  background: '#fff',
  padding: '0',
  margin: '0',
  theme: 'default',
  borderTop: true,
  borderRight: false,
  borderBottom: false,
  borderLeft: false,
  minHeight: '50px',
  flexGrow: '1',
  flexShrink: '0',
  flexBasis: '0%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
};
StoryCard.propTypes = {
  /** background setter. Can be anything */
  background: PropTypes.string,
  /** single value padding. e.g "10px" or "10px 30px" */
  padding: PropTypes.string,
  /** single value margin. e.g "10px" or "10px 30px" */
  margin: PropTypes.string,
  /** defines the color of any borders */
  theme: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'inactive']),
  /** show or hide a top border */
  borderTop: PropTypes.bool,
  /** show or hide a right hand side border */
  borderRight: PropTypes.bool,
  /** show or hide a bottom border */
  borderBottom: PropTypes.bool,
  /** show or hide a left hand side border */
  borderLeft: PropTypes.bool,
  /** force a minimum height */
  minHeight: PropTypes.string,
  /** tweak flex-grou property. Renders as a stand-alone value. */
  flexGrow: PropTypes.string,
  /** tweak flex-shrink property. Renders as a stand-alone value. */
  flexShrink: PropTypes.string,
  /** tweak flex-basis property. Renders as a stand-alone value. */
  flexBasis: PropTypes.string,
  /** tweak flex-direction property. Renders as a stand-alone value. */
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  /** Vertically align inner items */
  alignItems: PropTypes.oneOf(['normal', 'stretch', 'flex-start', 'center', 'flex-end']),
  /** Horizontally align inner items. Options: 'space-between', 'center', 'flex-start', 'flex-end' */
  justifyContent: PropTypes.oneOf([
    'normal',
    'space-evenly',
    'stretch',
    'space-around',
    'space-between',
    'center',
    'flex-start',
    'flex-end'
  ])
};
StoryCard.displayName = 'Card';
