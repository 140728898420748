/**
 * REMOVE A DOM ELEMENT (SUPPORTS IE11)
 * @param element {DOM node}
 * @returns DOM node if successful, false if unsuccessful
 */

export function removeElement(element) {
  !!element && !!element.parentNode && element.parentNode.removeChild(element);
}

/**
 * CHECK IF IS BROWSER OR SERVER
 * @returns Boolean. false if server, true if browser
 */
export const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
