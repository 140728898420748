import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Optional from '../../atoms/typography/optional';

import Label from '../../atoms/form/label';
import Subtext from '../../atoms/form/subText';
import Error from '../../atoms/typography/error';

// STYLES
import { Wrapper, Element, Test } from './styles';

// COMPONENT
class Selector extends Component {
  constructor(props) {
    super(props);
    this.testWidthRef = React.createRef();
    this.state = {
      width: 'auto',
      selected: ''
    };
  }

  componentDidMount() {
    // const selected = this.textInput[this.textInput.selectedIndex].text;
    // this.setState({ selected }, () => this.resizeSelect());
  }

  componentDidUpdate(prevProps, prevState) {
    // if anything changes resize...
    const selectedChange = prevState.selected !== this.state.selected;
    const classesChange = prevProps.classes !== this.props.classes;
    const placeholderChange = prevProps.placeholder !== this.props.placeholder;
    const optionsChange = JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options);
    const optionGroupsChange = JSON.stringify(prevProps.optionGroups) !== JSON.stringify(this.props.optionGroups);
    const stylesChange = JSON.stringify(prevProps.styles) !== JSON.stringify(this.props.styles);

    if (selectedChange || placeholderChange || classesChange || optionsChange || optionGroupsChange || stylesChange) {
      this.resizeSelect();
    }
  }

  shouldDisplayError() {
    const { touched, error, showError } = this.props;

    return touched === true && error !== undefined && error !== '' && error !== null && showError;
  }

  resizeSelect() {
    /*
      The idea here seems to be to copy the width of the selected input text to the
      dropdown element. Previously it was done with 'innerRef' which is weird and old,
      so now it's using element refs instead.
    */
    this.setState({ width: this.testWidthRef.current.offsetWidth });
  }

  handleChange = (e) => {
    this.setState({
      selected: e.target[e.target.selectedIndex].text
    });

    // External hook event
    this.props.onChange(e);
  };

  handleBlur = (e) => {
    this.setState({
      selected: e.target[e.target.selectedIndex].text
    });

    // External hook event
    if (this.props.onBlur !== null && typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  };

  renderLabel() {
    const { hideLabel, label, name } = this.props;

    if (label !== '' && label !== undefined) {
      return (
        <Label
          htmlFor={name}
          className={`form__select-label form__select-label-${name} ${hideLabel ? 'sr-only' : ''} `}
        >
          {label}
          {this.renderOptionalText()}
        </Label>
      );
    }
    return (
      <Label htmlFor={name} className={`form__select-label form__select-label-${name} sr-only`}>
        {name}
        {this.renderOptionalText()}
      </Label>
    );
  }

  renderOptions() {
    const { placeholder, valueKey, labelKey } = this.props;
    const options = [];

    if (placeholder !== '') {
      options.push(
        <option disabled key="disabled" value="">
          {placeholder}
        </option>
      );
    }

    if (this.props.options.length) {
      this.props.options.map((option) => {
        options.push(
          <option value={option[valueKey]} key={option[valueKey]}>
            {option[labelKey]}
          </option>
        );
      });
    }

    return options;
  }

  renderOptionGroups() {
    const { placeholder, optionGroups, valueKey, labelKey } = this.props;
    const list = [
      <option disabled value="" key="disabled">
        {placeholder}
      </option>
    ];

    optionGroups.forEach((optionGroup) => {
      const opt = optionGroup.options.map((option) => (
        <option value={option[valueKey]} key={option[valueKey]}>
          {option[labelKey]}
        </option>
      ));
      list.push(
        <optgroup key={optionGroup.label} label={optionGroup.label}>
          {opt}
        </optgroup>
      );
    });

    return list;
  }

  renderOptionalText() {
    const { optional } = this.props;
    if (optional) {
      return (
        <span className="label--optional">
          {' '}
          — <Optional>optional</Optional>
        </span>
      );
    }
    return null;
  }

  renderSubtext() {
    const { name, subtext, hideLabel } = this.props;

    if (subtext !== null) {
      return (
        <Subtext className={`form__select-subtext form__select-subtext-${name} ${hideLabel ? 'sr-only' : ''} `}>
          {subtext}
        </Subtext>
      );
    }
    return null;
  }

  render() {
    const {
      onChange,
      onBlur,
      name,
      value,
      error,
      isSubmitting,
      disabled,
      optional,
      classes,
      options,
      optionGroups,
      spacing,
      styles
    } = this.props;
    const showError = this.shouldDisplayError();

    return (
      <Wrapper spacing={spacing} className={`form__selector-wrapper${showError ? ' invalid' : ''}`}>
        {this.renderLabel()}
        {this.renderSubtext()}
        <Element
          ref={name}
          id={name}
          className={`form__selector ${classes}`}
          name={name}
          value={value}
          onChange={this.handleChange}
          style={{ ...styles, width: this.state.width }}
          onBlur={this.handleBlur}
          required={!optional}
          disabled={isSubmitting || disabled}
          isError={showError}
          innerRef={(input) => {
            this.textInput = input;
          }}
        >
          {options.length && this.renderOptions()}
          {optionGroups.length && this.renderOptionGroups()}
        </Element>
        <Test ref={this.testWidthRef} style={styles}>
          {this.state.selected}
        </Test>
        {showError && (
          <Error padding="5px 0 0" className="form__error">
            {error}
          </Error>
        )}
      </Wrapper>
    );
  }
}

Selector.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  styles: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  optionGroups: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  subtext: PropTypes.string,
  optional: PropTypes.bool,
  showError: PropTypes.bool,
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  spacing: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
  placeholder: PropTypes.string
};

Selector.defaultProps = {
  styles: {},
  subtext: null,
  label: '',
  value: '',
  optionGroups: [],
  labelKey: 'label',
  valueKey: 'value',
  showError: true,
  error: null,
  touched: false,
  isSubmitting: false,
  disabled: false,
  optional: false,
  classes: '',
  hideLabel: false,
  spacing: 'none',
  placeholder: 'Please select',
  onBlur: null
};

Selector.displayName = 'Selector';

export default Selector;
