import { css } from 'styled-components';

import fluidType from '../fluidType';
import { fontFamily, wordBreak, srOnly } from '../helpers/css';

const isString = (prop, fallback) => {
  if (typeof prop === 'string') return prop;
  return fallback;
};

const isBool = (prop, fallback) => {
  if (typeof prop === 'boolean') return prop;
  return fallback;
};

const isNum = (prop, fallback) => {
  if (typeof prop === 'number') return prop;
  return fallback;
};

const base = css`
  display: block;
  width: 100%;

  letter-spacing: ${({ textSpacing }) => isString(textSpacing, 'normal')};
  text-transform: ${({ textTransform }) => isString(textTransform, 'none')};
  color: ${({ color }) => isString(color, '#333')};

  font-weight: ${({ textWeight }) => isString(textWeight, 400)};
  text-align: ${({ align }) => isString(align, 'left')};
  margin: ${({ margin }) => isString(margin, '0')};
  padding: ${({ padding }) => isString(padding, '0')};

  ${({ fonts }) => fontFamily(isString(fonts, 'inter'))};

  ${(props) => {
    const textSize = isString(props.textSize, '');
    const lineHeight = isString(props.lineHeight, '');
    const minFont = isNum(props.minFont, 10);
    const maxFont = isNum(props.maxFont, 20);
    const minLine = isNum(props.minLine, 10);
    const maxLine = isNum(props.maxLine, 20);

    if (textSize === '' && lineHeight === '') {
      return `
        ${fluidType({ fontSize: [minFont, maxFont], lineHeight: [minLine, maxLine] })}
      `;
    }

    return `
      font-size: ${textSize};
      line-height: ${lineHeight};
    `;
  }}

  ${({ hide }) => (isBool(hide, false) ? srOnly : '')};
  ${({ wordWrap }) => (isBool(wordWrap, false) ? wordBreak : '')};
`;

export default base;
