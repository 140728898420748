import React, { Component } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';

// Override default styles
import { Wrapper } from './styles';

export default class DateTimePicker extends Component {
  static constants;

  constructor(props) {
    super(props);

    this.state = { init: false };

    this.typesMap = {
      range: 'DateRangePicker',
      single: 'SingleDatePicker',
      rangeController: 'DayPickerRangeController',
      singleController: 'DayPickerSingleDateController'
    };

    import(
      /* webpackChunkName: "react-dates" */
      /* webpackMode: "lazy" */
      'react-dates'
    ).then((reactDates) => {
      this.componentType = reactDates[this.typesMap[props.type]];
      import(
        /* webpackChunkName: "react-dates/initialize" */
        /* webpackMode: "lazy" */
        'react-dates/initialize'
      ).then(() => {
        import(
          /* webpackChunkName: "react-dates/constants" */
          /* webpackMode: "lazy" */
          'react-dates/constants'
        ).then((constants) => {
          this.constructor.constants = constants;
          this.setState({ init: true });
        });
      });
    });

    this.datePickerProps = omit(props, ['type']);

    switch (props.type) {
      case 'rangeController':
      case 'range':
        this.numberOfMonths = 2;
        break;
      default:
        this.numberOfMonths = 1;
    }
  }

  componentWillReceiveProps(nextProps) {
    this.datePickerProps = omit(nextProps, ['type']);
  }

  render() {
    const { numberOfMonths } = this.props;
    const { init } = this.state;
    if (!init) {
      return null;
    }
    return (
      <Wrapper>
        <this.componentType
          hideKeyboardShortcutsPanel
          navPrev={<Icon icon="long-arrow-left" size="22" />}
          navNext={<Icon icon="long-arrow-right" size="22" />}
          monthFormat="MMM YYYY"
          weekDayFormat="dd"
          numberOfMonths={numberOfMonths || this.numberOfMonths}
          {...this.datePickerProps}
        />
      </Wrapper>
    );
  }
}

DateTimePicker.defaultProps = {
  type: 'single',
  numberOfMonths: 1
};

DateTimePicker.propTypes = {
  type: PropTypes.oneOf(['single', 'range', 'rangeController', 'singleController']),
  numberOfMonths: PropTypes.number
};

DateTimePicker.displayName = 'DateTimePicker';
