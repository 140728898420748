import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';
import { RemoveScroll } from 'react-remove-scroll';

import Title from '../title';

import { Container, Backdrop, Wrapper, Header, Footer, Body, Close } from './modal.styles';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false };
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.
    this.modalWrapper = React.createRef();
    this.Container = React.createRef();
    this.modalRoot = props.root || document.createElement('div');
  }

  UNSAFE_componentWillMount() {
    this.modalRoot.className = 'modal-container';
    document.body.appendChild(this.modalRoot);
  }

  componentDidMount() {
    this.setState(() => ({ mounted: true }));

    // focus container so we can force focus within modal
    this.Container.current.focus();
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    this.modalRoot.parentNode.removeChild(this.modalRoot);
  }

  // Functions
  handleKeyDown = (e) => {
    const { closeOnEsc, onClose } = this.props;

    const focusableEls = Array.prototype.slice.call(
      this.modalWrapper.current.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
      )
    );
    const KEY_TAB = 9;
    const KEY_ESCAPE = 27;

    switch (e.keyCode) {
      case KEY_TAB:
        if (e.shiftKey) {
          this.handleBackwardTab(e, focusableEls);
        } else {
          this.handleForwardTab(e, focusableEls);
        }

        break;
      case KEY_ESCAPE:
        if (closeOnEsc !== false) {
          onClose();
        }

        break;
      default:
        break;
    }
  };

  handleBackwardTab(e, focusableEls) {
    const firstElement = focusableEls[0];
    const lastElement = focusableEls[focusableEls.length - 1];

    if (document.activeElement === firstElement) {
      e.preventDefault();
      lastElement.focus();
    }
    if (document.activeElement === this.Container.current) {
      e.preventDefault();
      lastElement.focus();
    }
  }

  handleForwardTab(e, focusableEls) {
    const firstElement = focusableEls[0];
    const lastElement = focusableEls[focusableEls.length - 1];

    if (document.activeElement === lastElement) {
      e.preventDefault();
      firstElement.focus();
    } else if (document.activeElement === this.Container.current) {
      e.preventDefault();
      firstElement.focus();
    }
  }

  handleClose(e) {
    const { onClose } = this.props;
    e.preventDefault();

    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  }

  // Modal constructor
  constructModal() {
    // Build up the header, body, footer of the modal.
    const { lockScroll, closeOnBackdrop, transparent, outerPadding } = this.props;

    const content = (
      <Container
        role="dialog"
        tabIndex={-1}
        ref={this.Container}
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
        outerPadding={outerPadding}
      >
        {this.renderContent()}
        <Backdrop transparent={transparent} onClick={(e) => closeOnBackdrop !== false && this.handleClose(e)} />
      </Container>
    );

    if (lockScroll) {
      return <RemoveScroll>{content}</RemoveScroll>;
    }

    return content;
  }

  // Render functions
  renderHeader() {
    const { stickyHeader, header, hideClose } = this.props;

    if (header !== '') {
      return (
        <Header sticky={stickyHeader}>
          <Title>{header}</Title>
          {!hideClose && (
            <Close theme="inverse" onClick={(e) => this.handleClose(e)} header={header}>
              <Icon size="14px" icon="close" />
            </Close>
          )}
        </Header>
      );
    }
    if (!hideClose) {
      return (
        <Close theme="inverse" onClick={(e) => this.handleClose(e)}>
          <Icon size="14px" icon="close" />
        </Close>
      );
    }
    return null;
  }

  renderChildren = () => {
    const { children } = this.props;
    const { mounted } = this.state;

    if (mounted) {
      const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { modal: this.modalWrapper });
        }
        return child;
      });
      return childrenWithProps;
    }
    return null;
  };

  renderContent() {
    const { render, mobileFullscreen, padding, height, width, forceHeight, bodyBackground } = this.props;
    const { mounted } = this.state;

    const renderWithProps = render && typeof render === 'function';

    return (
      <Wrapper
        className="modal-wrapper"
        ref={this.modalWrapper}
        forceHeight={forceHeight}
        setHeight={height}
        setWidth={width}
        mobileFullscreen={mobileFullscreen}
        padding={padding}
        bodyBackground={bodyBackground}
      >
        {this.renderHeader()}
        <Body padding={padding}>
          {renderWithProps && mounted && render(this.modalWrapper, this.modalRoot)}
          {!renderWithProps && mounted && this.renderChildren()}
        </Body>
        {this.renderFooter()}
      </Wrapper>
    );
  }

  renderFooter() {
    const { footer, stickyFooter } = this.props;

    if (typeof footer === 'string') {
      return (
        <Footer sticky={stickyFooter}>
          <Title>{footer}</Title>
        </Footer>
      );
    }
    if (footer && typeof footer === 'object') {
      return <Footer sticky={stickyFooter}>{footer}</Footer>;
    }
    return null;
  }

  render() {
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(this.constructModal(), this.modalRoot);
  }
}

Modal.displayName = 'Modal';

Modal.defaultProps = {
  // Choose render style
  render: null,
  children: '',
  // define preset width/height options
  height: '100%',
  width: '580px',
  mobileFullscreen: '580px',
  // define styling of backdrop
  transparent: 0.9,
  // define built in padding to header, footer and body
  padding: true,
  outerPadding: true,
  // manage closing options
  hideClose: false,
  closeOnBackdrop: true,
  closeOnEsc: true,
  // Controlling header and footer renders
  header: '',
  stickyHeader: true,
  footer: null,
  stickyFooter: false,
  root: null,
  forceHeight: false,
  bodyBackground: '#fff',
  lockScroll: true
};

Modal.propTypes = {
  /**
   * pass any child component or text
   */
  children: PropTypes.any,
  /**
   * size of modal window
   */
  height: PropTypes.string,
  /**
   * size of modal window
   */
  width: PropTypes.string,
  /**
   * function run when close is ran
   */
  onClose: PropTypes.func.isRequired,
  /**
   * function run when user clicks background
   */
  closeOnBackdrop: PropTypes.bool,
  /**
   * function run when user presses the esc key
   */
  closeOnEsc: PropTypes.bool,
  /**
   * sting to pass into header block
   */
  header: PropTypes.string,
  /**
   * string to pass into footer block
   */
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * change opacity of black overlay
   */
  transparent: PropTypes.number,
  /**
   * Enable or disabled default padding
   */
  padding: PropTypes.bool,
  /**
   * Enable or disabled default outerPadding
   */
  outerPadding: PropTypes.bool,
  /**
   * Show or hide close button
   */
  hideClose: PropTypes.bool,
  /**
   * Mobile full screen
   */
  mobileFullscreen: PropTypes.string,
  /**
   * Render prop allow to use component as a render with props
   */
  render: PropTypes.func,
  /**
   * makes header stick when content is scrollable
   */
  stickyHeader: PropTypes.bool,
  /**
   * makes footer stick when content is scrollable
   */
  stickyFooter: PropTypes.bool,
  /**
   * element to append modal to
   */
  root: PropTypes.element,
  forceHeight: PropTypes.bool,
  bodyBackground: PropTypes.string,
  lockScroll: PropTypes.bool
};
