import styled from 'styled-components';
import PropTypes from 'prop-types';
import FluidType from '../../../helpers/fluidType';
import FontFamily from '../../../helpers/fontFamily';

const P = styled.p`
  font-weight: 400;
  width: 100%;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  ${({ fonts }) => FontFamily(fonts)};

  ${({ fontSize, lineHeight, minFont, maxFont, minLine, maxLine }) => {
    if (fontSize === '' && lineHeight === '') {
      return `${FluidType({ fontSize: [minFont, maxFont], lineHeight: [minLine, maxLine] })}`;
    }
    return `
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `;
  }}

  em,
  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  a {
    font-size: 1em;
    color: currentColor;
    transition: color 0.3s;
    font-weight: 600;

    &:hover {
      transition: color 0.3s;
      color: #8f8f8f;
    }
  }
  pre {
    font-family: 'Courier 10 Pitch', Courier, monospace;
    font-size: 95%;
    line-height: 140%;
    white-space: pre;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
  }

  code {
    font-family: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
    font-size: 95%;
    line-height: 140%;
    white-space: pre;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    background: #faf8f0;
  }
`;

P.p = P;
P.h1 = P.withComponent('h1');
P.h2 = P.withComponent('h2');
P.h3 = P.withComponent('h3');
P.h4 = P.withComponent('h4');
P.h5 = P.withComponent('h5');
P.h6 = P.withComponent('h6');
P.li = P.withComponent('li');
P.span = P.withComponent('span');
P.label = P.withComponent('label');

P.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  minFont: PropTypes.number,
  minLine: PropTypes.number,
  maxFont: PropTypes.number,
  maxLine: PropTypes.number,
  fonts: PropTypes.oneOfType([PropTypes.oneOf(['inter', 'LushHandwritten']), PropTypes.string])
};

P.defaultProps = {
  color: '#333',
  align: 'left',
  fontSize: '',
  lineHeight: '',
  margin: '0',
  padding: '0',
  minFont: 14,
  maxFont: 17,
  minLine: 17,
  maxLine: 29,
  fonts: 'inter'
};

P.displayName = 'P';

export default P;
