import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import renderGraphic from './graphics';

class SVG extends React.Component {
  render() {
    const { icon, styles, title, className, fill, size, xSize, ySize, srHidden } = this.props;

    return renderGraphic({
      icon,
      fill,
      callback: ({ graphic, viewBox, ratio }) => {
        const id = title !== null ? title.replace(/[^\w\s]/gi, '').replace(/\s/g, '-') : null;
        let width = xSize || size;
        let height = ySize || size;

        if (ySize && !xSize) {
          width = parseInt(height, 10) * ratio;
        }

        if (xSize && !ySize) {
          height = parseInt(width, 10) / ratio;
        }

        return (
          <svg
            style={styles}
            className={`${icon} ${className}`}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            {...(id !== null ? { 'aria-labelledby': id } : { 'aria-hidden': srHidden })}
          >
            {id !== null && <title id={id}>{title}</title>}
            <g transform="translate(0, 0)">{graphic}</g>
          </svg>
        );
      }
    });
  }
}

SVG.defaultProps = {
  fill: 'currentColor',
  size: '16px',
  ySize: null,
  xSize: null,
  styles: {},
  title: null,
  srHidden: true,
  icon: ''
};

SVG.propTypes = {
  icon: PropTypes.string,
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  size: PropTypes.string,
  ySize: PropTypes.string,
  xSize: PropTypes.string,
  styles: PropTypes.object,
  title: PropTypes.string,
  srHidden: PropTypes.bool
};

const Icon = styled(SVG)`
  pointer-events: none;
`;

export default Icon;
