import PropTypes from 'prop-types';
import styled from 'styled-components';

const themes = {
  default: '#fff',
  error: '#d0021b',
  warning: '#ff9800',
  success: '#00a44c',
  inactive: '#8F8F8F'
};

const Card = styled.div`
  position: relative;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);

  /* prop styling */
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  /* status */
  ${(props) => {
    let borders = '';
    if (props.borderTop) borders += `border-top: 3px solid ${themes[props.theme]};`;
    if (props.borderRight) borders += `border-right: 3px solid ${themes[props.theme]};`;
    if (props.borderBottom) borders += `border-bottom: 3px solid ${themes[props.theme]};`;
    if (props.borderLeft) borders += `border-left: 3px solid ${themes[props.theme]};`;
    return borders;
  }};

  /* sizing */
  height: 100%;
  width: 100%;

  /* layout */
  display: flex;
  flex-direction: column;
`;

// Default props
Card.defaultProps = {
  background: '#fff',
  padding: '0',
  margin: '0',
  theme: 'default',
  borderTop: true,
  borderRight: false,
  borderBottom: false,
  borderLeft: false
};

// Prop type check
Card.propTypes = {
  background: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'inactive']),
  borderTop: PropTypes.bool,
  borderRight: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderLeft: PropTypes.bool
};

Card.displayName = 'Card';

// export
export default Card;
