import styled from 'styled-components';

const SrOnly = styled.span`
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

SrOnly.span = SrOnly.withComponent('span');
SrOnly.p = SrOnly.withComponent('p');

SrOnly.displayName = 'SrOnly';

export default SrOnly;
