export function sizeMixin(size, columns, flexBasis = '0%') {
  return `
    ${
      typeof size === 'string'
        ? `
      width: ${size};
      flex-basis: ${flexBasis !== '0%' ? flexBasis : size};
      max-width: ${size};
    `
        : ''
    }


    ${
      typeof size === 'number'
        ? `
      width: ${(size / columns) * 100}%;
      flex-basis: ${flexBasis !== '0%' ? flexBasis : `${(size / columns) * 100}%`};
      max-width: ${(size / columns) * 100}%;
    `
        : ''
    }
  `;
}

export const calculatePadding = (paddingProp = []) => {
  const paddingValue = (value) => {
    switch (value) {
      case 'sm':
        return '5px';
      case 'md':
        return '10px';
      case 'lg':
        return '20px';
      case 'none':
      default:
        return '0';
    }
  };

  // we expect paddingProp to be an array so error if this is incorrect
  if (Array.isArray(paddingProp)) {
    if (paddingProp.length >= 2) {
      return { padding: `${paddingValue(paddingProp[0])} ${paddingValue(paddingProp[1])}` };
    }

    if (paddingProp.length === 1) {
      return { padding: paddingValue(paddingProp[0]) };
    }
  } else {
    throw Error('calculatePadding value must be of type Array');
  }

  return { padding: paddingValue('none') };
};

export const calculateContainerPadding = (paddingProp = []) => {
  const paddingValue = (value) => {
    switch (value) {
      case 'sm':
        return '5px';
      case 'md':
        return '10px';
      case 'lg':
        return '20px';
      case 'none':
      default:
        return '0';
    }
  };

  if (paddingProp.length >= 2) {
    return { padding: `${paddingValue(paddingProp[0])} ${paddingValue(paddingProp[1])}` };
  }
  if (paddingProp.length === 1) {
    return { padding: paddingValue(paddingProp[0]) };
  }

  return { padding: paddingValue('none') };
};
