import memoize from 'lodash/memoize';
import has from 'lodash/has';

import { ENV as env, NEXT_PUBLIC_BUILD_ENV as buildEnv, REACT_APP_BUILD_ENV as CRAbuildEnv } from '../index';

const _getEnvironment = (ENV = env, NEXT_PUBLIC_BUILD_ENV = buildEnv, REACT_APP_BUILD_ENV = CRAbuildEnv) => {
  // If either of the environment variables are 'staging', we are in staging
  const isStaging = ENV === 'staging' || NEXT_PUBLIC_BUILD_ENV === 'staging' || REACT_APP_BUILD_ENV === 'staging';
  // If NODE_ENV is 'production' and we're not in staging, we're in production
  const isProduction = ENV === 'production' && !isStaging;

  if (isProduction) {
    return 'production';
  }
  if (isStaging) {
    return 'staging';
  }
  return 'development';
};

export const getEnvironment = memoize(_getEnvironment);

function _getEnvRoot(ENV = env) {
  switch (getEnvironment(ENV)) {
    case 'staging':
      return 'frontendserviceaccount.com';
    case 'development':
      return 'localhost';
    default:
      return 'lush.com';
  }
}

// GET API ROUTE
function _getGatewayUrl(ENV = env) {
  switch (getEnvironment(ENV)) {
    case 'production':
      return 'https://api-gateway.cloudserviceaccount.com';
    case 'staging':
    case 'development':
    default:
      return 'https://api-gateway-staging.platformserviceaccount.com';
  }
}

function _getLoginUrl(ENV = env, returnURL = null) {
  const redirect = returnURL ? `?return=${returnURL}` : '';
  switch (getEnvironment(ENV)) {
    case 'production':
      return `https://labs.lush.com/${redirect}`;
    case 'development':
      return `http://localhost:8000/${redirect}`;
    default:
    case 'staging':
      return `https://passport-staging.frontendserviceaccount.com/${redirect}`;
  }
}

function _appEnvironmentRoutes(ENV = env) {
  let applicationRoutes;
  const environment = getEnvironment(ENV);

  if (environment === 'development') {
    applicationRoutes = {
      PASSPORT: 'http://localhost:8000',
      CORE: 'http://localhost:8080',
      LUSH: 'http://localhost:8080/:language',
      ACCOUNT: 'http://localhost:8080/:language/my-account',
      SIGN_UP: 'http://localhost:8080/:language/sign-up',
      ANALYTICS: 'http://localhost:3004',
      CREATOR: 'http://localhost:3001',
      CREATORS: 'http://localhost:5050',
      ARTICLE_CREATOR: 'http://localhost:5050/:language/article',
      INGREDIENT_MASTER: 'http://localhost:5050/:language/ingredient',
      MANAGER: 'http://localhost:3030/:language',
      PEOPLE: 'http://localhost:3030/:language/people',
      VOUCHERS: 'http://localhost:3030/:language/bookings',
      SHOP_MANAGER: 'http://localhost:3030/:language/tills',
      MARKET_MANAGER: 'http://localhost:3030/:language/market-manager',
      LOCATIONS_MANAGER: 'http://localhost:3030/:language/locations',
      TAG_MANAGER: 'http://localhost:3030/:language/tag-manager',
      STOCK: 'http://localhost:3030/:language/stock',
      PRODUCT_MASTER: 'http://localhost:3000',
      LANGUAGE_MANAGER: 'http://localhost:3000',
      CONNECT_FEED: 'http://localhost:3030/:language/connect-feed',
      CONNECT: 'http://localhost:3000/:language'
    };
  } else if (environment === 'staging') {
    const stagingUrl = '-staging.frontendserviceaccount.com';
    const developUrl = '-develop.frontendserviceaccount.com';
    const http = 'https://';
    applicationRoutes = {
      PASSPORT: `${http}passport${stagingUrl}`,
      CORE: `${http}lush-com${stagingUrl}`,
      LUSH: `${http}lush-com${stagingUrl}/:language`,
      ACCOUNT: `${http}lush-com${stagingUrl}/:language/my-account`,
      SIGN_UP: `${http}lush-com${stagingUrl}/:language/sign-up`,
      ANALYTICS: `${http}analytics${stagingUrl}`,
      CREATOR: `${http}creator${stagingUrl}`,
      CREATORS: `${http}creators${stagingUrl}`,
      ARTICLE_CREATOR: `${http}creators${stagingUrl}/:language/article`,
      INGREDIENT_MASTER: `${http}creators${stagingUrl}/:language/ingredient`,
      MANAGER: `${http}manager${developUrl}/:language`,
      PEOPLE: `${http}people${developUrl}`,
      VOUCHERS: `${http}manager${developUrl}/:language/bookings`,
      SHOP_MANAGER: `${http}tills-manager${developUrl}/:language/tills`,
      MARKET_MANAGER: `${http}manager${developUrl}/:language/market-manager`,
      LOCATIONS_MANAGER: `${http}manager${developUrl}/:language/locations`,
      TAG_MANAGER: `${http}manager${developUrl}/:language/tag-manager`,
      STOCK: `${http}manager${developUrl}/:language/stock`,
      PRODUCT_MASTER: `${http}product-master${stagingUrl}`,
      LANGUAGE_MANAGER: `${http}language-manager${stagingUrl}`,
      CONNECT_FEED: `${http}manager${developUrl}/:language/connect-feed`,
      CONNECT: `${http}connect${stagingUrl}/:language`
    };
  } else {
    const prodUrl = 'labs.lush.com';
    const creatorsUrl = 'creators.lush.com';
    const lushCom = 'lush.com';
    const http = 'https://';
    applicationRoutes = {
      PASSPORT: `${http}passport.${prodUrl}`,
      CORE: `${http}${prodUrl}`,
      LUSH: `${http}${prodUrl}/:language`,
      ACCOUNT: `${http}${prodUrl}/:language/my-account`,
      SIGN_UP: `${http}${prodUrl}/:language/sign-up`,
      ANALYTICS: `${http}analytics.${prodUrl}`,
      CREATOR: `${http}creator.${prodUrl}`,
      CREATORS: `${http}creators.${lushCom}`,
      ARTICLE_CREATOR: `${http}${creatorsUrl}/:language/article`,
      INGREDIENT_MASTER: `${http}${creatorsUrl}/:language/ingredient`,
      PEOPLE: `${http}people.${prodUrl}`,
      MANAGER: `${http}manager.${prodUrl}/:language`,
      VOUCHERS: `${http}manager.${prodUrl}/:language/bookings`,
      SHOP_MANAGER: `${http}manager.${prodUrl}/:language/tills`,
      MARKET_MANAGER: `${http}manager.${prodUrl}/:language/market-manager`,
      LOCATIONS_MANAGER: `${http}manager.${prodUrl}/:language/locations`,
      TAG_MANAGER: `${http}manager.${prodUrl}/:language/tag-manager`,
      PRODUCT_MASTER: `${http}product-master.${prodUrl}`,
      LANGUAGE_MANAGER: `${http}language-manager.${prodUrl}`,
      STOCK: `${http}manager.${prodUrl}/:language/stock`,
      CONNECT_FEED: `${http}manager.${prodUrl}/:language/connect-feed`,
      CONNECT: `${http}connect.${prodUrl}/:language`
    };
  }
  return applicationRoutes;
}

export const appEnvironmentRoutes = memoize(_appEnvironmentRoutes);

// Function for returning different lush apps.
// groups = obj, project group.json
// language = string, current project language
const _getApps = ({ groups, language }) => {
  const environmentRoutes = appEnvironmentRoutes();

  return [
    {
      name: 'LUSH_LABS',
      href: environmentRoutes.LUSH.replace(':language', language),
      label: 'Lush Labs',
      icon: 'lush-labs'
    },
    {
      name: 'CREATORS',
      href: environmentRoutes.CREATORS.replace(':language', language),
      label: 'Creators',
      icon: 'creator',
      permissions: has(groups, 'CREATOR.ALL') ? groups.CREATOR.ALL : ['denied']
    },
    {
      name: 'PEOPLE',
      href: environmentRoutes.PEOPLE,
      label: 'People',
      icon: 'profile',
      permissions: has(groups, 'PEOPLE.ALL') ? groups.PEOPLE.ALL : ['denied']
    },
    {
      name: 'TILLS',
      href: environmentRoutes.SHOP_MANAGER.replace(':language', language),
      label: 'Store Manager',
      icon: 'smartphone-landscape',
      permissions: has(groups, 'TILLS.ALL') ? groups.TILLS.ALL : ['denied']
    },
    {
      name: 'STOCK',
      href: environmentRoutes.STOCK.replace(':language', language),
      label: 'Stock',
      icon: 'chart',
      permissions: has(groups, 'STOCK.ALL') ? groups.STOCK.ALL : ['denied']
    },
    {
      name: 'TAG_MANAGER',
      href: environmentRoutes.TAG_MANAGER.replace(':language', language),
      label: 'Tag Manager',
      icon: 'tag',
      permissions: has(groups, 'CORE.ADMIN') ? groups.CORE.ADMIN : ['denied']
    },
    {
      name: 'PRODUCT_MASTER',
      href: environmentRoutes.PRODUCT_MASTER.replace(':language', language),
      label: 'Product Master',
      icon: 'product',
      permissions: has(groups, 'PRODUCT_MASTER.PRODUCT_MASTER.ALL')
        ? groups.PRODUCT_MASTER.PRODUCT_MASTER.ALL
        : ['denied']
    },
    {
      name: 'LANGUAGE_MANAGER',
      href: environmentRoutes.LANGUAGE_MANAGER.replace(':language', language),
      label: 'Product Master',
      icon: 'translate',
      permissions: has(groups, 'LANGUAGE_MANAGER.LANGUAGE_MANAGER.ALL')
        ? groups.LANGUAGE_MANAGER.LANGUAGE_MANAGER.ALL
        : ['denied']
    }
  ];
};
export const getApps = memoize(_getApps);
export const getEnvRoot = memoize(_getEnvRoot);
export const getGatewayUrl = memoize(_getGatewayUrl);
export const getLoginUrl = memoize(_getLoginUrl);
