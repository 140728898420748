import styled from 'styled-components';
import PropTypes from 'prop-types';

const Warning = styled.span`
  color: #f6a623;
  font-weight: 400;
  width: 100%;
  display: block;
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  text-align: ${({ align }) => align};
`;

Warning.span = Warning;
Warning.h1 = Warning.withComponent('h1');
Warning.h2 = Warning.withComponent('h2');
Warning.h3 = Warning.withComponent('h3');
Warning.h4 = Warning.withComponent('h4');
Warning.h5 = Warning.withComponent('h5');
Warning.h6 = Warning.withComponent('h6');
Warning.p = Warning.withComponent('p');
Warning.label = Warning.withComponent('label');

Warning.propTypes = {
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  align: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string
};

Warning.defaultProps = {
  fontSize: '12px',
  lineHeight: '18px',
  align: 'left',
  padding: '0.3em 0 0 0',
  margin: '0'
};

Warning.displayName = 'Warning';

export default Warning;
