import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableRow from './fancyTable.tableRow';

// ==================== //
// TABLE BODY COMPONENT //
// ==================== //
export default class TableBody extends Component {
  renderRows() {
    const { mobile, data, columns, actions, clickCell, hoverCell, clickRow, hoverRow, clickColumn, hoverColumn } =
      this.props;
    const rowsArray = [];

    for (let i = 0; i < data.length; i++) {
      const rowData = data[i];
      rowsArray.push(
        <TableRow
          mobile={mobile}
          columns={columns}
          key={rowData.id || i}
          data={rowData}
          index={i}
          // Custom Columns
          actions={actions}
          // Functions
          clickCell={clickCell}
          hoverCell={hoverCell}
          clickRow={clickRow}
          hoverRow={hoverRow}
          clickColumn={clickColumn}
          hoverColumn={hoverColumn}
        />
      );
    }

    return rowsArray;
  }

  render() {
    const { mobile } = this.props;

    return <div className={`ui-table__tbody ${mobile ? 'ui-table__tbody--is-mobile' : ''} `}>{this.renderRows()}</div>;
  }
}
TableBody.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired,
  // Custom columns
  actions: PropTypes.object,
  // Functions
  clickCell: PropTypes.func,
  hoverCell: PropTypes.func,
  clickRow: PropTypes.func,
  hoverRow: PropTypes.func,
  clickColumn: PropTypes.func,
  hoverColumn: PropTypes.func
};
