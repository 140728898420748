import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '@lushdigital/icons';

export default class InlineMenuButton extends Component {
  render() {
    const { data, className, icon, title, toggle, onClick } = this.props;

    return (
      <div className={`ui-inline-menu__item ${className !== '' ? `inline-menu-${className}__item` : ''}`}>
        <button
          type="button"
          className={`ui-inline-menu__button ${className !== '' ? `inline-menu-${className}__button` : ''}`}
          onClick={(e) => onClick(e, data, toggle)}
          title={title}
        >
          <span className={`ui-inline-menu__text ${className !== '' ? `inline-menu-${className}__text` : ''}`}>
            {title}
          </span>
          <div className={`ui-inline-menu__icon ${className !== '' ? `inline-menu-${className}__icon` : ''}`}>
            <Icon icon={icon} size="18px" />
          </div>
        </button>
      </div>
    );
  }
}

InlineMenuButton.displayName = 'InlineMenuButton';

InlineMenuButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  toggle: PropTypes.func,
  data: PropTypes.object,
  className: PropTypes.string
};

InlineMenuButton.defaultProps = {
  title: '',
  icon: 'trending-up',
  className: '',
  data: {},
  toggle: () => null
};
