import styled from 'styled-components';
import PropTypes from 'prop-types';

// Exported default element
const Select = styled.select`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 400;
  line-height: 1.2;

  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  border: 1px solid #eaeaea;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 0;

  appearance: none;
  &::-ms-expand {
    display: none;
  }

  ${({ type }) =>
    type === 'sort'
      ? `
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAgNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwIDUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTAsMjkuMWgxMGwtNSw1TDAsMjkuMXoiLz48cGF0aCBkPSJNMTAsMjAuOUgwbDUtNUwxMCwyMC45eiIvPjwvc3ZnPg==);
  `
      : `
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjUwcHgiIHZpZXdCb3g9IjAgMCAxMCA1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAgNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBkPSJNMCwyMi41aDEwbC01LDVMMCwyMi41eiIvPjwvc3ZnPg==);
  `}
  background-repeat: no-repeat;
  background-position: right 1em center;

  &::placeholder {
    color: #eaeaea;
    opacity: 0.8;
  }

  &:disabled,
  &[readonly] {
    color: #8f8f8f;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${({ error }) => (error ? 'border-color: #D0021B;' : '')}
  &.error,
  &.invalid {
    border-color: #d0021b;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 0 0 0.4em rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: #cecece;
  }

  &::-moz-placeholder {
    color: #cecece;
  }

  &:-ms-input-placeholder {
    color: #cecece;
  }

  ${({ fullWidth }) => {
    if (fullWidth) {
      return `
        display: flex; width: 100%;
        justify-content: center;
      `;
    }
    return 'display: inline-flex;';
  }};
`;

// Optional tag options
Select.select = Select;

// Default props
Select.defaultProps = {
  fontSize: '14px',
  padding: '1.143em 2.8em 1.143em 1.8em',
  margin: '0',
  error: false,
  fullWidth: false,
  type: 'select'
};

// Prop type check
Select.propTypes = {
  type: PropTypes.oneOf(['select', 'sort']),
  fontSize: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool
};

Select.displayName = 'Select';

// export
export default Select;
