import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { useSession as authUseSession } from 'next-auth/react';

export async function fetchSession() {
  const res = await fetch('/api/auth/session');
  const session = await res.json();
  if (Object.keys(session).length) {
    return session;
  }
  return null;
}

type Session = {
  user: {
    userId: number;
    firstName: string;
    lastName: string;
    user: {
      profile_image: string | null;
      location: number;
      role: string;
      type: string;
      business_unit: string;
      privacy_notice: string;
    };
    roles: number[];
    avatar: string | null;
    language: string | null;
    country: string | null;
    storeManager: boolean;
    homeShop: number;
  };
  expires: string;
  idToken: string;
  shop: number;
};

export function useSession({
  required,
  redirectTo = '/api/auth/signin?error=SessionExpired',
  queryConfig = {}
}: {
  required?: boolean;
  redirectTo?: string;
  queryConfig?: { onSettled?: (data: any, error: any) => void };
} = {}): [Session, boolean, string] {
  const s = authUseSession();

  const router = useRouter();
  const query = useQuery(['session'], fetchSession, {
    ...queryConfig,
    initialData: s.data,
    staleTime: 60000,
    refetchInterval: 60000 * 1, // 1 minutes
    // refetchIntervalInBackground: true,
    onSettled(data, error) {
      if (queryConfig.onSettled) {
        queryConfig.onSettled(data, error);
      }

      if (data || !required) return;

      console.warn('rq redirect');
      router.push(redirectTo);
    }
  });

  return [query?.data || {}, query?.status === 'loading', query?.status];
}
