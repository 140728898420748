import styled from 'styled-components';

export const Container = styled.div`
  background: #000;
  z-index: 100;

  position: sticky;

  top: 0;
  left: 0;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 10px;
`;

export const Menu = styled.nav`
  position: relative;
  display: grid;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 80px;
  grid-template-columns: 1fr 1fr;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 420px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Title = styled.span`
  z-index: 2;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 0;
  text-decoration: none;
  display: none;
  font-weight: 500;
  margin: 0;

  @media (min-width: 420px) {
    display: block;
  }
`;

export const Controls = styled.div`
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
