import styled from 'styled-components';
import PropTypes from 'prop-types';

import { calculatePadding } from '../../../helpers/layoutHelpers';

const Row = styled.div`
  display: flex;
  width: 100%;

  flex: ${({ flex }) => flex};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  ${({ padding }) => {
    if (typeof padding === 'object') return calculatePadding(padding);
    if (typeof padding === 'string') return `padding: ${padding};`;
    return null;
  }};
`;

Row.div = Row;
Row.section = Row.withComponent('section');
Row.header = Row.withComponent('header');
Row.footer = Row.withComponent('footer');
Row.article = Row.withComponent('article');
Row.nav = Row.withComponent('nav');

Row.defaultProps = {
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  padding: [],
  flex: '0 1 auto'
};

Row.propTypes = {
  flex: PropTypes.string,
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  flexWrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  /**
   * Vertically align inner items
   */
  alignItems: PropTypes.oneOf(['normal', 'stretch', 'flex-start', 'center', 'flex-end']),
  /**
   * Horizontally align inner items. Options: 'space-between', 'center', 'flex-start', 'flex-end'
   */
  justifyContent: PropTypes.oneOf([
    'normal',
    'space-evenly',
    'stretch',
    'space-around',
    'space-between',
    'center',
    'flex-start',
    'flex-end'
  ]),
  /**
   * An array of up to two values to define padding
   * 1 value = [All sides]
   * 2 values = [Vertical, Horizontal]
   * OR
   * Pass in a string
   */
  padding: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOf(['none', 'sm', 'md', 'lg'])), PropTypes.string])
};

Row.displayName = 'Row';

export default Row;
