import { createContext } from 'react';
import { PaymentTypes } from '@/types/PaymentTypes';

export type FeaturesContextType = {
  features: {
    shopId: string | number;
    timezone: string;
    countryCode: string;
    eodCount: boolean;
    paymentTypes: PaymentTypes;
    hasLooker: boolean;
    featureFlags: string[];
  };
};

export const FeaturesContext = createContext<FeaturesContextType | null>(null);
