import { css } from 'styled-components';

export const wordBreak = `
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

export const buttonReset = `
  box-sizing: border-box;
  cursor: pointer;
  backface-visibility: hidden;
  border: none;
  background: transparent;
  border-radius: 0;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

export const srOnly = `
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const objectCoverVideo = css`
  /* modern browsers */
  @supports (object-fit: cover) {
    object-fit: cover;
    height: 100%;
  }

  /* ie edge only gets the following rule */
  @supports (object-fit: cover) and (-ms-ime-align: auto) {
    width: auto !important;
    height: auto !important;
    min-width: 100%;
    min-height: 100%;
  }
`;

function decideFont(font) {
  switch (font) {
    case 'LushHandwritten':
      return "'LushHandwritten', 'inter', 'sans-serif'";
    case 'inter':
      return "'inter', 'sans-serif'";
    default:
      return font;
  }
}

export function fontFamily(font) {
  if (font) {
    return `
    ${font === 'LushHandwritten' ? 'line-height: 1 !important;' : ''}
      font-family: ${decideFont(font)}
    `;
  }
  return `font-family: ${font}`;
}

export function sizeMixin(size, columns, flexBasis = '0%') {
  return `
    ${
      typeof size === 'string'
        ? `
      width: ${size};
      flex-basis: ${flexBasis !== '0%' ? flexBasis : size};
      max-width: ${size};
    `
        : ''
    }


    ${
      typeof size === 'number'
        ? `
      width: ${(size / columns) * 100}%;
      flex-basis: ${flexBasis !== '0%' ? flexBasis : `${(size / columns) * 100}%`};
      max-width: ${(size / columns) * 100}%;
    `
        : ''
    }
  `;
}
