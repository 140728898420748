import { find, get, isEmpty, map, isNaN } from 'lodash';

import currencies from './currencies';
import { ukPaymentTypes } from './paymentTypes';
//
// GET VALUE OBJECT BY CURRENCY
//
export function getSalesByCurrency(data, currency = 'GBP') {
  if (data && data[currency]) {
    return data[currency];
  }
  return {
    amount: 0,
    currency
  };
}

//
// GET SUBTOTALS BY CURRENCY
//
/**
 * @param {object} data - A string param.
 * @param {string} currency - An optional param (Google Closure syntax)
 * @param {boolean} showForeignCash - An optional param (Google Closure syntax)
 * @param {object} [types=ukPaymentTypes] - An optional param with a default value
 * @returns {object[]}} This is the result
 */
export function getSubTotalsByCurrency(data, currency = 'GBP', showForeignCash = false, types = ukPaymentTypes) {
  if (data && data.sub_totals) {
    let foreignCashPayments = [];
    const foreignCash = get(data, `exchanged_foreign_cash[${currency}]`, null);
    if (foreignCash && showForeignCash) {
      foreignCashPayments = [
        {
          type: 'foreign_cash',
          amount: foreignCash.amount,
          currency
        }
      ];
    }

    const subTotals = data.sub_totals[currency] || {};

    const subtotalDataWithAllTypes = map(types, (type) => {
      const subtotal = find(subTotals, (s) => s.type === type.reportField);
      if (subtotal) {
        return {
          type: type.reportField,
          currency,
          amount: subtotal.amount,
          formatted_amount: subtotal.formatted_amount
        };
      }
      return {
        type: type.reportField,
        currency,
        amount: 0,
        formatted_amount: currency === 'JPY' ? '0' : '0.00'
      };
    });

    return [...subtotalDataWithAllTypes, ...foreignCashPayments];
  }

  return Object.entries(types).map(([, type]) => ({
    type: type.reportField,
    amount: 0,
    formatted_amount: currency === 'JPY' ? '0' : '0.00',
    currency
  }));
}

//
// GET CASH TRANSACTION BY CURRENCY
//
export function getCashTransactionsByCurrency(data, currency = 'GBP') {
  return get(data, `${currency}`, []);
}

//
// GET AMOUNT BY CURRENCY
//
export function getAmountByCurrency(data, currency = 'GBP') {
  // NOT USED YET
  return get(data, `${currency}.amount`, 0);
}

//
// GET AMOUNT BY CURRENCY
//
export function getCountByCurrency(data, currency = 'GBP') {
  // NOT USED YET
  return get(data, `${currency}.count`, 0);
}

//
// GET BY CURRENCY
//
export function getByCurrency(data, currency = 'GBP') {
  // NOT USED YET
  return get(data, `${currency}`, 0);
}

//
// RETURN CURRENCY VALUE
//
export function returnCurrencyValue(amount, currency = 'GBP') {
  if (typeof amount !== 'number' || Number.isNaN(amount)) {
    return 0;
  }
  if (currency === 'JPY') {
    return amount === 0 ? 0 : amount;
  }
  return amount === 0 ? 0 : amount / 100;
}

//
// GET DETAILS FOR A GIVEN CURRENCY
//
export function getCurrencyDetails(currency) {
  if (currency && typeof currency === 'string') {
    const currencyDetails = find(currencies, { code: currency });
    if (!isEmpty(currencyDetails)) {
      return currencyDetails;
    }
  }
  return null;
}

//
// GET AVAILABLE DENOMINATIONS FOR A GIVEN CURRENCY
//
export function getCurrencyDenominations(currency) {
  if (currency && typeof currency === 'string') {
    const currencyDenominations = find(currencies, { code: currency });
    if (!isEmpty(currencyDenominations) && !isEmpty(currencyDenominations.denominations)) {
      return currencyDenominations.denominations;
    }
  }
  return [];
}

//
// COUNT DECIMAL PLACES
//
export function countDecimalPlaces(num) {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

//
// CHECK IF COUNT IS ACHIEVABLE IN A GIVEN DENOMINATION
//
export function calculateCountValid({ value, denomination }) {
  // Need to calculate whether the input value is possible
  let num = 0;
  let error = false;
  // Check if number passed for value
  if (!isNaN(value)) {
    // Round the amount down to the nearest integer
    num = Math.floor(value / denomination.amount);
  }
  // Check that the input value is achievable in that denom
  const recalcValue = num * denomination.amount;
  if (recalcValue !== value && value !== 0) {
    if (!isNaN(value)) {
      error = true;
    }
  }
  return {
    count: num,
    error
  };
}

export function sumTransactions(transactions, currency, startValue) {
  return getCashTransactionsByCurrency(transactions, currency).reduce((a, b) => a + b.amount, startValue);
}
