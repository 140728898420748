import styled from 'styled-components';

// helpers
import colour from '../colour';

// base typography component
import base from '../base';

// element
const SubText = styled('span')`
  ${base};
`;
// default props for element
SubText.defaultProps = {
  textSize: '11px',
  lineHeight: '1.4',
  margin: '0 0 5px',
  color: colour.darkgray
};

// DisplayName for new element
SubText.displayName = 'SubText';

// export element
export default SubText;
