import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickOutsideListener } from '@/hooks/useClickOutside';

// styled
import { Wrapper, Label, List } from '@lushdigital/header/styles/dropdown';

// component
const Dropdown = ({ items, label }) => {
  const [listVisible, setListVisible] = useState(false);
  const wrapperRef = useRef(null);

  useClickOutsideListener(wrapperRef, () => setListVisible(false));

  if (label) {
    return (
      <Wrapper ref={wrapperRef}>
        <Label onClick={() => setListVisible(!listVisible)}>{label}</Label>
        <List open={listVisible}>{items}</List>
      </Wrapper>
    );
  }

  return null;
};

Dropdown.defaultProps = {
  items: [],
  label: ''
};

Dropdown.propTypes = {
  items: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Dropdown;
