import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Config from '../../../context/size/config';

// supporting
import { sizeMixin, calculatePadding } from '../../../helpers/layoutHelpers';
import Breakpoint from '../../../helpers/breakpoint';

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-basis: ${({ flexBasis }) => flexBasis};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  /* Padding */
  ${({ padding }) => {
    if (typeof padding === 'object') return calculatePadding(padding);
    if (typeof padding === 'string') return `padding: ${padding};`;
    return null;
  }};

  /* Default size */
  ${(props) => `${sizeMixin(props.default, props.columns, props.flexBasis)}`};

  ${(props) =>
    Object.keys(Config.breakpoints).map((bp) => {
      if (!props[bp]) return null;
      return css`
        ${Breakpoint(bp)`
          ${sizeMixin(props[bp], props.columns, props.flexBasis)}
        `}
      `;
    })};
`;

Column.defaultProps = {
  flexGrow: '1',
  flexShrink: '0',
  flexBasis: '0%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: ['md'],
  columns: 12,
  default: null,
  ...Object.keys(Config.breakpoints).reduce(
    (accumulator, bp) => ({
      ...accumulator,
      [bp]: null
    }),
    {}
  )
};

Column.propTypes = {
  flexGrow: PropTypes.string,
  flexShrink: PropTypes.string,
  flexBasis: PropTypes.string,
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  /**
   * Vertically align inner items
   */
  alignItems: PropTypes.oneOf(['normal', 'stretch', 'flex-start', 'center', 'flex-end']),
  /**
   * Horizontally align inner items. Options: 'space-between', 'center', 'flex-start', 'flex-end'
   */
  justifyContent: PropTypes.oneOf([
    'normal',
    'space-evenly',
    'stretch',
    'space-around',
    'space-between',
    'center',
    'flex-start',
    'flex-end'
  ]),
  /**
   * An array of up to two values to define padding
   * 1 value = [All sides]
   * 2 values = [Vertical, Horizontal]
   */
  padding: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOf(['none', 'sm', 'md', 'lg'])), PropTypes.string]),
  /**
   * Number defining the amount of columns in grid
   */
  columns: PropTypes.number,

  /**
   * Define size of column from anything that doesn't match a breakpoint
   * number will be that in the amount of set columns e.g. 3/12 = 24%
   * string can be any string
   */
  default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...Object.keys(Config.breakpoints).reduce(
    (accumulator, bp) => ({
      ...accumulator,
      [bp]: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    {}
  )
};

Column.displayName = 'Column';

export default Column;
