import React from 'react';

// components
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { AccountDropdown } from './accountDropdown';
import AppNavigation from './appNavigation';
import { dashboardTileLinks } from '@/tills/components/dashboard-tiles';
import { ShortcutMenu } from './shortcutMenu';
import packageJson from 'package.json';

// styled
import { Container, Wrapper, Menu, Title, Controls } from '../styles/header';

// component
export const Header = ({
  title = 'Lush Manager',
  dashboard = null
}: {
  title?: string;
  dashboard?: React.ReactNode;
}): JSX.Element => {
  const { t, lang } = useTranslation('tills');
  const router = useRouter();
  const routeFragment = router.pathname.split('/')[1];
  const section = dashboardTileLinks.find((tile) => tile.section === routeFragment);
  const managerVersion = packageJson.version;

  return (
    <Container>
      <Wrapper>
        <Menu>
          <AppNavigation items={dashboard} section={section?.route?.title || routeFragment} />
          <Title>
            {t(title)} - v{managerVersion}
          </Title>
          <Controls>
            <ShortcutMenu />
            <AccountDropdown />
          </Controls>
        </Menu>
      </Wrapper>
    </Container>
  );
};
