import React, { Component } from 'react';
import PropTypes from 'prop-types';

//
import Optional from '../optional';
import Label from '../label';
import Subtext from '../subText';
import SrOnly from '../srOnly';
import Error from '../error';

// STYLES
import { Wrapper, Element, Test } from './selector.styles';

// COMPONENT
class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 'auto',
      selected: ''
    };
  }

  componentDidMount() {
    const selected = this.textInput[this.textInput.selectedIndex].text;

    this.setState({ selected }, () => this.resizeSelect());
  }

  componentDidUpdate(prevProps, prevState) {
    // if anything changes resize...
    const selectedChange = prevState.selected !== this.state.selected;
    const classesChange = prevProps.classes !== this.props.classes;
    const placeholderChange = prevProps.placeholder !== this.props.placeholder;
    const optionsChange = JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options);
    const optionGroupsChange = JSON.stringify(prevProps.optionGroups) !== JSON.stringify(this.props.optionGroups);
    const stylesChange = JSON.stringify(prevProps.styles) !== JSON.stringify(this.props.styles);

    if (selectedChange || placeholderChange || classesChange || optionsChange || optionGroupsChange || stylesChange) {
      this.resizeSelect();
    }
  }

  shouldDisplayError() {
    const { touched, error, showError } = this.props;

    return touched === true && error !== undefined && error !== '' && error !== null && showError;
  }

  resizeSelect() {
    this.setState({ width: this.testInput.offsetWidth + 2 });
  }

  handleChange = (e) => {
    this.setState({ selected: e.target[e.target.selectedIndex].text });

    // External hook event
    this.props.onChange(e);
  };

  handleBlur = (e) => {
    this.setState({ selected: e.target[e.target.selectedIndex].text });

    // External hook event
    if (this.props.onBlur !== null && typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  };

  renderLabel() {
    const { hideLabel, label, name } = this.props;

    const hasLabel = label !== '' && label !== undefined;

    if (hideLabel) {
      return (
        <SrOnly>
          <Label htmlFor={name}>
            {hasLabel ? label : name}
            {this.renderOptionalText()}
          </Label>
        </SrOnly>
      );
    }
    return (
      <Label htmlFor={name}>
        {hasLabel ? label : name}
        {this.renderOptionalText()}
      </Label>
    );
  }

  renderOptions() {
    const { options, placeholder, placeholderDisabled, valueKey, labelKey } = this.props;
    const list = [];

    if (placeholder !== '') {
      list.push(
        <option disabled={placeholderDisabled} key="selector-placeholder-option-key" value="">
          {placeholder}
        </option>
      );
    }

    if (options.length) {
      options.map((option) => {
        list.push(
          <option value={option[valueKey]} key={option[valueKey]}>
            {option[labelKey]}
          </option>
        );
      });
    }

    return list;
  }

  renderOptionGroups() {
    const { placeholder, placeholderDisabled, optionGroups, valueKey, labelKey } = this.props;
    const list = [
      <option disabled={placeholderDisabled} value="" key="selector-placeholder-option-key">
        {placeholder}
      </option>
    ];

    if (optionGroups.length) {
      optionGroups.forEach((optionGroup) => {
        const opt = optionGroup.options.map((option) => (
          <option value={option[valueKey]} key={option[valueKey]}>
            {option[labelKey]}
          </option>
        ));
        list.push(
          <optgroup key={optionGroup.label} label={optionGroup.label}>
            {opt}
          </optgroup>
        );
      });
    }

    return list;
  }

  renderOptionalText() {
    const { optional } = this.props;
    if (optional) {
      return (
        <span>
          {' '}
          — <Optional>optional</Optional>
        </span>
      );
    }
    return null;
  }

  renderSubtext() {
    const { subtext, hideLabel } = this.props;

    if (subtext !== null) {
      if (hideLabel) {
        return (
          <SrOnly>
            <Subtext>{subtext}</Subtext>
          </SrOnly>
        );
      }
      return <Subtext>{subtext}</Subtext>;
    }
    return null;
  }

  render() {
    const {
      name,
      value,
      error,
      isSubmitting,
      disabled,
      optional,
      classes,
      options,
      optionGroups,
      spacing,
      styles,
      placeholder
    } = this.props;
    const showError = this.shouldDisplayError();

    return (
      <Wrapper spacing={spacing} className={showError ? 'invalid' : ''}>
        {this.renderLabel()}
        {this.renderSubtext()}
        <Element
          id={name}
          className={`form__selector ${classes}`}
          name={name}
          value={value}
          onChange={this.handleChange}
          style={{ ...styles, width: this.state.width }}
          onBlur={this.handleBlur}
          required={!optional}
          disabled={isSubmitting || disabled}
          isError={showError}
          ref={(input) => {
            this.textInput = input;
          }}
        >
          {(options.length || placeholder) && optionGroups.length === 0 && this.renderOptions()}
          {(optionGroups.length || placeholder) && options.length === 0 && this.renderOptionGroups()}
        </Element>
        <Test
          ref={(input) => {
            this.testInput = input;
          }}
          style={styles}
        >
          {this.state.selected}
        </Test>
        {showError && (
          <Error padding="5px 0 0" className="form__error">
            {error}
          </Error>
        )}
      </Wrapper>
    );
  }
}

Selector.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  styles: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  optionGroups: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  subtext: PropTypes.string,
  optional: PropTypes.bool,
  showError: PropTypes.bool,
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  placeholderDisabled: PropTypes.bool,
  spacing: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
  placeholder: PropTypes.string
};

Selector.defaultProps = {
  styles: {},
  subtext: null,
  label: '',
  value: '',
  optionGroups: [],
  labelKey: 'label',
  valueKey: 'value',
  showError: true,
  error: null,
  touched: false,
  isSubmitting: false,
  disabled: false,
  optional: false,
  classes: '',
  hideLabel: false,
  spacing: 'none',
  placeholder: 'Please select',
  placeholderDisabled: true,
  onBlur: null
};

Selector.displayName = 'Selector';

export default Selector;
