import { css } from 'styled-components';
import Config from '../../context/size/config';

const sizes = Object.keys(Config.breakpoints).reduce(
  (accumulator, bp) => ({
    ...accumulator,
    [bp]: `${Config.breakpoints[bp]}px`
  }),
  {}
);

export default function Breakpoint(min, max) {
  let selectedMinSize;
  let selectedMaxSize;

  if (sizes[min] !== undefined) {
    selectedMinSize = sizes[min];
  } else if (min !== undefined) {
    selectedMinSize = min;
  }

  if (sizes[max] !== undefined) {
    selectedMaxSize = sizes[max];
  } else if (max !== undefined) {
    selectedMaxSize = max;
  }

  return function (strings) {
    const interpolations = [];
    for (let i = 1; i < arguments.length; i++) {
      interpolations[i - 1] = arguments[i];
    }
    const x = css(strings, ...interpolations);

    if (min === undefined && max === undefined && min === '' && max === '') {
      return css`
        ${x}
      `;
    }

    if (min && max) {
      return css`
        @media (min-width: ${selectedMinSize}) and (max-width: ${selectedMaxSize}) {
          ${x}
        }
      `;
    }

    return css`
      @media (min-width: ${selectedMinSize}) {
        ${x}
      }
    `;
  };
}
