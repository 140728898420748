import { useEffect, useState } from 'react';
import { getFeatureFlags } from '@/tills/helpers/featureFlags';

const useFeatureFlags = (countryCode, shopId) => {
  const [flags, setFlags] = useState([]);

  useEffect(() => {
    if (!countryCode || !shopId) {
      setFlags([]);
      return;
    }

    // Filter available flags into an array of feature flag names.
    const flagNames = getFeatureFlags(shopId, countryCode);

    setFlags(flagNames);
  }, [countryCode, shopId]);

  return flags;
};

export default useFeatureFlags;
