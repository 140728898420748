import GROUPS from '@/manager-next/config/groups.json';
import GROUPS_PROD from '@/manager-next/config/groups_production.json';

const ENV = process.env.NEXT_PUBLIC_BUILD_ENV || process.env.NODE_ENV;

const base = `/:language`;

const groups = ENV === 'production' ? GROUPS_PROD : GROUPS;

export const FOUROHFOUR = {
  path: `${base}/*`,
  title: '404_ERROR',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const ERROR_404 = {
  path: `${base}/404-error`
};

export const DASHBOARD = {
  path: `${base}/`,
  title: 'DASHBOARD',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

/* Tills routes */

export const TILLS = {
  path: `${base}/tills`,
  title: 'TILLS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const REPORTS = {
  path: `${base}/reports`,
  title: 'REPORTS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const TILL_REPORT = {
  path: `${base}/tills/:shop/:till`,
  title: 'TILLS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

/* End of day routes */

export const TILL_READS = {
  path: `${base}/till-reads/:date`,
  title: 'TILL_READS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const TILL_END_OF_DAY = {
  path: `${base}/till-reads/end-of-day/:shop/:date/:till`,
  title: 'TILLS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const SHOP_END_OF_DAY = {
  path: `${base}/till-reads/end-of-day/:shopId/:date`,
  title: 'TILLS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

// export const TILL_READ = {
//   path: `${base}/till-read/shop/:shop/till/:id/date/:date`,
//   title: 'TILL_READS',
//   permissions: groups.TILLS.STORE_MANAGEMENT
// };

export const TILL_READ = {
  path: `${base}/till-reads/reports/:shop/:date/:till`,
  title: 'TILL_READS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

/* Petty cash routes */

export const PETTYCASH = {
  path: `${base}/petty-cash/from/:startYear/:startMonth/to/:endYear/:endMonth`,
  title: 'PETTY_CASH',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const PETTYCASH_WEEK = {
  path: `${base}/petty-cash/from/:startYear/:startMonth/to/:endYear/:endMonth/:week/open`,
  title: 'PETTY_CASH',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const PETTYCASH_WEEK_CHECKED = {
  path: `${base}/petty-cash/from/:startYear/:startMonth/to/:endYear/:endMonth/:week/checked`,
  title: 'PETTY_CASH',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

/* Till read routes */

// export const CASH_SHEET = {
//   path: `${base}/cash-sheet/totals/shop/:shop/date/:date`,
//   title: 'CASH_SHEET',
//   permissions: groups.TILLS.STORE_MANAGEMENT
// };

// export const CASH_SHEET_REFUND = {
//   path: `${base}/cash-sheet/refunds/shop/:shop/date/:date`,
//   title: 'CASH_SHEET',
//   permissions: groups.TILLS.STORE_MANAGEMENT
// };

// export const CASH_SHEET_BANKING = {
//   path: `${base}/cash-sheet/banking/shop/:shop/date/:date`,
//   title: 'CASH_SHEET',
//   permissions: groups.TILLS.STORE_MANAGEMENT
// };

// export const CASH_SHEET_DISCUSSIONS = {
//   path: `${base}/cash-sheet/discussions/shop/:shop/date/:date`,
//   title: 'CASH_SHEET',
//   permissions: groups.TILLS.STORE_MANAGEMENT
// };

// export const CASH_SHEET_DAILY_PRINTOUT = {
//   path: `${base}/cash-sheet/printout/shop/:shop/date/:date`,
//   title: 'CASH_SHEET',
//   permissions: groups.TILLS.STORE_MANAGEMENT
// };

export const CASH_SHEET = {
  path: `${base}/till-reads/reports/:shop/:date`,
  // path: `${base}/cash-sheet/shop/:shop/date/:date`, // totals
  title: 'CASH_SHEET',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const CASH_SHEET_REFUND = {
  path: `${base}/till-reads/reports/:shop/:date/refunds`,
  // path: `${base}/cash-sheet/refunds/shop/:shop/date/:date`,
  title: 'CASH_SHEET',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const CASH_SHEET_BANKING = {
  path: `${base}/till-reads/reports/:shop/:date/banking`,
  // path: `${base}/cash-sheet/banking/shop/:shop/date/:date`,
  title: 'CASH_SHEET',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const CASH_SHEET_DISCUSSIONS = {
  // path: `${base}/cash-sheet/discussions/shop/:shop/date/:date`,
  path: `${base}/till-reads/reports/:shop/:date/discussions`,
  title: 'CASH_SHEET',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const CASH_SHEET_DAILY_PRINTOUT = {
  // path: `${base}/cash-sheet/printout/shop/:shop/date/:date`,
  path: `${base}/till-reads/reports/:shop/:date/print`,
  title: 'CASH_SHEET',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

/* Transactions routes */

export const TRANSACTIONS = {
  path: `${base}/transactions/:shop/:date`,
  title: 'TRANSACTIONS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const TRANSACTIONS_TILL = {
  path: `${base}/transactions/:shop/:date/:till`,
  title: 'TILL_READS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const TRANSACTION = {
  path: `${base}/transactions/:shop/:date/:till/:transaction`,
  title: 'TILL_READS',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

/* Device manager routes */

export const DEVICE_MANAGER = {
  path: `${base}/device-manager/tills`,
  title: 'DEVICE_MANAGER',
  permissions: groups.TILLS.ADMIN
};

export const DEVICE_MANAGER_DEVICES = {
  path: `${base}/device-manager/devices`,
  title: 'DEVICE_MANAGER',
  permissions: groups.TILLS.ADMIN
};

/* TSS routes */

export const TSS = {
  path: `${base}/tss`,
  title: 'TSS',
  permissions: groups.TILLS.ADMIN
};

/* Store management route */

export const STORE_MANAGER = {
  path: `${base}/store-manager`,
  title: 'SHOP_MANAGER',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const STORE_ADD = {
  path: `${base}/store-manager/add`,
  title: 'SHOP_MANAGER',
  permissions: groups.TILLS.FINANCE
};

export const STORE_EDIT = {
  path: `${base}/store-manager/edit/:shop`,
  title: 'SHOP_MANAGER',
  permissions: groups.TILLS.FINANCE
};

export const STORE_OPENINGS = {
  path: `${base}/store-manager/opening-times/:shop`,
  title: 'SHOP_MANAGER',
  permissions: groups.TILLS.STORE_MANAGEMENT
};

export const DIAGNOSTICS = {
  path: `${base}/diagnostics`,
  title: 'DIAGNOSTICS',
  permissions: groups.TILLS.ADMIN
};
