import PropTypes from 'prop-types';
import styled from 'styled-components';

// helpers
import colour from '../colour';

// base typography component
import base from '../base';

// element
const Warning = styled('span')`
  ${base}
`;

// default props for element
Warning.defaultProps = {
  textSize: '12px',
  lineHeight: '18px',
  padding: '0.3em 0 0 0',
  color: colour.amber
};

Warning.propTypes = {
  textSize: PropTypes.string,
  lineHeight: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string
};

// DisplayName for new element
Warning.displayName = 'Warning';

// export element
export default Warning;
