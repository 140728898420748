import styled from 'styled-components';

// base typography component
import base from '../base';

// element
const Title = styled('span')`
  ${base}
`;

// default props for element
Title.defaultProps = {
  textSize: '17px',
  textWeight: '600',
  lineHeight: '21px',
  align: 'center'
};

// DisplayName for new element
Title.displayName = 'Title';

// export element
export default Title;
