import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// helper
import { sanitize, shouldDisplayError, shouldDisplayWarning } from '../../helpers';

// local
import Row from '../../atoms/flex/row';
import Label from '../../atoms/form/label';
import SubText from '../../atoms/form/subText';
import TextArea from '../../atoms/form/textArea';
import Warning from '../../atoms/typography/warning';
import Error from '../../atoms/typography/error';
import SrOnly from '../../atoms/typography/srOnly';
import Optional from '../../atoms/typography/optional';

// styled
const Wrapper = styled.label`
  display: block;
  width: 100%;
`;

class TextAreaField extends Component {
  constructor(props) {
    super(props);
    this.state = { touched: false };
  }

  shouldDisplayError() {
    const { forceTouch, showError, error } = this.props;
    const { touched } = this.state;

    return shouldDisplayError({ forceTouch, showError, error, touched });
  }

  shouldDisplayWarning() {
    const { forceTouch, warning, showWarning } = this.props;
    const { touched } = this.state;

    return shouldDisplayWarning({ forceTouch, warning, showWarning, touched });
  }

  // functions
  handleChange = (e) => {
    const { onChange } = this.props;

    this.setState(() => ({ touched: true }));

    onChange(e, sanitize(e.target.value));
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;

    this.setState(() => ({ touched: true }));

    onBlur(e, sanitize(e.target.value));
  };

  // render
  renderOptionalText() {
    const { optionalLabel, optional } = this.props;
    if (optional) {
      return (
        <span>
          {' '}
          — <Optional>{optionalLabel}</Optional>
        </span>
      );
    }
    return null;
  }

  renderLabel() {
    const { labelProps, hideLabel, label, name } = this.props;

    if (!hideLabel && label !== '' && label !== undefined) {
      return (
        <Label.span {...labelProps}>
          {label}
          {this.renderOptionalText()}
        </Label.span>
      );
    }
    return (
      <SrOnly>
        {name}
        {this.renderOptionalText()}
      </SrOnly>
    );
  }

  renderSubText() {
    const { hideLabel, subtext } = this.props;

    if (!hideLabel && subtext !== null) {
      return <SubText>{subtext}</SubText>;
    }
    return <SrOnly>{subtext}</SrOnly>;
  }

  renderPrefix() {
    const { prefix } = this.props;

    if (prefix !== null) {
      return prefix;
    }
    return null;
  }

  renderSuffix() {
    const { suffix } = this.props;

    if (suffix !== null) {
      return suffix;
    }
    return null;
  }

  render() {
    const {
      name,
      value,
      type,
      placeholder,
      error,
      optional,
      disabled,
      min,
      // max,
      warning,
      textAreaProps,
      errorProps,
      warningProps
    } = this.props;

    const showError = this.shouldDisplayError();
    const showWarning = this.shouldDisplayWarning();

    return (
      <Wrapper htmlFor={name} className={`${showError ? 'invalid' : ''} ${showWarning ? 'warning' : ''}`}>
        {this.renderLabel()}
        {this.renderSubText()}
        <Row flexWrap="nowrap" alignItems="center">
          {this.renderPrefix()}
          <TextArea
            ref={name}
            id={name}
            name={name}
            value={value}
            type={type}
            placeholder={placeholder}
            onChange={(e) => {
              this.handleChange(e);
            }}
            onBlur={(e) => {
              this.handleBlur(e);
            }}
            required={!optional}
            disabled={disabled}
            min={min}
            // maxLength={typeof max === 'string' ? Number(max) : max} // invalid prop on a textarea
            error={showError}
            fullWidth
            {...textAreaProps}
          />
          {this.renderSuffix()}
        </Row>
        {showError && error && (
          <Error styled={{ display: 'block' }} margin="10px 0 0" {...errorProps}>
            {error}
          </Error>
        )}
        {showWarning && warning && (
          <Warning styled={{ display: 'block' }} margin="10px 0 0" {...warningProps}>
            {warning}
          </Warning>
        )}
      </Wrapper>
    );
  }
}

TextAreaField.propTypes = {
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['date', 'email', 'number', 'text', 'tel', 'time', 'url', 'password', 'hidden']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  warning: PropTypes.string,
  showWarning: PropTypes.bool,
  error: PropTypes.string,
  optional: PropTypes.bool,
  subtext: PropTypes.string,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  prefix: PropTypes.element,
  suffix: PropTypes.element,
  optionalLabel: PropTypes.string,
  hideLabel: PropTypes.bool,
  forceTouch: PropTypes.bool,
  labelProps: PropTypes.object,
  textAreaProps: PropTypes.object,
  errorProps: PropTypes.object,
  warningProps: PropTypes.object
};

TextAreaField.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  warning: null,
  subtext: null,
  error: null,
  showWarning: true,
  showError: true,
  optional: false,
  disabled: false,
  forceTouch: false,
  min: null,
  max: null,
  prefix: null,
  suffix: null,
  optionalLabel: 'Optional',
  hideLabel: false,
  labelProps: {},
  textAreaProps: {},
  errorProps: {},
  warningProps: {}
};

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;
