import PropTypes from 'prop-types';
import styled from 'styled-components';

// base typography component
import base from '../base';

// element
const P = styled('p')`
  ${base}

  em,
  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  a {
    font-size: 1em;
    color: currentColor;
    transition: color 0.3s;
    font-weight: 600;

    &:hover {
      transition: color 0.3s;
      color: #8f8f8f;
    }
  }
  pre {
    font-family: 'Courier 10 Pitch', Courier, monospace;
    font-size: 95%;
    line-height: 140%;
    white-space: pre;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
  }

  code {
    font-family: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
    font-size: 95%;
    line-height: 140%;
    white-space: pre;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    background: #faf8f0;
  }
`;

// default props for element
P.defaultProps = {
  minFont: 14,
  maxFont: 17,
  minLine: 17,
  maxLine: 29
};

P.propTypes = {
  minFont: PropTypes.number,
  maxFont: PropTypes.number,
  minLine: PropTypes.number,
  maxLine: PropTypes.number
};

// DisplayName for new element
P.displayName = 'P';

// export element
export default P;
