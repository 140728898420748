import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function useEodCount(countryArray, countryCode) {
  const [eodCount, setEodCount] = useState(false);

  useEffect(() => {
    // See if our country code is in the settings array.
    // Using to upper case to ensure consistency
    if (countryArray.indexOf(countryCode.toUpperCase()) === -1) {
      setEodCount(false);
    } else {
      setEodCount(true);
    }
  }, [countryCode]);

  return eodCount;
}

useEodCount.propTypes = {
  countryArray: PropTypes.array.isRequired,
  countryCode: PropTypes.string.isRequired
};

export default useEodCount;
