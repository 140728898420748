import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';

import styled from 'styled-components';

import Button from '../../atoms/button';
import Link from '../../atoms/link';
import Loader from '../../atoms/loader';
import SrOnly from '../../atoms/typography/srOnly';

const FancyIcon = styled.div`
  position: relative;
  ${({ position, iconOffset }) =>
    position === 'before'
      ? `
    margin-right: ${iconOffset};
  `
      : `
    margin-left: ${iconOffset};
  `}
  line-height: 0;

  ${({ circle, theme }) =>
    circle
      ? `
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border: 1px solid ${theme};
      height: 3.6em;
      width: 3.6em;
      border-radius: 100%;
    }
  `
      : ''}
`;

const isWhiteTheme = (theme) =>
  ['default', 'default-solid', 'inverse-clear', 'inverse-floating', 'error', 'warning', 'success'].includes(theme);

class CTA extends React.PureComponent {
  renderContent = () => {
    const { iconSize, iconOffset, iconCircle, children, iconBefore, iconAfter, showContent, theme } = this.props;

    return (
      <>
        {iconBefore !== '' && (
          <FancyIcon
            position="before"
            circle={iconCircle}
            iconOffset={iconOffset}
            theme={isWhiteTheme(theme) ? 'white' : 'black'}
          >
            <Icon icon={iconBefore} size={iconSize} styles={{ pointerEvents: 'none' }} />
          </FancyIcon>
        )}
        {/* For some reason passing children outside of another element crashes IE */}
        {showContent && <>{children}</>}
        {!showContent && <SrOnly>{children}</SrOnly>}
        {iconAfter !== '' && (
          <FancyIcon
            position="after"
            circle={iconCircle}
            iconOffset={iconOffset}
            theme={isWhiteTheme(theme) ? 'white' : 'black'}
          >
            <Icon icon={iconAfter} size="1.4em" styles={{ pointerEvents: 'none' }} />
          </FancyIcon>
        )}
      </>
    );
  };

  renderLink = ({
    iconCircle,
    iconSize,
    style,
    loading,
    children,
    showContent,
    iconOffset,
    iconBefore,
    iconAfter,
    label,
    ...props
  }) => {
    const { theme } = this.props;
    const styles = {
      ...(loading ? { color: 'transparent' } : {}),
      ...style
    };

    return (
      <Link {...props} style={styles}>
        {this.renderContent()}
        {loading && <Loader position="absolute" theme={isWhiteTheme(theme) ? 'white' : 'black'} />}
      </Link>
    );
  };

  renderStandard = ({ iconCircle, style, loading, children, showContent, iconBefore, iconAfter, label, ...props }) => {
    const { type, theme } = this.props;
    const Element = type === 'submit' || type === 'button' ? Button : Button.withComponent(type);
    const styles = {
      ...(loading ? { color: 'transparent' } : {}),
      ...style
    };

    return (
      <Element {...props} style={styles} type={type === 'submit' ? type : ''}>
        {this.renderContent()}
        {loading && <Loader position="absolute" theme={isWhiteTheme(theme) ? 'white' : 'black'} />}
      </Element>
    );
  };

  render() {
    const { type } = this.props;

    if (type === 'link' || type === 'Link') return this.renderLink(this.props);
    return this.renderStandard(this.props);
  }
}

// Default props
CTA.defaultProps = {
  type: 'button',
  children: null,
  iconBefore: '',
  iconAfter: '',
  showContent: true,
  label: '',
  loading: false,
  iconCircle: false,
  iconSize: '1.4em',
  iconOffset: '1.8em',
  theme: 'default',
  style: null
};

// Prop type check
CTA.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'default-clear',
    'default-floating',
    'inverse',
    'inverse-clear',
    'inverse-floating',
    'error',
    'warning',
    'success'
  ]),
  type: PropTypes.oneOf(['button', 'a', 'Link', 'submit']),
  children: PropTypes.any,
  showContent: PropTypes.bool,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  label: PropTypes.string,
  iconCircle: PropTypes.bool,
  loading: PropTypes.bool,
  iconSize: PropTypes.string,
  iconOffset: PropTypes.string,
  style: PropTypes.object
};

CTA.displayName = 'CTA';

export default CTA;
