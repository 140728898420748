import React, { Component } from 'react';
import PropTypes from 'prop-types';

// =================== //
// TABLE ROW COMPONENT //
// =================== //
export default class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: null,
      styles: { minHeight: 0 }
    };
  }

  componentDidUpdate() {
    const { mobile, actions, columns } = this.props;
    const { ref, styles } = this.state;

    if (ref !== null) {
      const height = ref.clientHeight / columns.length - 1;

      if (height !== styles.minHeight && mobile && actions !== null) {
        this.setState(() => ({ styles: { minHeight: height } }));
      }
    }
  }

  handleRowHover(e, type) {
    const { hoverRow, index } = this.props;

    if (typeof hoverRow === 'function') {
      hoverRow({ target: this.tableRow, event: e, type, index });
    }
  }

  handleRowClick(e) {
    const { clickRow, index } = this.props;
    e.preventDefault();

    if (typeof clickRow === 'function') {
      clickRow({ target: this.tableRow, event: e, index });
    }
  }

  handleCellHover(e, columnIndex, type) {
    const { hoverCell, index } = this.props;

    if (typeof hoverCell === 'function') {
      hoverCell({
        target: this.tableCell,
        event: e,
        type,
        index: { row: index, column: columnIndex }
      });
    }
  }

  handleCellClick(e, columnIndex) {
    const { clickCell, index } = this.props;
    e.preventDefault();

    if (typeof clickCell === 'function') {
      clickCell({ target: this.tableCell, event: e, index: { row: index, column: columnIndex } });
    }
  }

  handleActionsRef(ref) {
    this.setState(() => ({ ref }));
  }

  renderActions(row) {
    const { actions, data, mobile } = this.props;

    const styles =
      mobile === false
        ? {
            flexBasis: actions.flexBasis ? actions.flexBasis : 0,
            flexGrow: actions.flexBasis ? actions.flexBasis : 0,
            minWidth: actions.minWidth
          }
        : {};

    let cell = '';

    if (typeof actions.cell === 'string' || typeof actions.cell === 'number') {
      cell = data[actions.cell];
    } else if (typeof actions.cell === 'object') {
      cell = React.cloneElement(actions.cell, {
        key: row.length - 1,
        index: row.length - 1,
        data,
        actions,
        mobile,
        onGetRef: (ref) => this.handleActionsRef(ref)
      });
    }

    return (
      <div
        ref={(cell) => {
          this.tableCell = cell;
        }}
        onMouseEnter={(e) => this.handleCellHover(e, row.length - 1, 'enter')}
        onMouseLeave={(e) => this.handleCellHover(e, row.length - 1, 'leave')}
        onClick={(e) => this.handleCellClick(e, row.length - 1)}
        style={styles}
        key="action"
        className={`ui-table__td ${mobile ? 'ui-table__td--is-mobile' : ''} `}
      >
        {mobile && <span className="ui-table__td__title">{actions.head}</span>}
        {cell}
      </div>
    );
  }

  renderRow() {
    const { actions, data, columns, mobile, index } = this.props;

    const row = columns.map((column, i) => {
      const styles =
        mobile === false
          ? {
              flexBasis: column.flexBasis ? column.flexBasis : `${100 / columns.length}%`,
              flexGrow: column.flexBasis == 0 ? 0 : 1,
              minWidth: column.minWidth
            }
          : {};

      let cell = '';

      if (typeof column.cell === 'string' || typeof column.cell === 'number') {
        cell = data[column.cell];
      } else if (typeof column.cell === 'object') {
        cell = React.cloneElement(column.cell, {
          key: i,
          index: i,
          rowIndex: index,
          data,
          column,
          mobile
        });
      }

      return (
        <div
          ref={(cell) => {
            this.tableCell = cell;
          }}
          onMouseEnter={(e) => this.handleCellHover(e, i, 'enter')}
          onMouseLeave={(e) => this.handleCellHover(e, i, 'leave')}
          onClick={(e) => this.handleCellClick(e, i)}
          style={{ ...styles, ...this.state.styles }}
          key={i}
          className={`ui-table__td ${mobile ? 'ui-table__td--is-mobile' : ''} `}
        >
          {mobile && <span className="ui-table__td__title">{column.head}</span>}
          {cell}
        </div>
      );
    });

    if (actions !== null) {
      row.push(this.renderActions(row));
    }

    return row;
  }

  render() {
    const { mobile } = this.props;

    return (
      <div
        ref={(row) => {
          this.tableRow = row;
        }}
        onMouseEnter={(e) => this.handleRowHover(e, 'enter')}
        onMouseLeave={(e) => this.handleRowHover(e, 'leave')}
        onClick={(e) => this.handleRowClick(e)}
        className={`ui-table__tr ${mobile ? 'ui-table__tr--is-mobile' : ''} `}
      >
        {this.renderRow()}
      </div>
    );
  }
}

TableRow.defaultProps = {
  actions: null,
  clickCell: null,
  hoverCell: null,
  clickRow: null,
  hoverRow: null,
  clickColumn: null,
  hoverColumn: null
};

TableRow.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  columns: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  // Custom columns
  actions: PropTypes.object,
  // Functions
  clickCell: PropTypes.func,
  hoverCell: PropTypes.func,
  clickRow: PropTypes.func,
  hoverRow: PropTypes.func,
  clickColumn: PropTypes.func,
  hoverColumn: PropTypes.func
};
