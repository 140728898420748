import styled from 'styled-components';
import PropTypes from 'prop-types';

import { calculatePadding } from '../../../helpers/layoutHelpers';

const Grid = styled.div`
  display: grid;
  grid-gap: ${({ gap }) => gap};
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  grid-template-rows: ${({ templateRows }) => templateRows};
  justify-items: ${({ justifyItems }) => justifyItems};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-content: ${({ alignContent }) => alignContent};
  margin: 0 auto;
  width: 100%;

  ${({ padding }) => {
    if (typeof padding === 'object') return calculatePadding(padding);
    if (typeof padding === 'string') return `padding: ${padding};`;
    return null;
  }};
`;

Grid.defaultProps = {
  templateColumns: 'repeat(12, 1fr)',
  templateRows: 'auto',
  gap: '10px',
  padding: '0',
  justifyItems: 'normal',
  alignItems: 'normal',
  justifyContent: 'normal',
  alignContent: 'normal'
};

Grid.propTypes = {
  templateColumns: PropTypes.string,
  templateRows: PropTypes.string,
  gap: PropTypes.string,

  justifyItems: PropTypes.oneOf([
    'auto',
    'normal',
    'stretch',
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'self-start',
    'self-end',
    'left',
    'right',
    'baseline',
    'first baseline',
    'last baseline'
  ]),

  alignItems: PropTypes.oneOf([
    'auto',
    'normal',
    'start',
    'end',
    'center',
    'stretch',
    'baseline',
    'first baseline',
    'last baseline'
  ]),

  justifyContent: PropTypes.oneOf([
    'normal',
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
    'baseline',
    'first baseline',
    'last baseline'
  ]),

  alignContent: PropTypes.oneOf([
    'normal',
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
    'baseline',
    'first baseline',
    'last baseline'
  ]),

  /**
   * An array of up to two values to define padding
   * 1 value = [All sides]
   * 2 values = [Vertical, Horizontal]
   * OR
   * Pass in a string
   */
  padding: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOf(['none', 'sm', 'md', 'lg'])), PropTypes.string])
};

Grid.displayName = 'Grid';

export default Grid;
