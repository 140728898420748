import React from 'react';

const renderGraphic = ({ icon, fill, callback }) => {
  switch (icon) {
    case 'alert-circle': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm-1.7 6.4h3.3v4.3l-.8 6.9h-1.6l-.9-6.9zm3.5 16.1h-3.5V19h3.5z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'article': {
      return callback({
        graphic: <path fill={fill} d="M20,26V30H0V26H20z M32,10v4H0v-4H32z M0,22v-4h32v4H0z M0,2h32V6H0V2z" />,
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'audio': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M12,20.2c-1.4,0-2.6-0.5-3.6-1.5s-1.5-2.2-1.5-3.6v-10c0-1.4,0.5-2.6,1.5-3.6S10.6,0,12,0s2.6,0.5,3.6,1.5S17,3.6,17,5.1 v10.1c0,1.4-0.5,2.6-1.5,3.6S13.4,20.2,12,20.2z M20.9,15.2h2.9c0,2.8-1,5.3-3,7.5s-4.4,3.4-7.2,3.8V32h-3.4v-5.5 C7.4,26.1,5,24.8,3,22.6S0,18,0,15.2h3c0,2.4,0.9,4.5,2.7,6.1s3.9,2.4,6.3,2.4s4.5-0.8,6.2-2.4C20,19.6,20.9,17.6,20.9,15.2z"
          />
        ),
        viewBox: '0 0 23.8 32',
        ratio: 23.8 / 32
      });
    }
    case 'brightcove': {
      return callback({
        graphic: (
          <>
            <path
              fill={fill}
              d="M23.8,24.3c1,0,1.9,0,2.9,0c1,0,1.7,0.4,2.1,1.3c1,1.7,1.9,3.4,3,5.1c0.4,0.7,0.1,1.3-0.7,1.3c-1.9,0-3.8,0-5.8,0 c-1,0-1.7-0.4-2.2-1.3c-1-1.6-1.9-3.3-2.9-5c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.6,0-1.1,0.6-1.1C21.7,24.3,22.8,24.3,23.8,24.3 L23.8,24.3z"
            />
            <path
              fill={fill}
              d="M28.1,15.8c-1,0-1.9,0-2.9,0c-1,0-1.7,0.4-2.1,1.3c-1,1.7-1.9,3.4-3,5.1c-0.4,0.7-0.1,1.3,0.7,1.3c1.9,0,3.8,0,5.8,0 c1,0,1.7-0.4,2.2-1.3c1-1.6,1.9-3.3,2.9-5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.6,0-1.1-0.6-1.1C30.2,15.8,29.1,15.8,28.1,15.8L28.1,15.8z "
            />
            <path
              fill={fill}
              d="M18.4,15.7c-1,0-1.9,0-2.9,0s-1.7,0.4-2.1,1.3c-1,1.7-1.9,3.4-3,5.1c-0.4,0.7-0.1,1.3,0.7,1.3c1.9,0,3.8,0,5.8,0 c1,0,1.7-0.4,2.2-1.3c1-1.6,1.9-3.3,2.9-5c0-0.1,0.1-0.1,0.1-0.2c0.3-0.6,0-1.1-0.6-1.1C20.4,15.7,19.5,15.7,18.4,15.7L18.4,15.7z "
            />
            <path
              fill={fill}
              d="M14.1,7.2c1,0,1.9,0,2.9,0s1.7,0.4,2.1,1.3c1,1.7,1.9,3.4,3,5.1c0.4,0.7,0.1,1.3-0.7,1.3c-1.9,0-3.8,0-5.8,0 c-1,0-1.7-0.4-2.2-1.3c-1-1.6-1.9-3.3-2.9-5c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.6,0-1.1,0.6-1.1C12.1,7.2,13.1,7.2,14.1,7.2L14.1,7.2z"
            />
            <path
              fill={fill}
              d="M6.1,3.1c0.4,0.9,1,1.6,1.4,2.5c0.4,0.9,0.5,1.7,0,2.6c-1,1.7-1.9,3.4-2.9,5.1c-0.4,0.7-1,0.7-1.4,0c-1-1.7-1.9-3.3-2.9-5 c-0.4-0.9-0.5-1.7,0-2.6c0.9-1.7,1.8-3.4,2.8-5c0-0.1,0.1-0.1,0.1-0.2c0.4-0.5,1-0.5,1.3,0C5.1,1.3,5.6,2.2,6.1,3.1L6.1,3.1z"
            />
            <path
              fill={fill}
              d="M11,11.6c0.4,0.9,1,1.6,1.4,2.5c0.4,0.9,0.5,1.7,0,2.6c-1,1.7-1.9,3.4-2.9,5.1c-0.4,0.7-1,0.7-1.4,0c-1-1.7-1.9-3.3-2.9-5 c-0.4-0.9-0.5-1.7,0-2.6c1-1.7,1.9-3.3,2.9-5C8.2,9,8.3,9,8.3,8.9c0.4-0.5,1-0.5,1.3,0C10.1,9.8,10.5,10.8,11,11.6L11,11.6z"
            />
          </>
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'card': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M3.2,0C2.3,0,1.6,0.3,1,0.9S0,2.3,0,3.2v19.3c0,0.9,0.3,1.7,0.9,2.3s1.4,0.9,2.3,0.9h25.6c0.9,0,1.7-0.3,2.3-0.9 s0.9-1.4,0.9-2.3V3.2c0-0.9-0.3-1.7-0.9-2.3S29.7,0,28.8,0C28.8,0,3.2,0,3.2,0z M3.2,22.5v-9.6h25.6v9.6H3.2z M3.2,6.4V3.2h25.6 v3.2H3.2z"
          />
        ),
        viewBox: '0 0 32 25.7',
        ratio: 32 / 25.7
      });
    }
    case 'caret-down': {
      return callback({
        graphic: <path fill={fill} d="M0,0h32L16.1,16L0,0z" />,
        viewBox: '0 0 32 16',
        ratio: 32 / 16
      });
    }
    case 'case': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M28.8,6.4c0.9,0,1.7,0.3,2.2,1c0.6,0.6,1,1.4,1,2.3v17.5c0,0.9-0.3,1.7-1,2.2c-0.6,0.6-1.4,1-2.2,1H3.2 c-0.9,0-1.7-0.3-2.2-1c-0.6-0.5-1-1.4-1-2.2V9.7C0,8.9,0.3,8,1,7.5s1.4-1.1,2.2-1.1h6.4V3.2c0-0.9,0.3-1.6,1-2.2s1.4-1,2.2-1h6.4 c0.9,0,1.7,0.3,2.2,1c0.6,0.6,1,1.4,1,2.2v3.2H28.8z M19.2,6.4V3.2h-6.4v3.2H19.2z"
          />
        ),
        viewBox: '0 0 32 30.4',
        ratio: 32 / 30.4
      });
    }
    case 'check': {
      return callback({
        graphic: <path fill={fill} d="M10.1,19.3L29.5,0L32,2.6L10.1,24.3L0,14.2l2.6-2.6L10.1,19.3z" />,
        viewBox: '0 0 32 24.3',
        ratio: 32 / 24.3
      });
    }
    case 'close': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M32,3.3L19.2,16L32,28.7l-3.2,3.2L15.9,19.2L3.2,31.9L0,28.7L12.7,16L0,3.3l3.2-3.2l12.7,12.7L28.8,0.1L32,3.3z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'close-circle': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M16,0c4.4,0,8.2,1.6,11.3,4.7S32,11.6,32,16s-1.6,8.2-4.7,11.3S20.4,32,16,32s-8.2-1.6-11.3-4.7S0,20.4,0,16 S1.6,7.8,4.7,4.7S11.6,0,16,0z M24,21.8L18.2,16l5.8-5.8L21.7,8L16,13.8L10.3,8L8,10.2l5.7,5.8L8,21.8l2.2,2.2l5.7-5.8l5.8,5.8 L24,21.8z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'collection-item': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="m28.28 25.67a2.61 2.61 0 0 1 -2.61 2.61h-13.39a2.61 2.61 0 0 1 -2.61-2.61v-13.39a2.61 2.61 0 0 1 2.61-2.61h13.39a2.61 2.61 0 0 1 2.61 2.61zm-22.28-13.39v10a2.6 2.6 0 0 1 -2.23-2.57v-13.38a2.61 2.61 0 0 1 2.56-2.61h13.39a2.6 2.6 0 0 1 2.57 2.28h-10a6.34 6.34 0 0 0 -6.29 6.28zm13.72-12.28h-13.39a6.34 6.34 0 0 0 -6.33 6.33v13.39a6.32 6.32 0 0 0 6 6.28 6.32 6.32 0 0 0 6.29 6h13.38a6.34 6.34 0 0 0 6.33-6.33v-13.39a6.32 6.32 0 0 0 -6-6.28 6.32 6.32 0 0 0 -6.28-6z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'delete': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M1.8,28.5V7.1h21.4v21.4c0,1-0.3,1.8-1,2.5c-0.7,0.7-1.5,1-2.5,1H5.4c-1,0-1.8-0.3-2.5-1C2.1,30.4,1.8,29.5,1.8,28.5z M25.1,1.8v3.6H0V1.8h6.2L8,0H17l1.8,1.8H25.1z"
          />
        ),
        viewBox: '0 0 25.1 32',
        ratio: 25.1 / 32
      });
    }
    case 'devices': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M5.3,2.7v14.7h13.3v3.9H0v-4.1h2.7V2.7c0-0.7,0.2-1.4,0.7-1.9S4.6,0,5.3,0h24v2.7H5.3z M30.7,5.3c0.3,0,0.6,0.1,0.9,0.4 s0.4,0.5,0.4,1v13.3c0,0.4-0.1,0.7-0.4,1c-0.3,0.2-0.5,0.3-0.9,0.3h-8c-0.4,0-0.7-0.1-1-0.4c-0.2-0.3-0.4-0.5-0.4-1V6.6 c0-0.4,0.1-0.6,0.4-1c0.3-0.3,0.6-0.4,1-0.4h8V5.3z M29.3,17.4V8H24v9.4H29.3z"
          />
        ),
        viewBox: '0 0 32 21.3',
        ratio: 32 / 21.3
      });
    }
    case 'edit': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M0,25.3L19.7,5.7l6.7,6.7L6.7,32H0V25.3z M31.5,7.2l-3.2,3.3l-6.7-6.7l3.2-3.2C25.2,0.2,25.6,0,26.1,0s0.9,0.2,1.2,0.5 l4.2,4.2C31.8,5,32,5.4,32,5.9S31.8,6.8,31.5,7.2z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'event': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M28.4 3.2c1 0 1.8.3 2.5.9.7.6 1 1.4 1 2.3v22.3c0 .9-.3 1.7-1 2.3-.7.6-1.6 1-2.5.9H3.6c-1 0-1.9-.3-2.5-.9-.7-.6-1.1-1.4-1-2.3V6.4c0-.9.3-1.7 1-2.3.7-.6 1.6-1 2.5-.9h1.7V0h3.6v3.2h14.2V0h3.6v3.2h1.7zm0 25.6V11.2H3.6v17.5h24.8z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'eye': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M16.1,0.1c3.6,0,6.8,1,9.7,3s5,4.7,6.2,7.9c-1.3,3.2-3.4,5.9-6.2,7.9s-6.1,3-9.7,3s-6.8-1-9.8-3S1.3,14.2,0,11 c1.3-3.2,3.4-5.9,6.3-7.9S12.4,0.1,16.1,0.1z M16.1,18.3c2,0,3.7-0.7,5.1-2.1s2.1-3.1,2.1-5.2s-0.7-3.7-2.1-5.2s-3.1-2.1-5.1-2.1 s-3.7,0.7-5.2,2.1S8.7,9,8.7,11s0.7,3.7,2.1,5.2C12.3,17.6,14,18.3,16.1,18.3z M16.1,6.6c1.2,0,2.2,0.4,3.1,1.3 c0.9,0.8,1.3,1.9,1.3,3.1s-0.4,2.3-1.3,3.1c-0.9,0.8-1.9,1.3-3.1,1.3s-2.2-0.4-3.1-1.3c-0.8-0.8-1.3-1.9-1.3-3.1s0.4-2.3,1.3-3.1 C13.8,7.1,14.8,6.6,16.1,6.6z"
          />
        ),
        viewBox: '0 0 32 22',
        ratio: 32 / 22
      });
    }
    case 'format-list-bulleted': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M2.6,10.4c0.7,0,1.3,0.3,1.8,0.8s0.8,1.1,0.8,1.8c0,0.7-0.3,1.3-0.8,1.8s-1.1,0.8-1.8,0.8s-1.3-0.3-1.8-0.8S0,13.7,0,13 c0-0.7,0.3-1.3,0.8-1.8C1.3,10.6,1.9,10.4,2.6,10.4z M2.6,0c0.7,0,1.3,0.3,1.8,0.8s0.8,1.1,0.8,1.8S4.9,3.9,4.4,4.4 C3.9,4.9,3.3,5.2,2.6,5.2S1.3,4.9,0.8,4.4C0.3,3.9,0,3.3,0,2.6s0.3-1.3,0.8-1.8S1.9,0,2.6,0z M2.6,21.1c0.6,0,1.2,0.2,1.6,0.6 s0.6,1,0.6,1.6s-0.2,1.2-0.6,1.6s-1,0.6-1.6,0.6S1.4,25.4,1,25s-0.6-1-0.6-1.6s0.2-1.2,0.6-1.6S1.9,21.1,2.6,21.1z M7.8,25v-3.4H32 V25H7.8z M7.8,14.7v-3.4H32v3.4H7.8z M7.8,0.9H32v3.4H7.8V0.9z"
          />
        ),
        viewBox: '0 0 32 25.5',
        ratio: 32 / 25.5
      });
    }
    case 'fullscreen': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M43 299v64h64v42H0V299h43zM0 213V107h107v42H43v64H0zm256 150v-64h43v106H192v-42h64zm-64-256h107v106h-43v-64h-64v-42z"
          />
        ),
        viewBox: '0 0 299 512',
        ratio: 299 / 512
      });
    }
    case 'globe': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M213.5 43q88.5 0 151 62.5T427 256t-62.5 150.5-151 62.5-151-62.5T0 256t62.5-150.5 151-62.5zM192 425v-41q-18 0-30.5-12.5T149 341v-21L47 218q-4 20-4 38 0 65 42.5 113T192 425zm147-54q45-49 45-115 0-53-29.5-96T277 98v9q0 17-12.5 29.5T235 149h-43v43q0 9-6.5 15t-14.5 6h-43v43h128q9 0 15 6.5t6 14.5v64h22q14 0 25 8.5t15 21.5z"
          />
        ),
        viewBox: '0 0 427 512',
        ratio: 427 / 512
      });
    }
    case 'grid': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M0,8V0h8v8H0z M12,32v-8h8v8H12z M0,32v-8h8v8H0z M0,20v-8h8v8H0z M12,20v-8h8v8H12z M24,0h8v8h-8V0z M12,8V0h8v8H12zM24,20v-8h8v8H24z M24,32v-8h8v8H24z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'hourglass-alt': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M0,0h19.2v9.6L12.8,16l6.4,6.4V32H0v-9.6L6.4,16L0,9.6V0z M16,23.2l-6.4-6.4l-6.4,6.4C3.2,23.2,16,23.2,16,23.2z M3.2,8.8 H16V3.2H3.2V8.8z"
          />
        ),
        viewBox: '0 0 19.2 32',
        ratio: 19.2 / 32
      });
    }
    case 'lock': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M21.4,10.7c0.8,0,1.5,0.3,2.1,0.9c0.6,0.6,0.9,1.3,0.9,2.1V29c0,0.8-0.3,1.5-0.9,2.1S22.2,32,21.4,32H3.1 c-0.9,0-1.6-0.3-2.2-0.9S0,29.8,0,29V13.7c0-0.8,0.3-1.5,0.9-2.1s1.3-0.9,2.2-0.9h1.5V7.6c0-2.1,0.8-3.9,2.2-5.4S10.1,0,12.2,0 s3.9,0.8,5.4,2.2s2.2,3.3,2.2,5.4v3.1C19.8,10.7,21.4,10.7,21.4,10.7z M12.2,24.4c0.8,0,1.5-0.3,2.1-0.9s0.9-1.3,0.9-2.2 c0-0.9-0.3-1.6-0.9-2.2s-1.3-0.9-2.1-0.9s-1.5,0.3-2.1,0.9s-0.9,1.3-0.9,2.2c0,0.9,0.3,1.6,0.9,2.2S11.4,24.4,12.2,24.4z M17,10.7 V7.6c0-1.3-0.5-2.4-1.4-3.3s-2-1.4-3.4-1.4S9.8,3.4,8.9,4.3C8,5.2,7.5,6.3,7.5,7.6v3.1C7.5,10.7,17,10.7,17,10.7z"
          />
        ),
        viewBox: '0 0 24.4 32',
        ratio: 24.4 / 32
      });
    }
    case 'long-arrow-left': {
      return callback({
        graphic: <path fill={fill} d="M32,9v3.5H6.8l6.3,6.4l-2.5,2.5L0,10.7L10.7,0l2.5,2.5L6.8,8.9H32V9z" />,
        viewBox: '0 0 32 21.3',
        ratio: 32 / 21.3
      });
    }
    case 'long-arrow-right': {
      return callback({
        graphic: <path fill={fill} d="M0,9h25.2l-6.3-6.4L21.3,0L32,10.7L21.3,21.3l-2.5-2.5l6.3-6.4H0V9z" />,
        viewBox: '0 0 32 21.3',
        ratio: 32 / 21.3
      });
    }
    case 'lush': {
      return callback({
        graphic: (
          <>
            <polygon fill={fill} points="0,0.2 0,8.4 6.1,8.4 6.1,6.3 2.5,6.3 2.5,0.2 	" />
            <path
              fill={fill}
              d="M14.6,5.2c0,2.3-1.2,3.3-3.7,3.3S7.2,7.4,7.2,5.2v-5h2.5v4.4c0,0.8,0,1.9,1.2,1.9s1.2-1,1.2-1.9V0.2h2.5L14.6,5.2L14.6,5.2 z"
            />
            <path
              fill={fill}
              d="M18.4,5.6c0,0.2,0,0.4,0.1,0.5c0.2,0.5,0.7,0.6,1.2,0.6c0.4,0,1-0.1,1-0.7c0-0.4-0.4-0.5-1.8-0.9c-1.3-0.4-2.8-0.7-2.8-2.4 c0-1.9,1.6-2.7,3.3-2.7c1.8,0,3.3,0.7,3.4,2.7h-2.3c0-0.3-0.1-0.5-0.3-0.6C20,2,19.7,1.9,19.5,1.9c-0.3,0-0.9,0.1-0.9,0.6 c0,0.6,1.2,0.7,2.4,1s2.3,0.9,2.3,2.3c0,2.1-2,2.8-3.8,2.8c-0.9,0-3.6-0.3-3.6-2.9L18.4,5.6L18.4,5.6z"
            />
            <polygon
              fill={fill}
              points="29.5,0.2 29.5,3.1 27.1,3.1 27.1,0.2 24.6,0.2 24.6,8.4 27.1,8.4 27.1,5.1 29.5,5.1 29.5,8.4 32,8.4 32,0.2 	"
            />
          </>
        ),
        viewBox: '0 0 32 8.6',
        ratio: 32 / 8.6
      });
    }
    case 'lush-labs': {
      return callback({
        graphic: (
          <>
            <polygon fill={fill} points="18.2,5.4 19.3,0.5 20.5,0.5 19.6,4.5 21,4.5 20.8,5.4 " />
            <path
              fill={fill}
              d="M23.7,1.4L23.7,1.4l-0.8,2.2h0.7L23.7,1.4z M21.1,5.4l2.1-4.9h1.4l0,4.9h-1.2l0-1h-0.9l-0.3,1L21.1,5.4z"
            />
            <path
              fill={fill}
              d="M26.6,2.5c0.5,0,0.7,0,0.9-0.6c0.1-0.5-0.2-0.5-0.6-0.5L26.6,2.5z M26.2,4.6c0.6,0,0.8,0,1-0.6c0.1-0.6-0.2-0.6-0.7-0.6 L26.2,4.6z M24.9,5.4l1-4.9h1.5c0.9,0,1.5,0.3,1.3,1.3c-0.1,0.4-0.3,0.8-0.8,1v0c0.5,0.3,0.5,0.6,0.4,1.2c-0.2,1-0.9,1.4-1.8,1.4 L24.9,5.4z"
            />
            <path
              fill={fill}
              d="M29.6,3.9l-0.1,0.3c-0.1,0.3,0,0.5,0.3,0.5c0.3,0,0.4-0.2,0.5-0.5c0.1-0.4-0.2-0.6-0.5-0.8c-0.3-0.2-0.6-0.4-0.7-0.6 c-0.2-0.2-0.2-0.6-0.1-1c0.2-0.9,0.9-1.4,1.7-1.4c1,0,1.4,0.7,1.1,1.6h-1.1c0-0.3,0.2-0.7-0.2-0.7c-0.2,0-0.4,0.1-0.5,0.3 c-0.1,0.3,0,0.4,0.2,0.6c0.4,0.3,0.7,0.4,0.9,0.7c0.2,0.2,0.3,0.6,0.2,1.2c-0.2,0.9-0.8,1.5-1.8,1.5c-1,0-1.4-0.6-1.2-1.2l0.1-0.5 L29.6,3.9z"
            />
            <polygon fill={fill} points="5,5.4 6.1,0.5 7.2,0.5 6.4,4.5 7.8,4.5 7.6,5.4 " />
            <path
              fill={fill}
              d="M8.9,0.5h1.1L9.3,4.2c-0.1,0.2,0,0.4,0.3,0.4c0.3,0,0.4-0.3,0.5-0.5l0.8-3.7H12l-0.8,3.5c-0.2,0.8-0.7,1.5-1.9,1.5 c-1,0-1.4-0.5-1.2-1.5L8.9,0.5z"
            />
            <path
              fill={fill}
              d="M12.7,3.8l-0.1,0.3c-0.1,0.3,0,0.5,0.3,0.5c0.3,0,0.4-0.2,0.5-0.5c0.1-0.4-0.2-0.6-0.5-0.8c-0.3-0.2-0.6-0.4-0.7-0.6 c-0.2-0.2-0.2-0.6-0.1-1c0.2-0.9,0.9-1.4,1.7-1.4c1,0,1.4,0.7,1.1,1.6H14c0-0.3,0.2-0.7-0.2-0.7c-0.2,0-0.4,0.1-0.5,0.3 c-0.1,0.3,0,0.4,0.2,0.6c0.4,0.3,0.7,0.4,0.9,0.7c0.2,0.2,0.3,0.6,0.2,1.2c-0.2,1-0.8,1.5-1.8,1.5c-1,0-1.4-0.6-1.2-1.2l0.1-0.5 L12.7,3.8z"
            />
            <polygon
              fill={fill}
              points="14.6,5.4 15.7,0.5 16.8,0.5 16.4,2.4 17.2,2.4 17.6,0.5 18.8,0.5 17.7,5.4 16.6,5.4 17,3.4 16.2,3.4 15.8,5.4 "
            />
            <path
              fill={fill}
              d="M3.2,2.6L3,3.4H2.2l0.2-0.8h0.5L3.2,2.6z M5.2,2.6l0.2-1H4.5l0.2-1H3.6L3.4,1.6H2.6l0.2-1H1.7l-0.2,1H0.6l-0.2,1h0.8L1,3.4 H0.2L0,4.4h0.8l-0.2,1h1.1l0.2-1l0-0.1h0.5h0.3l-0.2,1h1.1l0.2-1l0-0.1h0.8l0.2-1H4.2l0.2-0.8L5.2,2.6z"
            />
          </>
        ),
        viewBox: '0 0 32 6',
        ratio: 32 / 6
      });
    }
    case 'menu': {
      return callback({
        graphic: <path fill={fill} d="M0,21.4v-3.6h32v3.6H0z M0,12.5V8.9h32v3.6H0z M0,0h32v3.6H0V0z" />,
        viewBox: '0 0 32 21.4',
        ratio: 32 / 21.4
      });
    }
    case 'minus': {
      return callback({
        graphic: <path fill={fill} d="M32,4.5H0V0h32V4.5z" />,
        viewBox: '0 0 32 4.5',
        ratio: 32 / 4.5
      });
    }
    case 'more': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M4,0c1.1,0,2,0.4,2.8,1.2S8,2.9,8,4S7.6,6.1,6.8,6.9C6,7.6,5.1,8,4,8S2,7.6,1.2,6.8C0.4,6.1,0,5.1,0,4s0.4-2.1,1.2-2.9 S2.9,0,4,0z M28,0c1.1,0,2,0.4,2.8,1.2S32,2.9,32,4.1S31.6,6.2,30.8,7C30,7.6,29.1,8,28,8s-2-0.4-2.8-1.2C24.4,6.1,24,5.1,24,4 s0.4-2.1,1.2-2.9C26,0.4,26.9,0,28,0z M16,0c1.1,0,2,0.4,2.8,1.2S20,2.9,20,4.1S19.6,6.2,18.8,7C18,7.6,17.1,8,16,8s-2-0.4-2.8-1.2 C12.4,6.1,12,5.1,12,4s0.4-2.1,1.2-2.9S14.9,0,16,0z"
          />
        ),
        viewBox: '0 0 32 8',
        ratio: 32 / 8
      });
    }
    case 'more-vert': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M4,8C2.9,8,2,7.6,1.2,6.8S0,5.1,0,4s0.4-2.1,1.2-2.9S2.9,0,4,0s2,0.4,2.8,1.2C7.6,1.9,8,2.9,8,4S7.6,6.1,6.8,6.9 C6,7.7,5.1,8,4,8z M4,12c1.1,0,2,0.4,2.8,1.2S8,14.9,8,16.1S7.6,18.2,6.8,19C6,19.6,5.1,20,4,20s-2-0.4-2.8-1.2S0,17.1,0,16 s0.4-2.1,1.2-2.9C2,12.4,2.9,12,4,12z M4,24c1.1,0,2,0.4,2.8,1.2C7.6,26,8,26.9,8,28.1S7.6,30.2,6.8,31C6,31.6,5.1,32,4,32 s-2-0.4-2.8-1.2S0,29.1,0,28s0.4-2.1,1.2-2.9S2.9,24,4,24z"
          />
        ),
        viewBox: '0 0 8 32',
        ratio: 8 / 32
      });
    }
    case 'plus': {
      return callback({
        graphic: <path fill={fill} d="M32,18.2H18.3v13.7h-4.6V18.2H0v-4.5h13.7V0.1h4.6v13.7H32V18.2z" />,
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'profile': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M16,16c-2.2,0-4.1-0.8-5.7-2.3S8,10.2,8,8s0.8-4.1,2.3-5.7S13.8,0,16,0s4.1,0.8,5.7,2.3C23.2,3.9,24,5.8,24,8 s-0.8,4.1-2.3,5.7C20.1,15.2,18.2,16,16,16z M16,20c2,0,4.1,0.3,6.5,0.8s4.5,1.5,6.5,2.8S32,26.4,32,28v4H0v-4c0-1.6,1-3.1,2.9-4.4 s4.1-2.2,6.5-2.8S14,20,16,20z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'recipt': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M24,24v-3.2H4.8V24H24z M24,17.6v-3.2H4.8v3.2H24z M24,11.2V8H4.8v3.2H24z M0,32V0l2.5,2.5L4.8,0l2.5,2.5L9.6,0l2.5,2.5 L14.4,0l2.5,2.5L19.2,0l2.5,2.5L24,0l2.5,2.5L28.8,0v32l-2.5-2.5L24,32l-2.5-2.5L19.2,32l-2.5-2.5L14.4,32l-2.5-2.5L9.6,32 l-2.5-2.5L4.8,32l-2.5-2.5L0,32z"
          />
        ),
        viewBox: '0 0 28.8 32',
        ratio: 28.8 / 32
      });
    }
    case 'search': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M22.9,20.2l9.1,9.1L29.3,32l-9.1-9.1v-1.5l-0.5-0.5c-2.2,1.9-4.8,2.8-7.7,2.8c-3.3,0-6.1-1.2-8.5-3.5S0,15.2,0,11.9 s1.2-6.1,3.5-8.4S8.6,0,11.9,0s6.1,1.2,8.4,3.5s3.5,5.1,3.5,8.5c0,2.9-0.9,5.5-2.8,7.7l0.5,0.5H22.9z M11.9,20.2 c2.3,0,4.2-0.8,5.8-2.4c1.6-1.6,2.4-3.5,2.4-5.8s-0.8-4.2-2.4-5.8c-1.6-1.6-3.5-2.4-5.8-2.4S7.7,4.5,6.1,6.1s-2.4,3.5-2.4,5.8 s0.8,4.2,2.4,5.8S9.6,20.2,11.9,20.2z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'settings': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M27.4,17.6l3.4,2.7c0.3,0.3,0.4,0.6,0.2,1.1l-3.2,5.5c-0.2,0.4-0.5,0.5-1,0.3l-3.9-1.6c-0.9,0.6-1.8,1.2-2.7,1.6l-0.6,4.2 c0,0.4-0.3,0.6-0.9,0.6h-6.4c-0.4,0-0.7-0.2-0.9-0.6l-0.6-4.2c-1-0.4-1.8-1-2.7-1.6l-3.9,1.6c-0.4,0.1-0.7,0-1-0.3l-3.2-5.5 c-0.2-0.4-0.1-0.7,0.2-1.1l3.4-2.7c0-0.6-0.1-1.2-0.1-1.6s0-1,0.1-1.6l-3.4-2.7c-0.3-0.3-0.4-0.6-0.2-1.1l3.2-5.5 c0.1-0.3,0.4-0.4,0.9-0.2l3.9,1.6c1-0.7,1.8-1.2,2.8-1.6l0.6-4.2c0-0.5,0.3-0.7,0.7-0.7h6.4c0.5,0,0.7,0.2,0.9,0.6l0.6,4.2 c1,0.4,1.8,1,2.7,1.6l3.9-1.6c0.4-0.1,0.7,0,1,0.3l3.2,5.5c0.2,0.4,0.1,0.7-0.2,1.1l-3.4,2.7c0.1,0.6,0.1,1.2,0.1,1.6 S27.5,17,27.4,17.6z M15.5,21.7c1.5,0,2.9-0.5,3.9-1.6c1.1-1.1,1.6-2.5,1.6-3.9s-0.5-2.9-1.6-3.9c-1.1-1.1-2.5-1.8-3.9-1.8 s-2.9,0.5-3.9,1.6S9.9,14.4,9.9,16s0.5,2.9,1.6,3.9C12.6,21.1,14,21.7,15.5,21.7z"
          />
        ),
        viewBox: '0 0 31.2 32',
        ratio: 31.2 / 32
      });
    }
    case 'smartphone-android': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M16,0c1.2,0,2.2,0.4,3.1,1.3c0.7,0.9,1.2,1.9,1.2,3.1v23.3c0,1.2-0.4,2.2-1.3,3.1c-0.9,0.9-1.9,1.3-3.1,1.3H4.3 c-1.2,0-2.2-0.4-3.1-1.3S0,28.8,0,27.6V4.4c0-1.2,0.4-2.2,1.2-3.1S3.1,0,4.3,0H16z M13,29.1v-1.4H7.3v1.4H13z M17.7,24.7V4.4H2.5 v20.4H17.7z"
          />
        ),
        viewBox: '0 0 20.3 32',
        ratio: 20.3 / 32
      });
    }
    case 'store': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M30.3,0v3.6H1.7V0H30.3z M32,17.9h-1.7v10.7h-3.6V17.9h-7v10.7H1.7V17.9H0v-3.6l1.7-9h28.5l1.8,9V17.9z M16,25v-7H5.3v7H16 z"
          />
        ),
        viewBox: '0 0 32 28.6',
        ratio: 32 / 28.6
      });
    }
    case 'swap': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M7.1,10.6v5.3h12.5v3.5H7.1v5.3l-7.1-7L7.1,10.6z M32,7.1l-7.1,7.1V8.9H12.4V5.3h12.5V0L32,7.1z"
          />
        ),
        viewBox: '0 0 32 24.7',
        ratio: 32 / 24.7
      });
    }
    case 'tag': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M29.3,0c0.8,0,1.4,0.3,1.9,0.8S32,1.9,32,2.7v18.6c0,0.8-0.3,1.4-0.8,1.9S30.1,24,29.3,24h-20c-0.9,0-1.6-0.4-2.1-1.2L0,12 L7.2,1.2C7.7,0.4,8.4,0,9.3,0H29.3z"
          />
        ),
        viewBox: '0 0 32 24',
        ratio: 32 / 24
      });
    }
    case 'time': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M16,0c4.4,0,8.2,1.6,11.3,4.7S32,11.6,32,16s-1.6,8.2-4.7,11.3S20.4,32,16,32s-8.2-1.6-11.3-4.7S0,20.4,0,16 S1.6,7.8,4.7,4.7S11.6,0,16,0z M16,28.8c3.5,0,6.5-1.2,9-3.7s3.7-5.5,3.7-9.1S27.5,9.4,25,6.9s-5.5-3.7-9-3.7S9.5,4.4,7,6.9 S3.2,12.5,3.2,16s1.2,6.6,3.7,9.1S12.5,28.8,16,28.8z M16.8,8v8.4l7.2,4.3l-1.2,2l-8.4-5.1V8H16.8z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'trending-up': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M22.4,0H32v9.6l-3.7-3.7l-10.1,10l-6.4-6.3l-9.6,9.6L0,16.9L11.8,5.1l6.4,6.4l7.9-7.8L22.4,0z"
          />
        ),
        viewBox: '0 0 32 19.2',
        ratio: 32 / 19.2
      });
    }
    case 'upload': {
      return callback({
        graphic: <path fill={fill} d="M0,0h26.2v4.1H0V0z M0,20.1L13.1,6l13.2,14.1h-7.5V32H7.4V20.1H0z" />,
        viewBox: '0 0 26.3 32',
        ratio: 26.3 / 32
      });
    }
    case 'video': {
      return callback({
        graphic: <path fill={fill} d="M0,0l25.3,16L0,32V0z" />,
        viewBox: '0 0 25.3 32',
        ratio: 25.3 / 32
      });
    }
    case 'wifi-off': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M32,7.6L24.4,17L10.3,2.8c1.9-0.4,3.7-0.6,5.7-0.6C21.8,2.1,27.2,3.9,32,7.6z M22.9,18.9l4.8,4.8L26,25.4l-4.7-4.6L16,27.4 v0.1v-0.1L0,7.6c1.5-1.2,3.2-2.1,5.1-3L2.2,1.7L3.9,0L22.9,18.9z"
          />
        ),
        viewBox: '0 0 32 27.5',
        ratio: 32 / 27.5
      });
    }
    case 'wrench': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M31.6,26.2c0.3,0.2,0.4,0.5,0.4,1s-0.2,0.8-0.5,1.1l-3.3,3.3c-0.3,0.3-0.6,0.4-1.1,0.4c-0.4,0-0.7-0.1-1-0.4L12.9,18.3 c-1.7,0.6-3.4,0.9-5.2,0.4s-3.4-1.3-4.8-2.7c-1.4-1.5-2.2-3.2-2.7-5.2S0.1,7,0.9,5.3l6.4,6.3l4.4-4.4L5.4,0.9C7.1,0,9-0.2,11,0.1 s3.7,1.2,5.1,2.7c1.4,1.4,2.2,3,2.7,4.8s0.2,3.6-0.4,5.3L31.6,26.2z"
          />
        ),
        viewBox: '0 0 32 32',
        ratio: 32 / 32
      });
    }
    case 'youtube': {
      return callback({
        graphic: (
          <path
            fill={fill}
            d="M31.7,4.8C31.9,6.5,32,8.3,32,9.9v2.5l-0.3,5.2c-0.2,1.5-0.6,2.5-1.3,3.2c-0.7,0.7-1.7,1.2-3.2,1.4c-1.4,0.1-3,0.2-4.8,0.2 c-1.9,0.1-3.4,0.1-4.6,0.1H16c-5.5,0-9.2-0.1-10.9-0.3c-0.9-0.2-1.4-0.3-1.6-0.3s-0.5-0.2-1-0.3c-0.3-0.2-0.7-0.4-1-0.7 c-0.2-0.3-0.5-0.7-0.7-1.3c-0.2-0.5-0.3-1-0.4-1.4l-0.1-0.5C0.1,15.8,0,14.1,0,12.4V9.9l0.3-5.1C0.5,3.3,1,2.3,1.6,1.6 c0.7-0.7,1.8-1.2,3.2-1.4c1.4,0,3,0,4.8-0.1C11.4,0.1,13,0,14.2,0H16c4.5,0,8.2,0.1,11.3,0.3c1.4,0.1,2.5,0.6,3.2,1.4 c0.2,0.2,0.3,0.4,0.5,0.7c0.1,0.3,0.3,0.5,0.3,0.9c0.1,0.3,0.2,0.5,0.2,0.7c0,0.2,0.1,0.4,0.1,0.6V4.8L31.7,4.8z M20.3,11.4 l1.1-0.5l-8.6-4.5v9L20.3,11.4z"
          />
        ),
        viewBox: '0 0 32 22.5',
        ratio: 32 / 22.5
      });
    }
    default: {
      return callback({
        graphic: (
          <g>
            <path
              fill={fill}
              d="M12.7,12.8c0.2-0.5,0.4-0.9,0.8-1.2c0.3-0.4,0.7-0.6,1.2-0.8c0.5-0.2,1-0.3,1.6-0.3c0.8,0,1.4,0.1,1.9,0.3s0.9,0.5,1.2,0.8 c0.3,0.3,0.5,0.6,0.7,1c0.1,0.4,0.2,0.7,0.2,1c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.3-0.3,0.6-0.5,0.8s-0.4,0.4-0.7,0.6s-0.5,0.3-0.7,0.5 c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.3,0.5-0.3,0.8v0.6h-2.2V18c0-0.5,0.1-0.8,0.3-1.2c0.1-0.3,0.3-0.6,0.5-0.8 c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.2-0.7c0-0.5-0.1-0.9-0.4-1.1 c-0.2-0.2-0.6-0.4-1-0.4c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.3-0.1,0.5-0.1,0.8h-2.4 C12.4,13.8,12.5,13.3,12.7,12.8z M17.5,19.7v2.5H15v-2.5H17.5z"
            />
          </g>
        ),
        viewBox: '0 0 32 32',
        ratio: 1
      });
    }
  }
};

export default renderGraphic;
