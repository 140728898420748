import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;

Container.defaultProps = {
  padding: '0 10px',
  maxWidth: '1100px',
  margin: '0 auto'
};

Container.propTypes = {
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string
};

Container.displayName = 'Container';

export default Container;
