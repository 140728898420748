import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// helper
import { shouldDisplayError } from '../helpers';

// components
import Checkbox from '../checkbox';
import Caption from '../caption';

// styled
const Wrapper = styled.div`
  margin: ${({ margin }) => margin};
  ${({ disabled }) => (disabled ? 'opacity: 0.25;' : '')}
`;

const Label = styled.label`
  position: relative;
  display: flex;
  font-weight: 500;
  font-size: ${({ large }) => (large ? '15px' : '10px')};
  color: ${({ theme }) => (theme === 'black' ? '#000' : '#fff')};
  ${({ large }) => (large ? 'line-height: 2;' : '')}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')}
  ${({ disabled }) => (disabled ? 'cursor: auto;' : 'cursor: pointer;')}
`;

const Text = styled.span`
  display: block;
  flex: 0 0 auto;
  width: 100%;
  ${({ large }) => (!large ? 'line-height: 20px;' : '')}
`;

const ScreenReaderOnly = styled.span`
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const FormError = styled.span`
  font-size: 14px;
  border-top: 2px solid #d0021b;
  margin-top: 10px;
  display: inline-block;
  color: #d0021b;
  padding-top: 5px;
`;

const Subtext = styled(Caption)`
  display: block;
`;

// Maintains a list of Checkbox IDs to ensure a unique ID.
const ids = [];

class CheckboxField extends Component {
  constructor() {
    super();
    this.id = ids.length + 1;
    ids.push(this.id);
  }

  componentWillUnmount() {
    ids.splice(ids.indexOf(this.id), 1);
  }

  shouldDisplayError() {
    const { touched, forceTouch, error } = this.props;

    return shouldDisplayError({ error, touched, forceTouch });
  }

  renderBody() {
    const {
      label,
      name,
      type,
      disabled,
      theme,
      circle,
      fullWidth,
      large,
      tick,
      value,
      values,
      checked,
      onChange,
      isSubmitting
    } = this.props;

    if (label !== '' && label !== undefined) {
      let appearance;
      // if user does not define a tick choose fill to be default
      // else if type is checkbox then use tick as default
      if (tick !== null) {
        appearance = tick ? 'tick' : 'fill';
      } else {
        appearance = type === 'checkbox' ? 'tick' : 'fill';
      }

      let round = circle;
      if (round === null) {
        round = type === 'radio';
      }

      return (
        <Label disabled={disabled} theme={theme} fullWidth={fullWidth} large={large} htmlFor={`${name}-${this.id}`}>
          <Checkbox
            id={`${name}-${this.id}`}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isSubmitting || disabled}
            checked={typeof values === 'object' ? values[name] === value : checked}
            appearance={appearance}
            theme={theme}
            circle={round}
          />
          <div>
            <Text large={large}>{label}</Text>
            {this.renderDescription()}
          </div>
        </Label>
      );
    }
    return (
      <ScreenReaderOnly>
        <Label htmlFor={`${name}-${this.id}`}>
          <div>
            <Text>{name}</Text>
            {this.renderDescription()}
          </div>
        </Label>
      </ScreenReaderOnly>
    );
  }

  renderDescription() {
    const { showSubtext, subtext } = this.props;

    if (showSubtext && subtext !== '') {
      return (
        <Subtext as="span" textSize="12px" margin="5px 0 0">
          {subtext}
        </Subtext>
      );
    }
    return null;
  }

  render() {
    const { error, disabled, margin, className } = this.props;

    const showError = this.shouldDisplayError();

    return (
      <Wrapper margin={margin} disabled={disabled} className={showError ? `${className} invalid` : className}>
        {this.renderBody()}
        {showError && <FormError>{error}</FormError>}
      </Wrapper>
    );
  }
}

CheckboxField.propTypes = {
  label: PropTypes.any,
  theme: PropTypes.oneOf(['black', 'white']),
  checked: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  circle: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  tick: PropTypes.bool,
  forceTouch: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  values: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  fullWidth: PropTypes.bool,
  showSubtext: PropTypes.bool,
  subtext: PropTypes.any,
  margin: PropTypes.string,
  className: PropTypes.string
};

CheckboxField.defaultProps = {
  label: '',
  fullWidth: false,
  large: false,
  circle: null,
  tick: null,
  theme: 'black',
  value: false,
  error: null,
  touched: false,
  forceTouch: false,
  isSubmitting: false,
  checked: false,
  disabled: false,
  values: false,
  showSubtext: true,
  subtext: '',
  margin: '0 0 10px',
  className: null
};

CheckboxField.displayName = 'CheckboxField';

export default CheckboxField;
