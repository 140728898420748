import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';

import Icon from '@lushdigital/icons';
import { Toggle, Wrapper } from '../styles/appNavigation';

const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

const AppNavigation = ({ items, section }) => {
  const { t } = useTranslation('tills');

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleList = () => setMenuOpen((state) => !state);

  if (isBrowser) {
    const { body } = document;

    if (body && body.style) {
      if (menuOpen) {
        body.style['overflow-y'] = 'hidden';
      } else {
        body.style['overflow-y'] = 'visible';
      }
    }
  }

  return (
    <>
      <Toggle open={menuOpen} dashboard={false} onClick={() => toggleList()}>
        <Icon icon={menuOpen ? 'close' : 'menu'} size={menuOpen === 'menu' ? '19px' : '13px'} fill="#fff" />
        {t(menuOpen ? 'MENU' : section)}
      </Toggle>
      <Wrapper open={menuOpen} onClick={() => toggleList()}>
        {items}
      </Wrapper>
    </>
  );
};

AppNavigation.defaultProps = { items: null };

AppNavigation.propTypes = {
  items: PropTypes.any,
  section: PropTypes.string.isRequired
};

export default AppNavigation;
