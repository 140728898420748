import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';

import Button from '../../../atoms/button';
import SrOnly from '../../../atoms/typography/srOnly';

const MenuToggle = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 20px;
`;

const MenuToggleButton = styled(Button)`
  svg {
    ${(props) =>
      props.reveal
        ? `
      transform: rotate(90deg);
      transition: transform 0.1s;
    `
        : `
      transform: rotate(0);
      transition: transform 0.25s;
    `}
  }
`;

const MenuContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  transform-origin: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 5px;
  padding-bottom: ${(props) => props.padding}px;

  ${(props) =>
    props.reveal
      ? `
    opacity: 1;
    background: rgba(255, 255, 255, 0.98);
    transform: scale(1);
    min-height: 100%;
    `
      : `
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
  `}
`;

//
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      reveal: false
    };
  }

  componentDidMount() {
    // const toggleHeight = Math.max(this.toggle.scrollHeight, this.toggle.offsetHeight, this.toggle.clientHeight);
    // TODO fix toggleHeight
    const toggleHeight = 100;

    this.setState(() => ({ height: toggleHeight }));
  }

  handleMenuToggle = () => {
    this.setState((current) => ({ reveal: !current.reveal }));
  };

  handleMenuOpen = () => {
    this.setState({ reveal: true });
  };

  handleMenuClose = () => {
    this.setState({ reveal: false });
  };

  render() {
    const { actions } = this.props;
    const { reveal, height } = this.state;

    return (
      <>
        <MenuContent
          reveal={reveal}
          innerRef={(node) => {
            this.content = node;
          }}
          padding={height}
        >
          {actions({ toggle: this.handleMenuToggle, open: this.handleMenuOpen, close: this.handleMenuClose })}
        </MenuContent>

        <MenuToggle
          innerRef={(node) => {
            this.toggle = node;
          }}
        >
          <MenuToggleButton
            reveal={reveal}
            type="button"
            circle
            padding="1.2em"
            onClick={(e) => this.handleMenuToggle(e)}
          >
            <Icon size="1.1em" styles={{ pointerEvents: 'none' }} icon="more" />
            <SrOnly>toggle menu</SrOnly>
          </MenuToggleButton>
        </MenuToggle>
      </>
    );
  }
}

Menu.defaultProps = { actions: null };

Menu.propTypes = { actions: PropTypes.func };

Menu.displayName = 'Menu';

export default Menu;
