import styled from 'styled-components';
import PropTypes from 'prop-types';

// Exported default element
const Input = styled.input.attrs(({ type, maxLength }) => ({
  type,
  maxLength
}))`
  font-size: ${({ textSize }) => textSize};
  font-weight: 400;
  line-height: 1.2;

  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  border: 1px solid #eaeaea;
  color: #333;
  background-color: #fff;

  vertical-align: middle;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #eaeaea;
    opacity: 0.8;
  }

  &:disabled,
  &[readonly] {
    color: #8f8f8f;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${({ error }) => (error ? 'border-color: #D0021B;' : '')}
  &.error,
  &.invalid {
    border-color: #d0021b;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 0 0 3px rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: #cecece;
  }

  &::-moz-placeholder {
    color: #cecece;
  }

  &:-ms-input-placeholder {
    color: #cecece;
  }

  ${({ fullWidth }) => {
    if (fullWidth) {
      return `
        display: flex;
        width: 100%;
        justify-content: center;
      `;
    }
    return 'display: inline-flex;';
  }}
`;

// Default props
Input.defaultProps = {
  textSize: '14px',
  padding: '1.143em 1.8em',
  margin: '0',
  error: false,
  fullWidth: false,
  maxLength: 255,
  type: 'text'
};

// Prop type check
Input.propTypes = {
  textSize: PropTypes.string,
  type: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  maxLength: PropTypes.number
};

Input.displayName = 'Input';

// export
export default Input;
