import dates from './dates';

const CONFIG = {
  languages: ['cs', 'hu', 'en', 'es', 'fr', 'nl', 'pt-br', 'de', 'it', 'ja', 'ko', 'ru', 'sv', 'zh-hk'],
  languageTitles: {
    en: 'English',
    cs: 'Čeština',
    de: 'Deutsch',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
    hu: 'Magyar',
    nl: 'Nederlands',
    'pt-br': 'Português',
    sv: 'Svenska',
    ja: '日本語',
    ko: '한국어',
    ru: 'Pусский',
    'zh-hk': '繁體中文'
  },
  dateFormats: {
    ...dates
  },
  feed_image_sizes: [
    {
      key: 'post',
      label: 'F',
      width: 1000,
      height: 560
    }
  ],
  feed_image_default: {
    cdn: 'cloudinary',
    selected_type: 'post',
    selected_size: 'post',
    alt: '',
    edited: {
      name: '',
      directory: '',
      file_extension: '',
      link: '',
      height: '',
      width: ''
    },
    original: {
      name: '',
      directory: '',
      file_extension: '',
      link: ''
    },
    transformation: {
      scale: 1,
      position: {
        x: 0,
        y: 0
      }
    }
  }
};

export default CONFIG;
