import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Error from '../../../atoms/typography/error';

// STYLED COMPONENTS
const GroupWrapper = styled.div`
  ${({ staticHeight }) =>
    staticHeight
      ? `
    position: absolute;
    top: 100%;
    left: 0;
  `
      : ''}

  width: 100%;
  z-index: 302;

  ${({ direction }) =>
    direction === 'left'
      ? `
    padding: 5px 20px 5px 37px;
  `
      : `
    padding: 5px 37px 5px 20px;
  `}
`;

class ErrorGroup extends Component {
  render() {
    const { message, direction, staticHeight } = this.props;

    return (
      <GroupWrapper direction={direction} staticHeight={staticHeight}>
        <Error>{message}</Error>
      </GroupWrapper>
    );
  }
}

ErrorGroup.propTypes = {
  staticHeight: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired
};

export default ErrorGroup;
