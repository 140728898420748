import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import UseTranslation from 'next-translate/useTranslation';
import Icon from '@lushdigital/icons';

type AvatarProps = {
  profile_image?: string;
  first_name?: string;
  last_name?: string;
  size?: string;
};

const Avatar = ({ profile_image, first_name, last_name, size }: AvatarProps) => {
  const { t } = UseTranslation('tills');
  const [imageError, setImageError] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [initials, setInitials] = useState<string>('');
  const [avatarStyle, setAvatarStyle] = useState<{ size: { width: number; height: number }; classes: string }>({
    size: { width: 40, height: 40 },
    classes: 'bg-lush-black-8 text-lush-black-44 flex h-10 w-10 items-center justify-center rounded-full text-base'
  });

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    if (typeof first_name === 'string' && typeof last_name === 'string') {
      setName(`${first_name} ${last_name}`);
      setInitials(`${first_name.charAt(0)}${last_name.charAt(0)}`);
    } else if (typeof first_name === 'string') {
      setName(first_name);
      setInitials(first_name.charAt(0));
    } else if (typeof last_name === 'string') {
      setName(last_name);
      setInitials(last_name.charAt(0));
    }
  }, [first_name, last_name]);

  const altText = name ? t('AVATAR_ALT_TEXT', { name }) : t('AVATAR_ALT_TEXT_WITHOUT_USER_NAME');

  useEffect(() => {
    switch (size) {
      case 'small':
        setAvatarStyle({
          size: { width: 32, height: 32 },
          classes: 'bg-lush-black-8 text-lush-black-44 flex h-7 w-7 items-center justify-center rounded-full text-xs'
        });
        break;
      case 'large':
        setAvatarStyle({
          size: { width: 64, height: 64 },
          classes: 'bg-lush-black-8 text-lush-black-44 flex h-14 w-14 items-center justify-center rounded-full text-2xl'
        });
        break;
      default:
        setAvatarStyle({
          size: { width: 40, height: 40 },
          classes:
            'bg-lush-black-8 text-lush-black-44 flex h-10 w-10 items-center justify-center rounded-full text-base'
        });
        break;
    }
  }, [size]);

  const renderAvatar = () => {
    if (profile_image && !imageError) {
      return (
        <Image
          src={profile_image}
          {...avatarStyle.size}
          alt={altText}
          className="rounded-full"
          unoptimized
          onError={handleImageError}
          priority={process.env.NODE_ENV === 'test'}
        />
      );
    }

    if (initials) {
      return <div className={`${avatarStyle.classes} font-normal`}>{initials}</div>;
    }

    return (
      <div className={avatarStyle.classes} data-testid="avatar-profile-icon">
        <Icon icon="profile" size="1em" color="#8F8F8F" />
      </div>
    );
  };

  return renderAvatar();
};

export default Avatar;
