import { useState, useRef } from 'react';
import { Wrapper, Tile, Triangle } from '../styles/shortcutMenu';
import Icon from '../../icons';
import { ENV, appEnvironmentRoutes } from '@lushdigital/helper-functions';
import { useClickOutsideListener } from '@/hooks/useClickOutside';
import useTranslation from 'next-translate/useTranslation';

export const ShortcutMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t, lang } = useTranslation('tills');
  const wrapperRef = useRef(null);

  useClickOutsideListener(wrapperRef, () => setMenuOpen(false));

  const routes = appEnvironmentRoutes(ENV);

  const LinkTile = ({ name, url, icon, iconSize }) => (
    <Tile href={url.replace(':language', lang)}>
      <Icon icon={icon} size={iconSize} fill="#fff" />
      {name && <p>{name}</p>}
    </Tile>
  );

  return (
    <button onClick={() => setMenuOpen(!menuOpen)} onMouseDown={(event) => event.stopPropagation()}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="white"
          d="M4 8h4V4H4zm6 12h4v-4h-4zm-6 0h4v-4H4zm0-6h4v-4H4zm6 0h4v-4h-4zm6-10v4h4V4zm-6 4h4V4h-4zm6 6h4v-4h-4zm0 6h4v-4h-4z"
        />
      </svg>
      {menuOpen && (
        <Wrapper ref={wrapperRef}>
          <Triangle />
          <LinkTile url={routes.PEOPLE} icon="profile" name={t('PEOPLE')} iconSize="30px" />
          <LinkTile url={routes.SHOP_MANAGER} icon="store" name={t('SHOP_MANAGER')} iconSize="30px" />
        </Wrapper>
      )}
    </button>
  );
};
