import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// context
import { breakpoints } from '../size';

// supporting
import { sizeMixin } from '../helpers/css';
import breakpoint from '../breakpoint';

const Column = styled.div`
  display: flex;

  /* sizing */
  width: 100%;
  max-width: 100%;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  /* flex */
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-basis: ${({ flexBasis }) => flexBasis};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  /* breakpoint size */
  ${(props) =>
    Object.keys(breakpoints).map((bp) => {
      const size = props[bp];

      if (!size) return null;

      return css`
        ${breakpoint(bp)`
          ${sizeMixin(size, props.columns, props.flexBasis)}
        `}
      `;
    })}
`;

Column.defaultProps = {
  flexGrow: '1',
  flexShrink: '0',
  flexBasis: '0%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  padding: '10px',
  margin: '0',
  columns: 12,

  ...Object.keys(breakpoints).reduce(
    (accumulator, bp) => ({
      ...accumulator,
      [bp]: null
    }),
    {}
  )
};

Column.propTypes = {
  flexGrow: PropTypes.string,
  flexShrink: PropTypes.string,
  flexBasis: PropTypes.string,
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  alignItems: PropTypes.oneOf(['normal', 'stretch', 'flex-start', 'center', 'flex-end']),
  justifyContent: PropTypes.oneOf([
    'normal',
    'space-evenly',
    'stretch',
    'space-around',
    'space-between',
    'center',
    'flex-start',
    'flex-end'
  ]),
  padding: PropTypes.string,
  margin: PropTypes.string,

  /**
   * Number defining the amount of columns in grid
   */
  columns: PropTypes.number,

  /**
   * Define size of column from anything that doesn't match a breakpoint
   * number will be that in the amount of set columns e.g. 3/12 = 24%
   * string can be any string
   */
  ...Object.keys(breakpoints).reduce(
    (accumulator, bp) => ({
      ...accumulator,
      [bp]: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    {}
  )
};

Column.displayName = 'Column';

export default Column;
