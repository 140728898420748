import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import Card from '../../atoms/card';
import Menu from './components/menu';

// styled components
const CardSection = styled.div`
  &:last-child {
    min-height: ${(props) => props.toggleHeight}px;
  }
`;

const CardSectionContainer = styled.div``;

//
class ActionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      contentHeight: 0,
      toggleHeight: 0
    };
    this.card = null;
    this.menu = null;
  }

  //
  componentDidMount() {
    if (this.menu) {
      const contentHeight = 100;
      const toggleHeight = 200;

      this.setState(() => ({
        contentHeight,
        toggleHeight,
        height: contentHeight + toggleHeight
      }));
    }
  }

  //
  setCardRef = (e) => {
    this.card = e;
  };

  setMenuRef = (e) => {
    this.menu = e;
  };

  //
  renderActions() {
    const { actions } = this.props;

    actions({});
  }

  //
  renderChildren() {
    const { children, sectionProps } = this.props;
    const { toggleHeight } = this.state;

    return React.Children.map(children, (child) => {
      if (child) {
        return (
          <CardSection toggleHeight={toggleHeight} {...sectionProps}>
            {child}
          </CardSection>
        );
      }
    });
  }

  //
  render() {
    const { actions, cardProps, menuProps, containerProps, theme, minHeight } = this.props;
    const { height } = this.state;

    const newMinHeight = parseInt(minHeight, 10) < parseInt(height, 10) ? height : minHeight;

    return (
      <Card {...cardProps} theme={theme} style={{ minHeight: newMinHeight }} innerRef={this.setCardRef}>
        <CardSectionContainer {...containerProps}>{this.renderChildren()}</CardSectionContainer>

        {actions && <Menu {...menuProps} ref={this.setMenuRef} actions={actions} />}
      </Card>
    );
  }
}

ActionCard.defaultProps = {
  children: null,
  theme: 'default',
  actions: null,
  cardProps: {},
  sectionProps: {},
  containerProps: {},
  menuProps: {},
  minHeight: 0
};

ActionCard.propTypes = {
  cardProps: PropTypes.object,
  menuProps: PropTypes.object,
  containerProps: PropTypes.object,
  sectionProps: PropTypes.object,
  children: PropTypes.any,
  actions: PropTypes.func,
  theme: PropTypes.string,
  minHeight: PropTypes.number
};

ActionCard.displayName = 'ActionCard';

export default ActionCard;
