import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  will-change: transform;

  background: ${({ theme }) => {
    if (theme === 'white') return '#fff';
    return '#000';
  }};

  ${({ transitionStyles }) => transitionStyles}
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;
