import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../../atoms/button';

// STYLED COMPONENTS
const GroupWrapper = styled.div`
  ${({ staticHeight }) =>
    staticHeight
      ? `
    position: absolute;
    top: 100%;
    left: 0;
  `
      : ''}

  width: 100%;
  z-index: 302;

  ${({ direction }) =>
    direction === 'left'
      ? `
    padding: 5px 20px 5px 37px;
  `
      : `
    padding: 5px 37px 5px 20px;
  `}
`;

const GroupItem = styled(Button)`
  white-space: nowrap;
  text-transform: none;
  letter-spacing: 0;
  background: none;
  border: none;
  width: auto;
  font-size: 17px;
  padding: 0 8px;
  line-height: 32px;
  font-weight: 400;
  color: ${({ color }) => (color === 'white' ? '#000' : '#fff')};
`;

class Group extends Component {
  shouldComponentUpdate(nextProps) {
    const { options, theme, direction } = this.props;

    const optionsMatch = JSON.stringify(nextProps.options) !== JSON.stringify(options);
    const themeUpdate = theme !== nextProps.theme;
    const directionUpdate = direction !== nextProps.direction;

    return optionsMatch || themeUpdate || directionUpdate;
  }

  renderOptions = () => {
    const { options, onSelect, theme, className, renderOptions } = this.props;

    // If a custom render function is provided then use it to render the options
    if (typeof renderOptions === 'function') {
      return renderOptions({ options, onSelect, theme });
    }

    return options.map((option) => (
      <GroupItem
        theme="default-floating"
        className={`ui-type-ahead__group-item ${className !== '' ? `${className}-type-ahead__group-item` : ''}`}
        key={option.id}
        fullWidth
        onClick={() => {
          onSelect(option);
        }}
        color={theme}
        align="left"
      >
        {option.title || option.name}
      </GroupItem>
    ));
  };

  render() {
    const { direction, className, staticHeight } = this.props;

    return (
      <GroupWrapper
        staticHeight={staticHeight}
        direction={direction}
        className={`ui-type-ahead__group ${className !== '' ? `${className}-type-ahead__group` : ''}`}
      >
        {this.renderOptions()}
      </GroupWrapper>
    );
  }
}

Group.defaultProps = { renderOptions: null };

Group.propTypes = {
  staticHeight: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderOptions: PropTypes.func,
  options: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  theme: PropTypes.oneOf(['white', 'black']).isRequired
};

export default Group;
