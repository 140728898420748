import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { RemoveScroll } from 'react-remove-scroll';

// components
import Header from './takeover.header';

// styles
import { Container, Wrapper } from './takeover.styles';

// component
export default class TakeOver extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false };
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.
    this.modalRoot = props.ssr ? null : document.createElement('div');
    this.modalWrapper = React.createRef();
    this.Container = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const { ssr } = this.props;

    if (!ssr) {
      this.modalRoot.className = 'takeover-container';
      document.body.appendChild(this.modalRoot);
    }
  }

  componentDidMount() {
    this.setState(() => ({ mounted: true }));

    this.Container.current.focus();
  }

  // Functions
  handleKeyDown = (e) => {
    const { closeOnEsc, onClose } = this.props;

    const focusableEls = Array.prototype.slice.call(
      this.modalWrapper.current.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
      )
    );
    const KEY_TAB = 9;
    const KEY_ESCAPE = 27;

    switch (e.keyCode) {
      case KEY_TAB:
        if (e.shiftKey) {
          this.handleBackwardTab(e, focusableEls);
        } else {
          this.handleForwardTab(e, focusableEls);
        }

        break;
      case KEY_ESCAPE:
        if (closeOnEsc !== false) {
          onClose();
        }

        break;
      default:
        break;
    }
  };

  handleBackwardTab(e, focusableEls) {
    const firstElement = focusableEls[0];
    const lastElement = focusableEls[focusableEls.length - 1];

    if (document.activeElement === firstElement) {
      e.preventDefault();
      lastElement.focus();
    }
    if (document.activeElement === this.Container.current) {
      e.preventDefault();
      lastElement.focus();
    }
  }

  handleForwardTab(e, focusableEls) {
    const firstElement = focusableEls[0];
    const lastElement = focusableEls[focusableEls.length - 1];

    if (document.activeElement === lastElement) {
      e.preventDefault();
      firstElement.focus();
    } else if (document.activeElement === this.Container.current) {
      e.preventDefault();
      firstElement.focus();
    }
  }

  // Modal constructor
  constructModal() {
    const { lockScroll, theme, transitionStyles } = this.props;

    const content = (
      <Container
        transitionStyles={transitionStyles}
        theme={theme}
        role="dialog"
        tabIndex={-1}
        ref={this.Container}
        onKeyDown={(e) => {
          this.handleKeyDown(e);
        }}
      >
        {this.renderContent()}
      </Container>
    );

    if (lockScroll) {
      return <RemoveScroll>{content}</RemoveScroll>;
    }
    return content;
  }

  // Render functions
  renderHeader() {
    const { headerPosition, header, onClose, theme, headerIcon, headerAlign } = this.props;

    if (header) {
      return <Header align={headerAlign} icon={headerIcon} position={headerPosition} onClose={onClose} theme={theme} />;
    }
    return null;
  }

  renderContent() {
    const { mounted } = this.state;
    const { children, ssr } = this.props;

    return (
      <Wrapper className="takeover-wrapper" ref={this.modalWrapper}>
        {this.renderHeader()}
        {(mounted || ssr) && children({ wrapper: this.modalWrapper, root: this.modalRoot })}
      </Wrapper>
    );
  }

  render() {
    const { ssr } = this.props;

    if (ssr) {
      return this.constructModal();
    }
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(this.constructModal(), this.modalRoot);
  }
}

TakeOver.displayName = 'TakeOver';

TakeOver.defaultProps = {
  // manage closing options
  closeOnEsc: true,
  onClose: () => null,
  // Controlling header and footer renders
  header: true,
  headerPosition: 'relative',
  theme: 'black',
  headerIcon: 'long-arrow-left',
  headerAlign: 'flex-start',
  transitionStyles: '',
  ssr: false,
  lockScroll: true
};

TakeOver.propTypes = {
  /**
   * pass any child component or text
   */
  children: PropTypes.func.isRequired,
  /**
   * function run when close is ran
   */
  onClose: PropTypes.func,
  /**
   * function run when user presses the esc key
   */
  closeOnEsc: PropTypes.bool,
  /**
   * theme to change background colour
   */
  theme: PropTypes.oneOf(['black', 'white']),
  /**
   * show or hide header
   */
  header: PropTypes.bool,
  /**
   * makes header stick when content is scrollable
   */
  headerPosition: PropTypes.oneOf(['fixed', 'relative', 'absolute', 'sticky']),
  /**
   * Icon for header
   */
  headerIcon: PropTypes.string,
  /**
   * alignment for header
   */
  headerAlign: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  transitionStyles: PropTypes.string,
  ssr: PropTypes.bool,
  lockScroll: PropTypes.bool
};
