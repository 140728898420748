
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useState, useEffect } from 'react';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { QueryClient, QueryCache, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';

import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';

import { Header } from '@lushdigital/header/components/header';
import DashboardTiles from '@tills/components/dashboard-tiles';

import LushLoader from '@lushdigital/ui/lushLoader';

import packageJson from 'package.json';
import Script from 'next/script';

import '../styles/global.css';
import '../scss/app.scss';
import '@lushdigital/ui-latest/component/molecules/fancyTable/style.scss';
import '@lushdigital/ui-latest/component/molecules/inlineMenu/style.scss';
// eslint-disable-next-line import/no-absolute-path
import '/node_modules/@salocreative/react-pagination/src/scss/styles.scss';

import { FeatureProvider, useFeatures } from '@/tills/context/features';

import { UserAuthError, ApiError } from '@/manager-next/customErrors';
import { logSentryApiException } from '@/manager-next/helpers/sentry';
import { clarity } from '@/manager-next/helpers/clarity';
import apolloClient from '../lib/apollo';

const { NEXT_PUBLIC_SENTRY_RELEASE } = process.env;
const ENV = process.env.NEXT_PUBLIC_BUILD_ENV || process.env.NODE_ENV;

/**
 * Not using feature flags for this because it's instantiated outside of a component,
 * and because it should NEVER be used outside of development. Tests have their own
 * version (in /mocks).
 */
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  require('../mocks/index');
}

const Loading = (): JSX.Element | null => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
      return true;
    };
    const handleComplete = () => {
      setLoading(false);
      return true;
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 999,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'white',
          display: 'grid',
          placeItems: 'center'
        }}
      >
        <LushLoader width={300} />
      </div>
    );
  }

  return null;
};

const LayoutWrapper = ({ children, hideNav, session }): JSX.Element => {
  const { t } = useTranslation('tills');
  const features = useFeatures();
  const managerVersion = packageJson.version;

  useEffect(() => {
    if (typeof window !== 'undefined' && features.featureFlags?.includes('clarity')) {
      if (!features) {
        console.error('features not defined');
        return;
      }
      clarity.init();
      if (clarity.hasStarted()) {
        clarity.identify(session?.user);
        clarity.setTags({
          shopId: String(features?.shopId),
          countryCode: String(features?.countryCode),
          timezone: String(features?.timezone),
          managerVersion
        });
      }
    }
  }, [features.featureFlags]);

  return (
    <>
      <Head>
        <link rel="shortcut icon" type="image/png" href="/assets/img/favicon.png" />
      </Head>
      {!hideNav && <Header dashboard={<DashboardTiles />} title={t('SHOP_MANAGER')} />}

      <Loading />
      {children}
      {process.env.NEXT_PUBLIC_BUILD_ENV === 'production' ? (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
          `}
          </Script>
        </>
      ) : null}
    </>
  );
};

const MyApp = ({ Component, pageProps: { session, ...pageProps } }): JSX.Element => {
  const router = useRouter();
  const { locale } = router;

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error) => {
            logSentryApiException({
              exception: error
            });

            if (error instanceof UserAuthError) {
              console.warn('Redirect in _app - user auth error');
              if (clarity.hasStarted()) {
                clarity.setTag('warning', 'UserAuthError');
              }
              router.push('/login', undefined, { locale });
            } else if (error instanceof ApiError) {
              console.error('API error', error);
              if (clarity.hasStarted()) {
                clarity.setTag('error', 'APIError');
              }
            } else {
              console.error('Unknown error', error);
              if (clarity.hasStarted()) {
                clarity.setTag('error', 'UnknownError');
              }
            }
          }
        })
      })
  );

  useEffect(() => {
    if (!session && router.pathname !== '/login') {
      console.warn('Redirect in _app - no session');
      if (clarity.hasStarted()) {
        clarity.setTag('warning', 'RedirectToLoginNoSession');
      }
      router.push('/login', undefined, { locale });
    }
  }, []);

  const errorComp = () => (
    <>
      <h2>An error occured. Please clear your cookies and login again.</h2>
      <p>If the error persists please contact an administrator</p>
    </>
  );

  console.warn('Manager', ENV, NEXT_PUBLIC_SENTRY_RELEASE);

  const localeMap = {
    ja: 'ja',
    de: 'de',
    en: 'en',
    us: 'en-US'
  };

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider session={session}>
        <ApolloProvider client={apolloClient}>
          <IntlProvider locale={locale ? localeMap[locale] : 'EN'}>
            <FeatureProvider>
              <LayoutWrapper hideNav={Component.hideNav} session={session}>
                <ErrorBoundary
                  FallbackComponent={errorComp}
                  onError={(error) => {
                    logSentryApiException({
                      exception: error,
                      config: {
                        url: window.location.href,
                        data: {
                          locale,
                          expires: session.expires,
                          shop: session.shop,
                          session_user: session.user
                        }
                      }
                    });
                  }}
                >
                  <Component {...pageProps} />
                </ErrorBoundary>
              </LayoutWrapper>
            </FeatureProvider>
          </IntlProvider>
        </ApolloProvider>
      </SessionProvider>
      {process.env.NEXT_PUBLIC_BUILD_ENV === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </QueryClientProvider>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  