import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AtomInput from '../../../atoms/form/input';

// STYLED COMPONENTS
const SearchInput = styled(AtomInput)`
  border-radius: 20px;
  width: 100%;
  font-size: 17px;
  background: ${({ theme }) => (theme === 'white' ? '#F6F7FB' : 'white')};

  ${({ direction }) =>
    direction === 'left'
      ? `
    padding: 8px 20px 8px 45px;
  `
      : `
    padding: 8px 45px 8px 20px;
  `}
`;

class Input extends Component {
  constructor(props) {
    super(props);
    this.searchInput = null;
  }

  componentDidMount() {
    // TODO use a ref for focus
    // this.searchInput.focus();
  }

  shouldComponentUpdate(nextProps) {
    const { disabled, value } = this.props;

    const valueMatch = nextProps.value !== value;
    const disabledMatch = nextProps.disabled !== disabled;

    return valueMatch || disabledMatch;
  }

  componentDidUpdate(prevProps) {
    const { disabled } = this.props;

    if (prevProps.disabled && !disabled) {
      this.searchInput.focus();
    }
  }

  checkEnter = (e) => {
    const { onEnter } = this.props;

    if (e.charCode === 13) {
      e.preventDefault();
      onEnter();
    }
  };

  render() {
    const { value, onChange, disabled, direction, theme, className } = this.props;

    return (
      <SearchInput
        innerRef={(input) => {
          this.searchInput = input;
        }}
        name="search-tags"
        autoComplete="off"
        type="text"
        value={value}
        onChange={onChange}
        disabled={disabled}
        direction={direction}
        theme={theme}
        onKeyPress={this.checkEnter}
        className={`ui-type-ahead__input ${className !== '' ? `${className}-type-ahead__input` : ''}`}
      />
    );
  }
}

Input.defaultProps = { disabled: false };

Input.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  theme: PropTypes.oneOf(['white', 'black']).isRequired
};

export default Input;
