import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table } from './fancyTable.styles';
import TableHead from './fancyTable.tableHead';
import ActionRow from './fancyTable.actionRow';
import TableBody from './fancyTable.tableBody';
import TableFoot from './fancyTable.tableFoot';

// =============== //
// TABLE COMPONENT //
// =============== //
export default class FancyTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: false,
      minWidth: '0',
      width: '0',
      height: '0'
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const { columns, actions } = this.props;
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    let minWidth = 0;
    if (actions !== null) {
      minWidth += parseInt(actions.minWidth, 10);
    }
    columns.forEach((column) => {
      for (const key in column) {
        if (column.hasOwnProperty(key)) {
          if (key === 'minWidth') minWidth += parseInt(column[key], 10);
        }
      }
    }, this);

    this.setState(() => ({ minWidth }));
  }

  componentDidUpdate() {
    const { mobile, minWidth, width } = this.state;

    if (!mobile && width <= minWidth) {
      this.setState(() => ({ mobile: true }));
    } else if (mobile && width > minWidth) {
      this.setState(() => ({ mobile: false }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  renderHeader() {
    const { actions, columns, classes, showHeader } = this.props;
    const { mobile } = this.state;

    if (showHeader === false) return null;
    return <TableHead actions={actions} mobile={mobile} columns={columns} />;
  }

  renderActionRow() {
    const { actionRow } = this.props;
    const { mobile } = this.state;

    if (actionRow === false) return null;
    return <ActionRow actions={actionRow} mobile={mobile} />;
  }

  renderRows() {
    const { classes, data, columns, actions, clickCell, hoverCell, clickRow, hoverRow, clickColumn, hoverColumn } =
      this.props;
    const { mobile } = this.state;

    return (
      <TableBody
        mobile={mobile}
        data={data}
        columns={columns}
        // Custom columns
        actions={actions}
        // Functions
        clickCell={clickCell}
        hoverCell={hoverCell}
        clickRow={clickRow}
        hoverRow={hoverRow}
        clickColumn={clickColumn}
        hoverColumn={hoverColumn}
      />
    );
  }

  renderFooter() {
    const { columns, classes, className, showFooter, actions } = this.props;
    const { mobile } = this.state;

    if (showFooter === false) return null;
    return <TableFoot actions={actions} mobile={mobile} columns={columns} />;
  }

  render() {
    const { data, classes, className } = this.props;
    const { mobile } = this.state;

    if (data === false) return null;

    return (
      <Table
        className={`ui-table ${classes} ${className !== '' ? `ui-table--${className}` : ''} ${
          mobile ? 'ui-table--is-mobile' : ''
        } `}
      >
        {this.renderHeader()}
        {this.renderActionRow()}
        {this.renderRows()}
        {this.renderFooter()}
      </Table>
    );
  }
}

FancyTable.displayName = 'FancyTable';

FancyTable.defaultProps = {
  data: false,
  columns: null,
  classes: '',
  className: '',
  showHeader: true,
  showFooter: false,
  // custom columns
  actions: null,
  // custom rows
  actionRow: null,
  // Functions
  clickCell: null,
  hoverCell: null,
  clickRow: null,
  hoverRow: null
};

FancyTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  classes: PropTypes.string,
  columns: PropTypes.array,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  // custom columns
  actions: PropTypes.shape({
    head: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cell: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
    foot: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    classes: PropTypes.string,
    className: PropTypes.string,
    minWidth: PropTypes.string.isRequired
  }),
  // custom row
  actionRow: PropTypes.func,
  // Functions
  clickCell: PropTypes.func,
  hoverCell: PropTypes.func,
  clickRow: PropTypes.func,
  hoverRow: PropTypes.func
};
