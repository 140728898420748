import { OpeningTime } from '@/types/OpeningTime.type';
import { create } from 'zustand';

export interface ShopInfo {
  shopId?: string | number;
  shopName?: string;
  openingTimes: OpeningTime[];
  countryCode: string;
  closingTime: string;
  eodStartTime: string;
  timezone: string;
}

interface ManagerState {
  shop: string | number;
  setShop: (shopId: string | number) => void;
  shopInfo: ShopInfo;
  setShopInfo: (shopInfo: Partial<ShopInfo>) => void;
}

export const useStore = create<ManagerState>((set) => ({
  shop: '',
  shopInfo: {
    countryCode: '',
    openingTimes: [],
    closingTime: '',
    eodStartTime: '',
    timezone: ''
  },
  setShop: (shopId) => set(() => ({ shop: shopId })),
  setShopInfo: (shopInfo) => set((state) => ({ shopInfo: { ...state.shopInfo, ...shopInfo } }))
}));

export const useShop = () => useStore((state) => state.shop);
export const useShopInfo = () => useStore((state) => state.shopInfo);
export const useSetShop = () => useStore((state) => state.setShop);
export const useSetShopInfo = () => useStore((state) => state.setShopInfo);
