import PropTypes from 'prop-types';
import styled from 'styled-components';

// base typography component
import base from '../base';

// element
const H3 = styled('h3')`
  ${base}
`;

// default props for element
H3.defaultProps = {
  textWeight: '600',
  minFont: 17,
  maxFont: 27,
  minLine: 27,
  maxLine: 34
};

H3.propTypes = {
  textWeight: PropTypes.string,
  minFont: PropTypes.number,
  maxFont: PropTypes.number,
  minLine: PropTypes.number,
  maxLine: PropTypes.number
};

// DisplayName for new element
H3.displayName = 'H3';

// export element
export default H3;
