import { breakpoints, matches, sizes } from './breakpoints';

const Config = {
  fallbackHeight: 0,
  fallbackWidth: 0,
  canUseDOM: false,
  hasProvider: false,
  width: 0,
  height: 0,
  breakpoints,
  matches,
  sizes
};

export default Config;
