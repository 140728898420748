import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 7rem;
  right: 4rem;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;
  gap: 1rem;
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
`;

export const Tile = styled.a`
  width: 100px;
  height: 100px;
  background-color: black;
  justify-self: center;
  align-self: center;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &:hover {
    opacity: 0.6;
    transition: opacity 0.3s;
  }
`;

export const Triangle = styled.div`
  position: absolute;
  right: 1.5rem;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid white;
`;
