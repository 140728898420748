const currencies = [
  {
    symbol: '£',
    name: 'British Pound Sterling',
    float_step: 5,
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    code: 'GBP',
    name_plural: 'British pounds sterling',
    denominations: [
      { label: '£50', amount: 5000 },
      { label: '£20', amount: 2000 },
      { label: '£10', amount: 1000 },
      { label: '£5', amount: 500 },
      { label: '£2', amount: 200 },
      { label: '£1', amount: 100 },
      { label: '50p', amount: 50 },
      { label: '20p', amount: 20 },
      { label: '10p', amount: 10 },
      { label: '5p', amount: 5 },
      { label: '2p', amount: 2 },
      { label: '1p', amount: 1 }
    ]
  },
  {
    symbol: '€',
    name: 'Euro',
    float_step: 5,
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros',
    denominations: [
      { label: '500€', amount: 50000 },
      { label: '200€', amount: 20000 },
      { label: '100€', amount: 10000 },
      { label: '50€', amount: 5000 },
      { label: '20€', amount: 2000 },
      { label: '10€', amount: 1000 },
      { label: '5€', amount: 500 },
      { label: '2€', amount: 200 },
      { label: '1€', amount: 100 },
      { label: '50c', amount: 50 },
      { label: '20c', amount: 20 },
      { label: '10c', amount: 10 },
      { label: '5c', amount: 5 },
      { label: '2c', amount: 2 },
      { label: '1c', amount: 1 }
    ]
  },
  {
    symbol: '$',
    name: 'US Dollar',
    float_step: 5,
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'USD',
    name_plural: 'US dollars',
    denominations: [
      { label: '$5000', amount: 500000 },
      { label: '$1000', amount: 100000 },
      { label: '$500', amount: 50000 },
      { label: '$100', amount: 10000 },
      { label: '$50', amount: 5000 },
      { label: '$20', amount: 2000 },
      { label: '$10', amount: 1000 },
      { label: '$5', amount: 500 },
      { label: '$2', amount: 200 },
      { label: '$1', amount: 100 },
      { label: '50¢', amount: 50 },
      { label: '25¢', amount: 25 },
      { label: '10¢', amount: 10 },
      { label: '5¢', amount: 5 },
      { label: '1¢', amount: 1 }
    ]
  },
  {
    symbol: '$',
    name: 'CAD Dollar',
    float_step: 5,
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CAD',
    name_plural: 'CAD dollars',
    denominations: [
      { label: '$5000', amount: 500000 },
      { label: '$1000', amount: 100000 },
      { label: '$500', amount: 50000 },
      { label: '$100', amount: 10000 },
      { label: '$50', amount: 5000 },
      { label: '$20', amount: 2000 },
      { label: '$10', amount: 1000 },
      { label: '$5', amount: 500 },
      { label: '$2', amount: 200 },
      { label: '$1', amount: 100 },
      { label: '50¢', amount: 50 },
      { label: '25¢', amount: 25 },
      { label: '10¢', amount: 10 },
      { label: '5¢', amount: 5 },
      { label: '1¢', amount: 1 }
    ]
  },
  {
    symbol: '¥',
    name: 'Japanese Yen',
    float_step: 50,
    symbol_native: '￥',
    decimal_digits: 0,
    rounding: 0,
    code: 'JPY',
    name_plural: 'Japanese yen',
    denominations: [
      { label: '￥10000', amount: 10000 },
      { label: '￥5000', amount: 5000 },
      { label: '￥1000', amount: 1000 },
      { label: '￥500', amount: 500 },
      { label: '￥100', amount: 100 },
      { label: '￥50', amount: 50 },
      { label: '￥10', amount: 10 },
      { label: '￥5', amount: 5 },
      { label: '￥1', amount: 1 }
    ]
  }
];

export default currencies;
