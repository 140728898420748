import GlobalConfig from '../../config';

const AppConfig = {
  ...GlobalConfig,
  name: 'tills',
  tillStates: {
    CLOSED_DAY_ENDED: 'closed_day_ended',
    CLOSED_NOT_TRADED: 'closed_not_traded',
    CLOSED_REPORT_GENERATED: 'closed_report_generated',
    CLOSED_REPORT_GENERATING: 'closed_report_generating',
    CLOSED: 'closed',
    ERROR: 'error',
    FAULTY: 'faulty',
    LOADING: 'loading',
    LOCKED: 'locked',
    OFFLINE: 'offline',
    OPEN: 'open',
    WAITING: 'awaiting_report'
  },
  tillReadStates: {
    GENERATING_REPORT: 'generating_report',
    OFFLINE: 'offline',
    SHOP_EOD_NO_REPORT: 'shop_eod_no_report',
    SHOP_EOD_NOT_SUBMITTED: 'shop_eod_not_submitted',
    SHOP_EOD_SUBMITTED: 'shop_eod_submitted',
    SHOP_NOT_TRADED: 'shop_not_traded',
    TILL_NO_REPORT: 'till_no_report',
    TILL_NOT_TRADED: 'till_not_traded',
    TILL_OPEN: 'till_open',
    TILL_READ_NOT_SUBMITTED: 'till_read_not_submitted',
    TILL_READ_SUBMITTED: 'till_read_submitted'
  },
  daysConfig: {
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
    0: 'SUNDAY'
  },
  hourLabelsConfig: {
    0: '00:00',
    1: '01:00',
    2: '02:00',
    3: '03:00',
    4: '04:00',
    5: '05:00',
    6: '06:00',
    7: '07:00',
    8: '08:00',
    9: '09:00',
    10: '10:00',
    11: '11:00',
    12: '12:00',
    13: '13:00',
    14: '14:00',
    15: '15:00',
    16: '16:00',
    17: '17:00',
    18: '18:00',
    19: '19:00',
    20: '20:00',
    21: '21:00',
    22: '22:00',
    23: '23:00',
    24: '24:00'
  },
  orderedDaysArray: [1, 2, 3, 4, 5, 6, 0],
  dailyTarget: {
    GBP: 3000,
    JPY: 2000000,
    EUR: 3000,
    USD: null,
    CAD: null
  }
};

export default AppConfig;
