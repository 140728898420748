export function createArrayOfLength(length, mapFunction = null) {
  // eslint-disable-next-line
  return Array.apply(null, { length }).map((v, k, a) => {
    if (mapFunction) {
      return mapFunction(v, k, a);
    }
    // By default fill with number count from 1 to length
    return k + 1;
  });
}
