import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;

  ${({ spacing }) => (spacing === 'sm' ? 'margin-bottom: 20px;' : '')};
  ${({ spacing }) => (spacing === 'md' ? 'margin-bottom: 40px;' : '')};
  ${({ spacing }) => (spacing === 'lg' ? 'margin-bottom: 60px;' : '')};
`;

export const Element = styled.select`
  display: inline-block;
  font-weight: bold;
  font-size: 17px;
  color: #333;
  letter-spacing: 0;
  line-height: 1.647058824;
  max-width: 100%;
  min-height: 0;
  width: auto;
  padding: 0 1.33em 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAxNC45MyI+PHBhdGggZD0iTTMwIDBIMGwxNS4wNyAxNC45MnoiLz48L3N2Zz4=);
  background-size: 0.58em;
  background-repeat: no-repeat;
  background-position: right center;

  &:focus,
  &:active {
    box-shadow: inset 0 0 0 2px rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }
`;

export const Test = styled.span`
  font-family: 'inter', sans-serif;
  display: inline-block;
  padding: 0 1.33em 0 0;
  border: 1px solid #eaeaea;
  font-weight: bold;
  font-size: 17px;
  line-height: 1.647058824;
  position: absolute;
  top: 100%;
  left: 100%;
  white-space: nowrap;
`;
