import styled from 'styled-components';

export const Toggle = styled.button`
  ${({ dashboard }) =>
    dashboard
      ? `
    visibility: hidden;
    pointer-events: none;
  `
      : ''}
  display: block;
  z-index: 2;
  outline: none;
  background: none;
  position: relative;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 2.5px;
  padding-left: 32px;
  cursor: pointer;
  color: #fff;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  background: #000;
  overflow-y: scroll;
  z-index: 1;

  ${({ open }) =>
    open
      ? `
    display: block;
  `
      : `
    display: none;
  `}
`;
