export function sanitize(value) {
  return value.replace(/<[^>]+>/g, '');
}

export function getWordAndCharacterCountFromNodes(value) {
  let array = [];
  let symbols = 0;
  let words = 0;
  // check against this value since it will not trim whitespace
  let rawSymbols = 0;
  let rawWords = 0;

  value.nodes.forEach((node) => {
    array = array.concat(node.getTextsAsArray());
  });

  array.forEach((item) => {
    const { text } = item;
    if (text.length) {
      rawSymbols += text.length;
      rawWords += text.split(' ').length;
      const trimmed = text.trim();
      symbols += trimmed.length;
      words += trimmed.split(' ').length;
    }
  });

  return { symbols, words, rawWords, rawSymbols };
}

export function getWordAndCharacterCountFromText(value) {
  // check against this value since it will not trim whitespace
  const rawSymbols = value.length;
  const rawWords = value.split(' ').length;

  // output value
  const trimmed = value.trim();
  const symbols = trimmed.length;
  const words = trimmed.split(' ').length;

  return {
    rawWords,
    rawSymbols,
    symbols,
    words
  };
}

export function shouldDisplayError({ forceTouch = true, showError = true, error, touched }) {
  return (forceTouch === true || touched === true) && error !== undefined && error !== null && showError;
}

export function shouldDisplayWarning({ forceTouch = true, showWarning = true, warning, touched }) {
  return (
    (forceTouch === true || touched === true) &&
    warning !== undefined &&
    warning !== '' &&
    warning !== null &&
    showWarning
  );
}

export const noop = () => ({});
