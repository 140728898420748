import styled from 'styled-components';
import PropTypes from 'prop-types';
import FluidType from '../../../helpers/fluidType';
import FontFamily from '../../../helpers/fontFamily';

const H3 = styled.h3`
  width: 100%;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  ${({ fonts }) => (fonts === 'LushHandwritten' ? 'font-weight: 400;' : 'font-weight: 600;')};
  ${({ fonts }) => FontFamily(fonts)};

  ${({ fontSize, lineHeight, minFont, minLine, maxFont, maxLine }) => {
    if (fontSize === '' && lineHeight === '') {
      return `${FluidType({ fontSize: [minFont, maxFont], lineHeight: [minLine, maxLine] })}`;
    }
    return `
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `;
  }}
`;

H3.h1 = H3.withComponent('h1');
H3.h2 = H3.withComponent('h2');
H3.h3 = H3.withComponent('h3');
H3.h4 = H3.withComponent('h4');
H3.h5 = H3.withComponent('h5');
H3.h6 = H3.withComponent('h6');
H3.p = H3.withComponent('p');
H3.span = H3.withComponent('span');
H3.label = H3.withComponent('label');

H3.propTypes = {
  padding: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
  lineHeight: PropTypes.string,
  minFont: PropTypes.number,
  minLine: PropTypes.number,
  maxFont: PropTypes.number,
  maxLine: PropTypes.number,
  fonts: PropTypes.oneOfType([PropTypes.oneOf(['inter', 'LushHandwritten']), PropTypes.string])
};

H3.defaultProps = {
  color: '#333',
  align: 'left',
  fontSize: '',
  margin: '0',
  padding: '0',
  lineHeight: '',
  minFont: 17,
  maxFont: 27,
  minLine: 27,
  maxLine: 34,
  fonts: 'inter'
};

H3.displayName = 'H3';

export default H3;
