import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SearchIcon = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  align-items: center;

  ${({ hasTerm }) => (hasTerm ? 'cursor: pointer;' : '')}
`;

export const Input = styled.input`
  display: block;
  border: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  padding: 0;
  min-height: 45px;
  border-bottom: 1px solid #fff;
  width: 100%;
  color: #333;
  background-color: transparent;
  background-image: none;
  border-radius: 0;

  ${({ hasTerm }) =>
    hasTerm
      ? `
    box-shadow: none;
    outline: none;
    border-bottom: 1px solid #000;
  `
      : ''}

  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
    border-bottom: 1px solid #000;
  }

  &::-webkit-input-placeholder {
    color: #cecece;
  }
  &::-moz-placeholder {
    color: #cecece;
  }
  &:-ms-input-placeholder {
    color: #cecece;
  }
`;
