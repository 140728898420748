import { tz } from 'moment-timezone';

import { getTimestampInTimezone, getNowMomentInTimezone } from './time';

// Get the hour [as a number] for a given date/format
export const getHour = ({ date = '', format = '', timezone = tz.guess() }) =>
  date ? getTimestampInTimezone(date, format).get('hour') : getNowMomentInTimezone(timezone).get('hour');

// Return true if the given hour is between [inclusive] the given min and max
// If excludeEndTime is true, the check is not inclusive of end time
// min of 0 is midnight at the start of the day; max of 0 is midnight at the end of the day
export const hourInRange = ({ hour, min, max, excludeEndTime = false }) => {
  const maximum = excludeEndTime ? max - 1 : max;
  return hour >= min && hour <= maximum;
};
