export default function fluidType(options) {
  if (options.fontSize.length < 1) {
    console.warn('Fluid type expects a min and max font size');
  }

  if (options.lineHeight.length < 1) {
    console.warn('Fluid type expects a min and max line height');
  }

  const minVW = options.breakpoint ? options.breakpoint[0] : 400;
  const maxVW = options.breakpoint ? options.breakpoint[1] : 1200;

  const minFS = options.fontSize[0];
  const maxFS = options.fontSize[1];

  const minLH = options.lineHeight[0];
  const maxLH = options.lineHeight[1];

  return `
    ${minFS ? `font-size: ${minFS}px;` : ''}
    ${minLH ? `line-height: ${minLH}px;` : ''}

    @media screen and (min-width: ${minVW}px) {
      ${
        minFS && maxFS
          ? `
        font-size: calc(${minFS}px + (${maxFS} - ${minFS}) * (100vw - ${minVW}px) / (${maxVW} - ${minVW}));
      `
          : ''
      }
      ${
        minLH && maxLH
          ? `
        line-height: calc(${minLH}px + (${maxLH} - ${minLH}) * (100vw - ${minVW}px) / (${maxVW} - ${minVW}));
      `
          : ''
      }
    }

    @media screen and (min-width: ${maxVW}px) {
      ${maxFS ? `font-size: ${maxFS}px;` : ''}
      ${maxLH ? `line-height: ${maxLH}px;` : ''}
    }
  `;
}
