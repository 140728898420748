import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Config from '../../../context/size/config';

import { calculatePadding } from '../../../helpers/layoutHelpers';
import Breakpoint from '../../../helpers/breakpoint';

const Cell = styled.div`
  justify-self: ${({ justifySelf }) => justifySelf};
  align-self: ${({ alignSelf }) => alignSelf};

  /* Default size */
  ${(props) => (props.default ? `grid-column-end: span ${props.default};` : '')}
  ${(props) => (props.defaultMerge ? `grid-row-end: span ${props.defaultMerge};` : '')}

  /* Sizes */
  ${(props) =>
    Object.keys(Config.breakpoints).map((bp) => {
      if (!props[bp] && !props[`${bp}Merge`]) return null;
      return css`
        ${Breakpoint(bp)`
            ${(props) => (props[bp] ? `grid-column-end: span ${props[bp]};` : null)}
            ${(props) => (props[`${bp}Merge`] ? `grid-row-end: span ${props[`${bp}Merge`]};` : null)}
        `}
      `;
    })};

  ${ props => { // eslint-disable-line
    if (typeof props.padding === 'object') return calculatePadding(props.padding);
    if (typeof props.padding === 'string') return `padding: ${props.padding};`;
    return null;
  }};
`;

Cell.defaultProps = {
  default: 1,
  defaultMerge: null,
  ...Object.keys(Config.breakpoints).reduce(
    (accumulator, bp) => ({
      ...accumulator,
      [bp]: null,
      [`${bp}Merge`]: null
    }),
    {}
  ),
  padding: '0',
  justifySelf: 'auto',
  alignSelf: 'auto'
};

Cell.propTypes = {
  default: PropTypes.number,
  defaultMerge: PropTypes.number,
  ...Object.keys(Config.breakpoints).reduce(
    (accumulator, bp) => ({
      ...accumulator,
      [bp]: PropTypes.number,
      [`${bp}Merge`]: PropTypes.number
    }),
    {}
  ),
  /**
   * An array of up to two values to define padding
   * 1 value = [All sides]
   * 2 values = [Vertical, Horizontal]
   * OR
   * Pass in a string
   */
  padding: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOf(['none', 'sm', 'md', 'lg'])), PropTypes.string]),

  justifySelf: PropTypes.oneOf([
    'auto',
    'normal',
    'stretch',
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'self-start',
    'self-end',
    'left',
    'right',
    'baseline',
    'first baseline',
    'last baseline'
  ]),

  alignSelf: PropTypes.oneOf([
    'auto',
    'normal',
    'center',
    'start',
    'end',
    'self-start',
    'self-end',
    'flex-start',
    'flex-end',
    'baseline',
    'first baseline',
    'last baseline',
    'stretch'
  ])
};

Cell.displayName = 'Cell';

export default Cell;
