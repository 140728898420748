import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';

// components
import Breakpoint from '../breakpoint';
import Button from '../button';
import Row from '../row';
import Column from '../column';
import SrOnly from '../srOnly';

// styled components
const Bar = styled.div`
  display: flex;
  align-items: stretch;
  z-index: 10;
  width: 100%;
  min-height: 60px;
  flex: 0 0 auto;

  ${Breakpoint('tablet')`
    min-height: 80px;
  `}

  ${({ position }) => {
    if (position === 'sticky') {
      return `
        position: relative;
        position: sticky;
        top: 0;
        left: 0;
      `;
    }
    if (position === 'absolute') {
      return `
        position: absolute;
        top: 0;
        left: 0;
      `;
    }
    if (position === 'fixed') {
      return `
        position: fixed;
        top: 0;
        left: 0;
      `;
    }
    return 'position: relative;';
  }}
`;

const BarRow = styled(Row)``;

const BarColumn = styled(Column)`
  height: 100%;
`;

const BarButton = styled(Button)`
  height: 100%;
`;

class Header extends React.Component {
  render() {
    const { align, icon, theme, onClose, position, label } = this.props;

    const buttonTheme = theme === 'black' ? 'inverse-floating' : 'default-floating';

    return (
      <Bar theme={theme} position={position}>
        <BarRow alignItems="center">
          <BarColumn padding="0" alignItems={align}>
            <BarButton
              theme={buttonTheme}
              padding="17.5px"
              onClick={() => {
                onClose();
              }}
            >
              <Icon icon={icon} size="23px" />
              <SrOnly>{label}</SrOnly>
            </BarButton>
          </BarColumn>
        </BarRow>
      </Bar>
    );
  }
}

Header.defaultProps = { label: 'back' };

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default Header;
