import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

// Animations
const l_fade = keyframes`
  0%, 20% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0
  }
`;

const u_fade = keyframes`
  0%, 40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0
  }
`;

const s_fade = keyframes`
  0%, 55% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0
  }
`;

const h_fade = keyframes`
  0%, 70% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0
  }
`;

// Styling
const Loader = styled.svg`
  box-sizing: border-box;
  display: block;
  position: ${(props) => props.position};
  max-width: 100%;

  ${(props) => {
    switch (props.align) {
      case 'right': {
        if (props.position === 'absolute') {
          return `
            top: 50%;
            right: 0;
            transform: translate3d(0, -50%, 0);
          `;
        }
        return 'margin: 0 0 0 auto;';
      }
      case 'left': {
        if (props.position === 'absolute') {
          return `
            top: 50%;
            left: 0;
            transform: translate3d(0, -50%, 0);
          `;
        }
        return 'margin: 0 auto 0 0;';
      }
      default: {
        if (props.position === 'absolute') {
          return `
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          `;
        }
        return 'margin: 0 auto;';
      }
    }
  }}
`;

const L = styled.polygon`
  animation: ${l_fade} 1.1s linear infinite;
  will-change: opacity;
`;

const U = styled.path`
  animation: ${u_fade} 1.1s linear infinite;
  will-change: opacity;
`;

const S = styled.path`
  animation: ${s_fade} 1.1s linear infinite;
  will-change: opacity;
`;

const H = styled.polygon`
  animation: ${h_fade} 1.1s linear infinite;
  will-change: opacity;
`;

const LushLoader = (props) => {
  const { width, fill } = props;
  const baseWidth = 120;
  const baseHeight = 32;
  const newHeight = width / (baseWidth / baseHeight);

  return (
    <Loader {...props} width={width} height={newHeight} x="0px" y="0px" viewBox="0 0 120 32">
      <L fill={fill} points="9.4,0.7 0,0.7 0,31.3 23,31.3 23,23.4 9.4,23.4" />
      <U
        fill={fill}
        d="M45.2,17.4c0,3.1,0,7-4.4,7c-4.6,0-4.6-3.9-4.6-7V0.7h-9.4v18.7c0,8.5,4.6,12.6,13.9,12.6s13.9-4,13.9-12.6V0.7h-9.4V17.4z"
      />
      <S
        fill={fill}
        d="M78.4,12.7c-4.3-1.2-8.7-1.7-8.8-3.8c0-1.7,2.1-2,3.4-2c0.9,0,2,0.2,2.8,0.8c0.8,0.5,1.3,1.3,1.1,2.4h8.9 C85.5,2.5,79.6,0,72.9,0c-6.3,0-12.3,3-12.3,10.1c0,6.1,5.5,7.5,10.3,8.9c5.3,1.5,6.6,1.9,6.6,3.5c0,2.3-2.4,2.8-3.9,2.8 c-1.8,0-3.8-0.4-4.5-2.2c-0.2-0.6-0.3-1.2-0.3-2h-9.4c0.1,9.8,9.9,11,13.4,11C79.7,32,87,29.4,87,21.4C87,15.9,82.7,13.9,78.4,12.7"
      />
      <H
        fill={fill}
        points="110.6,11.4 101.7,11.4 101.7,0.7 92.3,0.7 92.3,31.3 101.7,31.3 101.7,19.2 110.6,19.2 110.6,31.3 120,31.3 120,0.7 110.6,0.7"
      />
    </Loader>
  );
};

// Default props
LushLoader.defaultProps = {
  width: 120,
  fill: '#000',
  position: 'relative',
  align: 'center'
};

// Prop type check
LushLoader.propTypes = {
  /** generates height + width */
  width: PropTypes.number,
  /** define color of all the letters */
  fill: PropTypes.string,
  /** set the position attribute */
  position: PropTypes.oneOf(['relative', 'absolute']),
  /** moves the loader to a position within it's wrapper. Either using margin or top / left values */
  align: PropTypes.oneOf(['left', 'center', 'right'])
};

LushLoader.displayName = 'LushLoader';

// export
export default LushLoader;
