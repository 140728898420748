import styled from 'styled-components';
import PropTypes from 'prop-types';

// supporting
import { calculateContainerPadding } from '../../helpers/layoutHelpers';

const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};

  ${({ padding }) => {
    if (typeof padding === 'object') return calculateContainerPadding(padding);
    if (typeof padding === 'string') return `padding: ${padding};`;
    return null;
  }};
`;

Container.div = Container;
Container.section = Container.withComponent('section');
Container.header = Container.withComponent('header');
Container.footer = Container.withComponent('footer');
Container.article = Container.withComponent('article');
Container.nav = Container.withComponent('nav');

Container.defaultProps = {
  padding: ['none', 'md'],
  maxWidth: '1100px',
  margin: '0 auto'
};

Container.propTypes = {
  /**
   * An array of up to two values to define padding
   * 1 value = [All sides]
   * 2 values = [Vertical, Horizontal]
   * OR
   * Pass in a string
   */
  padding: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOf(['none', 'sm', 'md', 'lg'])), PropTypes.string]),
  maxWidth: PropTypes.string,
  margin: PropTypes.string
};

Container.displayName = 'Container';

export default Container;
