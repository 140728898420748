import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ==================== //
// TABLE FOOT COMPONENT //
// ==================== //
export default class TableFoot extends Component {
  renderActions(row) {
    const { actions, data, columns, mobile } = this.props;

    const styles =
      mobile === false
        ? {
            flexBasis: actions.flexBasis ? actions.flexBasis : 0,
            flexGrow: actions.flexBasis ? actions.flexBasis : 0,
            minWidth: actions.minWidth
          }
        : {};

    let cell = '';

    if (typeof actions.foot === 'string' || typeof actions.foot === 'number') {
      cell = actions.foot;
    } else if (typeof actions.foot === 'object') {
      cell = React.cloneElement(actions.foot, {
        key: row.length - 1,
        index: row.length - 1,
        mobile,
        actions
      });
    }

    return (
      <div style={styles} key="actions" className={`ui-table__td ${mobile ? 'ui-table__td--is-mobile' : ''} `}>
        {cell}
      </div>
    );
  }

  renderRow() {
    const { actions, columns, mobile } = this.props;

    const row = columns.map((column, i) => {
      const styles =
        mobile === false
          ? {
              flexBasis: column.flexBasis ? column.flexBasis : `${100 / columns.length}%`,
              flexGrow: column.flexBasis == 0 ? 0 : 1,
              minWidth: column.minWidth
            }
          : {};

      let cell = '';

      if (typeof column.foot === 'string' || typeof column.foot === 'number') {
        cell = column.foot;
      } else if (typeof column.foot === 'object') {
        cell = React.cloneElement(column.foot, {
          key: i,
          index: i,
          mobile,
          column
        });
      }

      return (
        <div style={styles} key={i} className={`ui-table__td ${mobile ? 'ui-table__td--is-mobile' : ''} `}>
          {cell}
        </div>
      );
    });

    if (actions !== null) {
      row.push(this.renderActions(row));
    }

    return row;
  }

  render() {
    const { mobile } = this.props;

    return <div className={`ui-table__tfoot ${mobile ? 'ui-table__tfoot--is-mobile' : ''} `}>{this.renderRow()}</div>;
  }
}
TableFoot.propTypes = {
  columns: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired,
  actions: PropTypes.object
};
