import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import throttle from 'lodash/throttle';

// helpers
import { isBrowser } from '../../helpers/dom';
import Config from '../config';

// helper functions
const getWindowSize = ({ fallbackWidth = null, fallbackHeight = null }) => {
  const canUseDOM = isBrowser;

  return {
    width: canUseDOM ? window.innerWidth : fallbackWidth,
    height: canUseDOM ? window.innerHeight : fallbackHeight,
    canUseDOM
  };
};

// initial context creation
const SizeStateContext = createContext({ ...Config });
SizeStateContext.displayName = 'SizeStateContext';
const SizeDispatchContext = createContext({ ...Config });
SizeDispatchContext.displayName = 'SizeDispatchContext';

// component
const SizeProvider = ({ children }) => {
  const [fallbackHeight, setFallbackHeight] = useState(Config.fallbackHeight);
  const [fallbackWidth, setFallbackWidth] = useState(Config.fallbackWidth);
  const [canUseDOM, setCanUseDOM] = useState(Config.canUseDOM);
  const [hasProvider, setHasProvider] = useState(true);
  const [width, setWidth] = useState(Config.width);
  const [height, setHeight] = useState(Config.height);
  const [sizes, setSizes] = useState(Config.sizes);

  /* eslint-disable react/sort-comp */
  const dispatchSizes = () => {
    const windowSize = getWindowSize({ fallbackWidth, fallbackHeight });

    if (width !== windowSize.width || height !== windowSize.height) {
      const newSizes = sizes.map((item) => ({ ...item, match: windowSize.width >= item.size }));

      setSizes(newSizes);
      setWidth(windowSize.width);
      setHeight(windowSize.height);
      setCanUseDOM(windowSize.canUseDOM);
    }
  };

  const throttledDispatchSizes = throttle(dispatchSizes, 200, { leading: false, trailing: true });
  /* eslint-enable react/sort-comp */

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('resize', throttledDispatchSizes);
      dispatchSizes();
    }
    return () => {
      setHasProvider(false);
      if (isBrowser) window.removeEventListener('resize', throttledDispatchSizes);
    };
  }, [isBrowser, hasProvider]);

  const getSize = (size) => find(sizes, { key: size });

  // render
  return (
    <SizeStateContext.Provider
      value={{
        fallbackHeight,
        fallbackWidth,
        canUseDOM,
        hasProvider,
        width,
        height,
        sizes
      }}
    >
      <SizeDispatchContext.Provider value={{ getSize, setFallbackHeight, setFallbackWidth }}>
        {children}
      </SizeDispatchContext.Provider>
    </SizeStateContext.Provider>
  );
};

SizeProvider.defaultProps = {};
SizeProvider.propTypes = { children: PropTypes.any.isRequired };
SizeProvider.displayName = 'SizeProvider';

//
function useSizeState() {
  const context = useContext(SizeStateContext);
  if (context === undefined) {
    throw new Error('useSizeState must be used within a SizeProvider');
  }
  return context;
}

//
function useSizeDispatch() {
  const context = useContext(SizeDispatchContext);
  if (context === undefined) {
    throw new Error('useSizeDispatch must be used within a SizeProvider');
  }
  return context;
}

// export
export { useSizeState, useSizeDispatch, SizeStateContext, SizeDispatchContext };
export default SizeProvider;
